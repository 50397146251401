import React from "react";
import { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useEffect } from "react";
import moment from "moment";
import DashboardService from "../../services/dashboard.service";

const Bookings = () => {
  const customRowStyles = {
    gap: "10px",
    // padding:"0 20px"
  };

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [loadingData, setLoadingData] = useState(true);

  const getAllBookingData = async () => {
    try {
      const response = await DashboardService.getAllOrderBooking(
        currentPage,
        pageSize
      );
      setData(response.data.data.orders);
      setTotalItems(response.data.data.totalOrders);
      setLoadingData(false);
      // console.log("response", response);
    } catch (e) {
      setLoadingData(false);
      // console.log("Error get all bookings data", e);
    }
  };

  useEffect(() => {
    getAllBookingData();
  }, [currentPage, pageSize]);

  const handleRowsPerPageChange = (newPageSize, currentPage) => {
    setPageSize(newPageSize);
    setCurrentPage(currentPage);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      name: <b>USER NAME</b>,
      sortable: true,
      cell: (row) => (
        <div>
          {row.firstName} {row.lastName}
        </div>
      ),
    },
    {
      name: <b>TOTAL NUMBER OF PEOPLE</b>,
      sortable: true,
      cell: (row) => <div>{row.numberOfPeople}</div>,
    },
    {
      name: <b>BUSINESS NAME</b>,
      sortable: true,
      cell: (row) => <div>{row.bussinessId.bussinessName}</div>,
    },
    {
      name: <b>BOOKING TIME</b>,
      sortable: true,
      cell: (row) => (
        <div>
          {moment(row.startTime).format("hh:mm A")} -{" "}
          {moment(row.endTime).format("hh:mm A")}{" "}
        </div>
      ),
    },
    {
      name: <b>BOOKING DATE</b>,
      sortable: true,
      cell: (row) => <div>{moment(row.date).format("DD/MM/YYYY")}</div>,
      //   <Moment format="DD-MM-YYYY">{row.createdat}</Moment>
    },
    {
      name: <b>STATUS</b>,
      sortable: true,
      cell: (row) => (
        <Button
          variant={
            row.status === "Accepted"
              ? "success"
              : row.status === "Rejected"
              ? "danger"
              : "warning"
          }
          size="sm"
          style={{ cursor: "default" }}
        >
          {row.status}
        </Button>
      ),
    },
  ];
  return (
    <>
      {loadingData ? (
        <div className="loader-container">
          <div
            style={{ position: "absolute", top: "50%", left: "50%" }}
            className="loader"
          >
            Just A Moment....
          </div>
        </div>
      ) : (
        <>
          <div className="page-header2"></div>
          <div className="row">
            <Col sm={12} md={6} lg={6} xl={3}>
              <Card className="card custom-card mb-4">
                <Card.Body>
                  <div className="card-item">
                    <div className="card-item-icon card-icon">
                      <i
                        className="ti-write fs-25"
                        style={{ color: "#6259CA" }}
                      ></i>
                    </div>
                    <div className="card-item-title  mb-2">
                      <label className="main-content-label tx-13 font-weight-bold ">
                        Total Bookings
                      </label>
                      {/* <span className="d-block tx-12 mb-0 text-muted">
                    Total Audio calls
                  </span> */}
                    </div>
                    <div className="card-item-body">
                      <div className="card-item-stat">
                        <h4 className="font-weight-bold">
                          {/* {data.totalAudioCall} */}
                          {totalItems}
                        </h4>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </div>
          <Row className="row-sm" style={{ overflowX: "auto" }}>
            <Col lg={12} style={{ padding: "0" }}>
              <Card className="custom-card overflow-hidden">
                <Card.Body>
                  <div>
                    <h6 className="main-content-label mb-4">
                      all booking detail
                    </h6>
                  </div>
                  <div className="responsive">
                    <DataTable
                      //   {...extensions}
                      columns={columns}
                      data={data}
                      noHeader
                      persistTableHead
                      pagination
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                      highlightOnHover
                      // customStyles={{
                      //   rows: {
                      //     style: customRowStyles,
                      //   },
                      // }}
                      paginationServer
                      paginationTotalRows={totalItems}
                      paginationPerPage={pageSize}
                      paginationRowsPerPageOptions={[10, 25, 50]}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onChangePage={handlePageChange}
                      // progressPending={loading}
                      progressComponent={
                        <div className="text-center"> Just A Moment....</div>
                      }
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Bookings;
