export const MENUITEMS = [
  {
    Items: [
      {
        path: `/dashboard`,
        icon: "ti-home",
        type: "link",
        active: false,
        selected: false,
        title: "Dashboard",
      },
      {
        path: `/users`,
        icon: "ti-user",
        type: "link",
        active: false,
        selected: false,
        title: "User",
      },
      {
        path: `/vendors`,
        icon: "ti-user",
        type: "link",
        active: false,
        selected: false,
        title: "Vendor",
      },
      {
        path: `/bookings`,
        icon: "ti-write",
        type: "link",
        active: false,
        selected: false,
        title: "Booking",
      },
      {
        path: `/trendingsearch`,
        icon: "ti-search",
        type: "link",
        active: false,
        selected: false,
        title: "Trending Search",
      },
      {
        path: `/featureandecofriendly`,
        icon: "mdi mdi-nature-people fs-20",
        type: "link",
        active: false,
        selected: false,
        title: "Feature & Eco-Friendly",
      },
      // {
      //   path: `/uservendorchat`,
      //   icon: "ti-comments",
      //   type: "link",
      //   active: false,
      //   selected: false,
      //   title: "Uesr/Vendor Chat",
      // },
      {
        path: `/notifications`,
        icon: "ti-bell",
        type: "link",
        active: false,
        selected: false,
        title: "Notification",
      },
      // {
      //   path: `/helpandsupport`,
      //   icon: "ti-help-alt",
      //   type: "link",
      //   active: false,
      //   selected: false,
      //   title: "Help and Support",
      // },
      {
        path: `/profit`,
        icon: "ti-stats-up",
        type: "link",
        active: false,
        selected: false,
        title: "Profit",
      },
      {
        path: `/reportticket`,
        icon: "ti-bookmark-alt",
        type: "link",
        active: false,
        selected: false,
        title: "Report",
      },
      // {
      //   path: `/user`,
      //   icon: "ti-user",
      //   type: "link",
      //   active: false,
      //   selected: false,
      //   title: "User",
      // },
      // {
      //   path: `/restaurants`,
      //   icon: "fas fa-utensils",
      //   type: "link",
      //   active: false,
      //   selected: false,
      //   title: "Restaurants",
      // },
      // {
      //   path: `/report`,
      //   icon: "ti-bookmark-alt",
      //   type: "link",
      //   active: false,
      //   selected: false,
      //   title: "Report",
      // },
      // {
      //   path: `/channel`,
      //   icon: "ti-comments",
      //   type: "link",
      //   active: false,
      //   selected: false,
      //   title: "Channel",
      // },
      // {
      //   path: `/mailbox`,
      //   icon: "si si-people",
      //   type: "link",
      //   active: false,
      //   selected: false,
      //   title: "Referral code",
      // },
      // {
      //   path: `/userwaitinglist`,
      //   icon: "ti-list",
      //   type: "link",
      //   active: false,
      //   selected: false,
      //   title: "Waiting List",
      // },
      // {
      //   path: `/colabraters`,
      //   icon: "fas fa-handshake",
      //   type: "link",
      //   active: false,
      //   selected: false,
      //   title: "Colabraters",
      // },
    ],
  },
];
