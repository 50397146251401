import serverCall from "../../serverCall";

const graph = async () => {
  try {
    const response = await serverCall.get(`/users/admin/graph`);
    return response;
  } catch (e) {
    console.log("Error in graph", e);
  }
};

const getAllOrderBooking = async (page, limit) => {
  try {
    const response = await serverCall.get(
      `/reservation/admin/get-all-order?page=${page}&limit=${limit}`
    );
    return response;
  } catch (err) {
    console.log("Error getting booking", err);
  }
};

const getProfit = async (page, limit) => {
  try {
    const response = await serverCall.get(
      `/wallet/admin/profit?page=${page}&limit=${limit}`
    );
    return response;
  } catch (err) {
    console.log("Error getting profit", err);
  }
};

const trendingSearch = async (page, limit) => {
  try {
    const response = await serverCall.get(
      `/history-of-searches/admin/trending-search?page=${page - 1}&limit=${limit}`
    );
    return response;
  } catch (err) {
    console.log("Error getting trending", err);
  }
};

const DashboardService = {
  graph,
  getAllOrderBooking,
  getProfit,
  trendingSearch,
};

export default DashboardService;
