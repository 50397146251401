import React, { Fragment, useEffect, useState } from "react";
import {
  Dropdown,
  Container,
  Navbar,
  Row,
  Col,
  Card,
  Form,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import NotificationService from "../../services/notification.service";
import { faL } from "@fortawesome/free-solid-svg-icons";

function Header() {
  const history = useNavigate();
  const navigate = useNavigate();
  const name = localStorage.getItem("email");
  const [showNotification, setShowNotification] = useState(false);
  const [unSeenData, setUnSeenData] = useState([]);
  const [countUnSeen, setCountUnSeen] = useState();
  const [show, setShow] = useState(false);

  const AdminName = localStorage.getItem("email");

  const handleToggleNotification = () => {
    setShowNotification(!showNotification);
  };

  useEffect(() => {
    document.addEventListener("click", handlePageClick);

    return () => {
      document.removeEventListener("click", handlePageClick);
    };
  }, []);

  const handlePageClick = (event) => {
    const target = event.target;
    const sidebarToggle = document.getElementById("mainSidebarToggle");

    if (
      target !== sidebarToggle &&
      target.closest(".main-header-left") === null
    ) {
      closeSidebar();
    }
  };

  const closeSidebar = () => {
    const body = document.querySelector("body");
    const innerWidth = window.innerWidth;

    if (body !== null) {
      if (innerWidth >= 992) {
        body.classList.remove("main-sidebar-show");
      } else if (body.classList.contains("horizontalmenu")) {
        body.classList.remove("main-navbar-show");
      } else {
        body.classList.remove("main-sidebar-show");
      }
    }
  };

  const handleLogin = () => {
    localStorage.clear();
    // history.push("/login");
    closeSidebar();
  };

  const headerToggleButton = () => {
    const body = document.querySelector("body");
    const innerWidth = window.innerWidth;

    if (body !== null) {
      if (innerWidth >= 992) {
        body.classList.toggle("main-sidebar-hide");
        body.classList.remove("main-sidebar-show");
      } else if (body.classList.contains("horizontalmenu")) {
        body.classList.toggle("main-navbar-show");
        body.classList.remove("main-sidebar-show");
        body.classList.remove("main-sidebar-hide");
      } else {
        body.classList.toggle("main-sidebar-show");
        body.classList.remove("main-sidebar-hide");
      }
    }
  };

  const UnseenNotificationData = async () => {
    try {
      const response = await NotificationService.UnseenNotification();
      setUnSeenData(response.data.data);
      setCountUnSeen(response.data.totalNotification);
      console.log("unseen received response", response);
    } catch (err) {
      console.log("unseen notifi err", err);
    }
  };

  useEffect(() => {
    UnseenNotificationData();
  }, []);

  const SeenNotificationData = async (_id) => {
    try {
      const response = await NotificationService.seenNotification(_id);
      UnseenNotificationData();
      console.log("seen received response", response);
    } catch (err) {
      console.log("seen-notification err", err);
    }
  };

  const handleNotificationClick = (item) => {
    const navigatePath =
      item.title === "New Order Received"
        ? `/bookings`
        : `/reportticket/${item.payload.id}`;
    navigate(navigatePath, { state: { reporterId: item.payload.userId } });
    setShow(false);
    SeenNotificationData(item._id);
    UnseenNotificationData();
  };

  return (
    <Fragment>
      <Navbar expand="lg" className="main-header side-header sticky">
        <Container fluid className="main-container container-fluid">
          <div className="main-header-left">
            <Link
              to="#"
              className="main-header-menu-icon"
              id="mainSidebarToggle"
              onClick={headerToggleButton}
            >
              <span></span>
            </Link>
          </div>

          <div className="main-header-right">
            <Navbar.Toggle
              aria-controls="navbarSupportedContent-4"
              className="navresponsive-toggler"
            >
              <i className="fe fe-more-vertical header-icons navbar-toggler-icon"></i>
            </Navbar.Toggle>
            <div className="navbar navbar-expand-lg nav nav-item navbar-nav-right responsive-navbar navbar-dark">
              <Navbar.Collapse
                className="collapse navbar-collapse"
                id="navbarSupportedContent-4"
              >
                <div className="d-flex order-lg-2 align-items-center ms-auto">
                  <Dropdown className="main-profile-menu">
                    <Dropdown.Toggle className="d-flex p-0" variant="default">
                      <span className="main-img-user mx-1">
                        <img
                          alt="avatar"
                          src={require("../../../assets/img/users/male.png")}
                        />
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ margin: "0px" }}>
                      {/* <div className="header-navheading"> */}
                      {/* <h6 className="main-notification-title pt-1">
                          {" "}
                          admin@gmail.com
                        </h6> */}
                      {/* </div> */}

                      <Dropdown.Item
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          marginBottom: "3px",
                        }}
                      >
                        <i className="ti-user"></i>{" "}
                        <div style={{ fontSize: "14px" }}>{AdminName}</div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          navigate("/");
                          localStorage.removeItem("token", "");
                          localStorage.clear("");
                          window.location.reload();
                        }}
                      >
                        <i className="fe fe-power"></i> Sign Out
                      </Dropdown.Item>
                      {/* <Dropdown.Item>
                        <Form.Group className="form-group mt-3">
                          <label className="custom-switch">
                            <Form.Check
                              type="switch"
                              name="custom-switch-checkbox"
                              label="Turn on/off notification"
                              // className="custom-switch-input"
                              defaultChecked
                            />
                          </label>
                        </Form.Group>
                      </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <Dropdown
                  className=" main-header-notification"
                  show={show}
                  onToggle={(isOpen) => setShow(isOpen)}
                >
                  <Dropdown.Toggle className="nav-link icon" variant="default">
                    <i className="fe fe-bell header-icons"></i>
                    {countUnSeen > 0 ? (
                      <span className="badge bg-danger nav-link-badge">
                        {countUnSeen}
                      </span>
                    ) : (
                      ""
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{
                      margin: "0px",
                      maxHeight: "600px",
                      overflowY: "auto",
                      zIndex: "99999",
                    }}
                  >
                    {unSeenData.map((item, index) => (
                      <div
                        className="main-notification-list"
                        key={index}
                        onClick={(e) => {
                          e.preventDefault();
                          setShow(false);
                          handleNotificationClick(item);
                        }}
                        // onClick={() => { (item.title === 'Stock Alert!' ? navigate(`/supermarket/peoductdetails/${item.payload?.id}`) : navigate(`/order/`)); setShow(false); NotificationSeen(item._id); NotificationUnseen(); }}
                      >
                        <div className="media new">
                          <div>
                            <div className="notification-row">
                              <div className="media-body">
                                <p>
                                  {/* "Attention! The stock level for <b>Dairy Product</b> has reached a critical level. */}
                                  <b>{item.title}</b> {" - "}
                                  {item.body}
                                </p>
                                {/* <span>Oct 15 12:32pm</span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="dropdown-footer">
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          setShow(false);
                          navigate("/notifications/");
                        }}
                      >
                        View All Notifications
                      </Link>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </Navbar.Collapse>
            </div>
          </div>
        </Container>
      </Navbar>
    </Fragment>
  );
}

export default Header;
