import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import UseDialogState from "../../../hooks/UseDialogState";
import AddVender from "./AddVender";
import DataTable from "react-data-table-component";
import DeleteUserList from "../userlist/DeleteUserList";
import { useNavigate } from "react-router-dom";
import ViewVendor from "./ViewVendor";
import VendorStatus from "./VendorStatus";
import { useSnackbar } from "notistack";
import VendorService from "../../services/vendor.service";
import AddVendorModal from "./AddVendorModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import User from "../../../assets/img/users/user.png";

const Vendor = () => {
  const {
    open,
    handleClickOpen,
    handleClickClose,
    deleteOpen,
    handleClickDeleteOpen,
    handleClickDeleteClose,
    userStatusOpen,
    handleUserStatusOpen,
    handleUserStatusClose,
  } = UseDialogState();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [deleteId, setdeleteId] = useState("");
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isEmail, setIsEmail] = useState(true);
  const [isMobile, setIsMobile] = useState(true);
  const [edit, setEdit] = useState(false);
  const [isDefault, setIsDefault] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const getAllVendorData = async () => {
    try {
      setLoading(true);
      const response = await VendorService.getAllVendor(currentPage, pageSize);
      console.log("Got all vendor", response);
      setData(response.data.data.vendors);
      setTotalItems(response.data.data.totalVendors);
      // setBusiId(response.data.data.vendors.map((bid) => bid.businessId))
      setLoadingData(false);
      setLoading(false);
    } catch (err) {
      setLoadingData(false);
      setLoading(false);
      console.log("Error getting all vendor data", err);
    }
  };

  useEffect(() => {
    getAllVendorData();
  }, [currentPage, pageSize]);

  const handleRowsPerPageChange = (newPageSize, currentPage) => {
    setPageSize(newPageSize);
    setCurrentPage(currentPage);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleStatusClose = () => {
    handleUserStatusClose();
  };
  const { Option } = Select;

  const handleChange = (value, row) => {
    console.log("handleChange", value);
    statusFormik.setFieldValue("businessId", value.businessId);
    handleUserStatusOpen();
  };

  const handleAddNavigate = (row) => {
    console.log("row", row);
    navigate(`/vendors/${row}`, {
      state: {
        id: row,
        update: true,
      },
    });
  };

  const handleViewNavigate = (row) => {
    navigate(`/vendors/view/${row._id}`, {
      state: {
        id: row._id,
      },
    });
  };

  const deleteVendorData = async () => {
    try {
      const response = await VendorService.deleteVendor(deleteId);
      console.log("response", response);
      handleClickDeleteClose();
      getAllVendorData();
      enqueueSnackbar("Data Delete Successfully!", { variant: "error" });
    } catch (e) {
      console.log("Error deleting vendor", e);
    }
  };

  const handleClickDeleteOpenDialog = (data) => {
    handleClickDeleteOpen();
    setdeleteId(data._id);
  };
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption.value);
    setIsEmail(selectedOption.value === "email" ? true : false);
    setIsMobile(selectedOption.value === "mobileNumber" ? true : false);
    setIsDefault(selectedOption.value);
  };
  const options = [
    { value: "email", label: "Email" },
    { value: "mobileNumber", label: "Phone Number" },
  ];

  const submit = async (values) => {
    const { firstName, lastName, email, mobileNumber, password } = values;
    console.log("Submit", values);
    if (!values._id) {
      try {
        setLoading(true);
        const response = await VendorService.addVendorDetail(values);
        console.log("Add vendor detail", response);
        formik.resetForm();
        setSelectedOption(null);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Data Add Successfully!", { variant: "success" });
          getAllVendorData();
          handleClickClose();
        } else {
          enqueueSnackbar("User already exists by mobilenumber or email", {
            variant: "error",
          });
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log("Error submitting add vendor", err);
        enqueueSnackbar("User already exists by mobilenumber or email", {
          variant: "error",
        });
      }
    } else {
      try {
        setLoading(true);
        const response = await VendorService.updateVendor(values._id, values);
        console.log("Vendor updated successfully", response);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Data Add Successfully!", { variant: "success" });
          getAllVendorData();
          handleClickClose();
          setSelectedOption(null);
        } else {
          setLoading(false);
          enqueueSnackbar("User already exists by mobilenumber or email", {
            variant: "error",
          });
        }
      } catch (err) {
        setLoading(false);
        console.log("Error submitting update vendor", err);
        enqueueSnackbar("User already exists by mobilenumber or email", {
          variant: "error",
        });
      }
    }
  };

  const validationSchema = Yup.object({
    // id: edit ? "" : Yup.string(),
    firstName: edit ? "" : Yup.string().required("First name is required"),
    lastName: edit ? "" : Yup.string().required("Last name is required"),
    email: isEmail
      ? Yup.string()
          .email("Invalid email address")
          .required("Email is required")
      : "",
    password: isEmail ? Yup.string().required("Password is required") : "",
    mobileNumber: isMobile
      ? Yup.number().required("Phone number is required")
      : "",
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      mobileNumber: "",
    },
    validationSchema: validationSchema,
    onSubmit: submit,
  });

  console.log("Formik", formik);

  const handleOpen = (vendorId) => {
    console.log("handleOpen", vendorId);
    handleClickOpen();
    if (vendorId && vendorId._id) {
      setIsEmail(false);
      setIsMobile(false);
      setEdit(true);
      formik.setFieldValue("_id", vendorId._id);
      formik.setFieldValue("firstName", vendorId.firstName);
      formik.setFieldValue("lastName", vendorId.lastName);
      formik.setFieldValue("email", vendorId.email);
      formik.setFieldValue("password", vendorId.password);
      formik.setFieldValue("mobileNumber", vendorId.mobileNumber);
      if (vendorId.email) {
        setSelectedOption(options.find((opt) => opt.value === "email"));
      } else if (vendorId.mobileNumber) {
        setSelectedOption(options.find((opt) => opt.value === "mobileNumber"));
      }
    } else {
      setEdit(false);
      formik.setFieldValue("id", "");
      formik.setFieldValue("firstName", "");
      formik.setFieldValue("lastName", "");
      formik.setFieldValue("email", "");
      formik.setFieldValue("password", "");
      formik.setFieldValue("mobileNumber", "");
    }
  };

  const statusSubmit = async (values) => {
    const { businessId } = values;
    try {
      setLoading(true);
      const response = await VendorService.updateStatus(businessId);
      getAllVendorData();
      setLoading(false);
      console.log("updated status", response);
      enqueueSnackbar("Status Change Successfully", { variant: "success" });
    } catch (err) {
      setLoading(false);
      console.log("Error updating", err);
    }
  };

  const statusFormik = useFormik({
    initialValues: {
      businessId: "",
    },
    onSubmit: statusSubmit,
  });

  const columns = [
    {
      name: <b>FIRST NAME</b>,
      selector: (row) => [row.firstName],
      sortable: true,
      cell: (row) => <div>{row.firstName}</div>,
    },
    {
      name: <b>LAST NAME</b>,
      selector: (row) => [row.lastName],
      sortable: true,
      cell: (row) => <div>{row.lastName}</div>,
    },

    {
      name: <b>E-MAIL</b>,
      selector: (row) => [row.email],
      sortable: true,
      cell: (row) => (
        <div>
          {row.email !== undefined && row.email !== null && row.email !== ""
            ? row.email
            : "--"}
        </div>
      ),
    },
    {
      name: <b>BUSINESS NAME</b>,
      selector: (row) => [row.businessName],
      sortable: true,
      cell: (row) => <div>{row.businessName}</div>,
    },
    {
      name: <b>PROFILE</b>,
      selector: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="demo-avatar-group my-auto float-end">
              <div className="main-img-user" style={{ objectFit: "cover" }}>
                {row.profileImage ? (
                  <img
                    className="rounded-circle avatar-md me-2"
                    src={row.profileImage}
                    width="30px"
                    height="30px"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                ) : (
                  <img
                    className="rounded-circle avatar-md me-2"
                    src={User}
                    width="30px"
                    height="30px"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                )}
              </div>
            </div>
          </div>
        );
      },
      sortable: true,
    },

    {
      name: <b>PHONE NUMBER</b>,
      selector: (row) => [row.mobileNumber],
      cell: (row) => (
        <div>
          {row.mobileNumber !== undefined &&
          row.mobileNumber !== null &&
          row.mobileNumber !== ""
            ? row.mobileNumber
            : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <b>STATUS</b>,
      selector: (row) => [row.status],
      cell: (row) => (
        <div className="">
          {/* <span className="my-auto">{row.status}</span> */}
          <div className="">
            {/* {row.status === "PENDING" && ( */}
            <div className="select-pending">
              {row.isVerified !== undefined ? (
                row.isVerified === false ? (
                  <Button
                    type="button"
                    onClick={() => handleChange(row)}
                    variant="warning"
                    size="sm"
                  >
                    Verify
                  </Button>
                ) : (
                  <Button
                    type="button"
                    // onClick={() => handleChange(row)}
                    variant="success"
                    size="sm"
                  >
                    Verified
                  </Button>
                )
              ) : (
                <div style={{ color: "red" }}>Business not found</div>
              )}
            </div>
            {/* )} */}
          </div>
        </div>
      ),
      sortable: true,
    },
    // {
    //   name: <b>STATUS</b>,
    //   selector: (row) => [row.status],
    //   cell: (row) => (
    //     <div className="">
    //       {/* <span className="my-auto">{row.status}</span> */}
    //       <div className="">
    //         {/* {row.status === "PENDING" && ( */}
    //         <div className="select-pending">
    //           <Select
    //             value={row.active}
    //             defaultValue="Approved"
    //             onChange={(value) => handleChange(value, row)}
    //           >
    //             <Option value="active">
    //               {/* <i className="fas fa-user"></i>  */}
    //               <span>Approved</span>
    //             </Option>
    //             <Option value="inactive">
    //               {/* <i className="fas fa-user-slash"></i>  */}
    //               <span>Rejected</span>
    //             </Option>
    //           </Select>
    //         </div>
    //         {/* )} */}
    //       </div>
    //     </div>
    //   ),
    //   sortable: true,
    // },
    {
      name: <b>ACTION</b>,
      selector: (row) => [row.ACTIONS],
      // width: "100px",
      sortable: true,
      cell: (row) => (
        <>
          <Button
            variant="info"
            size="sm"
            className="me-1"
            onClick={() => handleViewNavigate(row)}
          >
            <i className="fas fa-eye"></i>
          </Button>
          {/* <Button
            variant="primary"
            size="sm"
            className="me-1"
            onClick={() => {
              handleOpen(row, true);
            }}
          >
            <i className="fa fa-pencil"></i>
          </Button> */}
          <Button
            variant="danger"
            size="sm"
            className="me-1"
            onClick={() => {
              handleClickDeleteOpenDialog(row);
            }}
          >
            <i className="fas fa-trash"></i>
          </Button>
        </>
      ),
    },
  ];
  return (
    <>
      {loadingData ? (
        <div className="loader-container">
          <div
            style={{ position: "absolute", top: "50%", left: "50%" }}
            className="loader"
          >
            Just A Moment....
          </div>
        </div>
      ) : (
        <>
          <div className="page-header2"></div>
          <div className="row">
            <Col sm={12} md={6} lg={6} xl={3}>
              <Card className="card custom-card mb-4">
                <Card.Body>
                  <div className="card-item">
                    <div className="card-item-icon card-icon">
                      <i
                        className="mdi mdi-account-multiple fs-30"
                        style={{ color: "#6259CA" }}
                      ></i>
                    </div>
                    <div className="card-item-title  mb-2">
                      <label className="main-content-label tx-13 font-weight-bold ">
                        Total Vendors
                      </label>
                      {/* <span className="d-block tx-12 mb-0 text-muted">
                      Total Audio calls
                    </span> */}
                    </div>
                    <div className="card-item-body">
                      <div className="card-item-stat">
                        <h4 className="font-weight-bold">
                          {/* {data.totalAudioCall} */}
                          {totalItems}
                        </h4>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={6} lg={6} xl={4} className="ms-auto mt-auto">
              <div
                className="custom-card text-end  pointer mb-4"
                style={{ marginRight: "18px" }}
              >
                {" "}
                {/* <Button
                  className="text-white"
                  onClick={() => {
                    handleOpen();
                  }}
                >
                  <i className="fas fa-plus"></i> &nbsp; Add Vendor Details
                </Button> */}
              </div>
              <AddVendorModal
                show={open}
                onHide={handleClickClose}
                loading={loading}
                formik={formik}
                edit={edit}
                isDefault={isDefault}
                options={options}
                handleSelectChange={handleSelectChange}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            </Col>
          </div>
          <Row className="row-sm">
            <Col lg={12}>
              <Card className="custom-card overflow-hidden">
                <Card.Body>
                  <div>
                    <h6 className="main-content-label mb-4">
                      all vendors detail
                    </h6>
                  </div>
                  <div className="responsive">
                    <div className="d-flex justify-content-center">
                      {/* <input
                    style={{
                      borderRadius: "5px",
                      padding: "5px",
                      border: "1px solid #e8e8f7",
                    }}
                    // value={filterValue}
                    // onChange={handleFilterChange}
                    placeholder="Search here"
                    className="ms-auto mb-2 border-rounded"
                  /> */}
                    </div>
                    <DataTable
                      //   {...extensions}
                      columns={columns}
                      data={data}
                      noHeader
                      persistTableHead
                      pagination
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                      highlightOnHover
                      paginationServer
                      paginationTotalRows={totalItems}
                      paginationPerPage={pageSize}
                      paginationRowsPerPageOptions={[10, 25, 50]}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onChangePage={handlePageChange}
                      // progressPending={loading}
                      progressComponent={
                        <div className="text-center"> Just A Moment....</div>
                      }
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <DeleteUserList
            path={"/vendors"}
            show={deleteOpen}
            onHide={handleClickDeleteClose}
            onDelete={deleteVendorData}
            loading={loading}
          />
          <VendorStatus
            show={userStatusOpen}
            onHide={handleStatusClose}
            statusFormik={statusFormik}
            loading={loading}
          />
        </>
      )}
    </>
  );
};

export default Vendor;
