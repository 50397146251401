import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

const VendorStatus = ({ show, onHide, statusFormik,loading }) => {
  return (
    <Modal centered show={show} backdrop="static" keyboard={false}>
      <Modal.Header closeButton onHide={onHide}>
        <Modal.Title style={{ fontSize: "20px" }}>Vendor Business Status</Modal.Title>
      </Modal.Header>
      <form onSubmit={statusFormik.handleSubmit}>
        <Modal.Body className="text-center">
          <>
            <i
              className={`icon ion-ios-checkmark-circle-outline tx-100 mg-t-20`}
              style={{color: "#19b159"}}
            ></i>
            <p className="mg-b-20 mg-x-20 ">
              Are you sure want to <b> Verified Status !!</b>
            </p>
          </>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
          <Button
            disabled
          >
            <Spinner
              as="span"
              aria-hidden="true"
              animation="border"
              role="status"
              style={{ color: "white", width: "24px", height: "24px" }}
              size="sm"
            />
          </Button>
        ) : (
          <Button type="submit" onClick={onHide} variant="success">
            Verified
          </Button>
       )} 
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default VendorStatus;
