import React, { useState } from "react";
import { Fragment } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MainGraph from "./MainGraph";
import DashboardService from "../../services/dashboard.service";
import { useEffect } from "react";

const Dashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [booking, setBooking] = useState([]);
  const [user, setUser] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [profit, setProfit] = useState([])
  const [query, setQuery] = useState([])

  // console.log("booking", vendor);

  const graphData = async () => {
    try {
      const response = await DashboardService.graph();
      const result = response.data;
      setData(result);
      setBooking(result.bookingCountByMonth);
      setUser(result.userCountsByMonth);
      setVendor(result.vendorCountsByMonth);
      setProfit(result.profitCountByMonth)
      setQuery(result.queryCountByMonth)
      // console.log("response geraph", result);
      setLoadingData(false);
    } catch (err) {
      // console.log("Failed to graph data", err);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    graphData();
  }, []);

  return (
    <>
      {loadingData ? (
        <div className="loader-container">
          <div
            style={{ position: "absolute", top: "50%", left: "50%" }}
            className="loader"
          >
            Just A Moment....
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="page-header">
            <h2 className="main-content-title tx-24 mb-4 mt-2">DASHBOARD</h2>
          </div>
          <div className="cards-main mb-4">
            {/* <div className="row mb-4 summary_cards"> */}
            <div className="cards dashboard-card">
              <Card
                className="card custom-card"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/profit/")}
              >
                <Card.Body>
                  <div className="card-item">
                    <div className="card-item-icon card-icon">
                      <i
                        // className="mdi mdi-chart-areaspline fs-25"     
                        className="ti-stats-up fs-25"     
                        style={{ color: "#6259CA" }}
                      ></i>
                    </div>
                    <div className="card-item-title  mb-2">
                      <label className="main-content-label tx-13 font-weight-bold ">
                        Total Profits
                      </label>
                      {/* <span className="d-block tx-12 mb-0 text-muted">
                      Total Audio calls
                    </span> */}
                    </div>
                    <div className="card-item-body">
                      <div className="card-item-stat">
                        <h4 className="font-weight-bold">
                          {/* {data.totalAudioCall} */}
                          {data.totalProfit}
                        </h4>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="cards dashboard-card">
              <Card
                className="custom-card"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/bookings/")}
              >
                <Card.Body>
                  <div className="card-item">
                    <div className="card-item-icon card-icon">
                      <i
                        className="ti-write fs-25"
                        style={{ color: "#6259CA" }}
                      ></i>
                    </div>
                    <div className="card-item-title mb-2">
                      <label className="main-content-label tx-13 font-weight-bold">
                        Total Bookings
                      </label>
                      {/* <span className="d-block tx-12 mb-0 text-muted">
                      Users joined this month

                    </span> */}
                    </div>
                    <div className="card-item-body">
                      <div className="card-item-stat">
                        <h4 className="font-weight-bold">
                          {data.totalBooking}
                        </h4>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="cards dashboard-card">
              <Card
                className="card custom-card"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/users/")}
              >
                <Card.Body>
                  <div className="card-item">
                    <div className="card-item-icon card-icon">
                      <i
                        className="ti-user fs-25"
                        style={{ color: "#6259CA" }}
                      ></i>
                    </div>
                    <div className="card-item-title  mb-2">
                      <label className="main-content-label tx-13 font-weight-bold">
                        Total Users
                      </label>
                      {/* <span className="d-block tx-12 mb-0 text-muted">
                      Total calls in this month
                    </span> */}
                    </div>
                    <div className="card-item-body">
                      <div className="card-item-stat">
                        <h4 className="font-weight-bold">{data.totalUser}</h4>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="cards dashboard-card">
              <Card
                className="card custom-card"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/vendors/")}
              >
                <Card.Body>
                  <div className="card-item">
                    <div className="card-item-icon card-icon">
                      <i
                        className="ti-user fs-25"
                        style={{ color: "#6259CA" }}
                      ></i>
                    </div>
                    <div className="card-item-title  mb-2">
                      <label className="main-content-label tx-13 font-weight-bold">
                        Total Vendors
                      </label>
                      {/* <span className="d-block tx-12 mb-0 text-muted">
                      Total Audio calls
                    </span> */}
                    </div>
                    <div className="card-item-body">
                      <div className="card-item-stat">
                        <h4 className="font-weight-bold">{data.totalVendor}</h4>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="cards dashboard-card">
              <Card
                className="card custom-card"
                onClick={() => navigate("/trendingsearch/")}
                style={{ cursor: "pointer" }}
              >
                <Card.Body>
                  <div className="card-item">
                    <div className="card-item-icon card-icon">
                      <i
                        className="ti-search fs-20"
                        style={{ color: "#6259CA" }}
                      ></i>
                    </div>
                    <div className="card-item-title  mb-2">
                      <label className="main-content-label tx-13 font-weight-bold">
                      Trending Search
                      </label>
                      {/* <span className="d-block tx-12 mb-0 text-muted">
                      Total video calls
                    </span> */}
                    </div>
                    <div className="card-item-body">
                      <div className="card-item-stat">
                        <h4 className="font-weight-bold">
                          {data.totalQueries}
                        </h4>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="cards dashboard-card">
              <Card className="card custom-card">
                <Card.Body>
                  <div className="card-item">
                    <div className="card-item-icon card-icon">
                      <i
                        className="mdi mdi-headset fs-25"
                        style={{ color: "#6259CA" }}
                      ></i>
                    </div>
                    <div className="card-item-title  mb-2">
                      <label className="main-content-label tx-13 font-weight-bold">
                        Total Calls
                      </label>
                      {/* <span className="d-block tx-12 mb-0 text-muted">
                      Total video calls
                    </span> */}
                    </div>
                    <div className="card-item-body">
                      <div className="card-item-stat">
                        <h4 className="font-weight-bold">
                          {data.totalCalls}
                        </h4>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="cards dashboard-card">
              <Card className="card custom-card">
                <Card.Body>
                  <div className="card-item">
                    <div className="card-item-icon card-icon">
                      <i
                        className="mdi mdi-facebook-messenger fs-25"
                        style={{ color: "#6259CA" }}
                      ></i>
                    </div>
                    <div className="card-item-title  mb-2">
                      <label className="main-content-label tx-13 font-weight-bold">
                        Total Messages
                      </label>
                      {/* <span className="d-block tx-12 mb-0 text-muted">
                      Total video calls
                    </span> */}
                    </div>
                    <div className="card-item-body">
                      <div className="card-item-stat">
                        <h4 className="font-weight-bold">
                          {data.totalMessages}
                        </h4>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="cards dashboard-card">
              <Card className="card custom-card">
                <Card.Body>
                  <div className="card-item">
                    <div className="card-item-icon card-icon">
                      <i
                        className="mdi mdi-directions-fork fs-25"
                        style={{ color: "#6259CA" }}
                      ></i>
                    </div>
                    <div className="card-item-title  mb-2">
                      <label className="main-content-label tx-13 font-weight-bold">
                        Total Directions
                      </label>
                    </div>
                    <div className="card-item-body">
                      <div className="card-item-stat">
                        <h4 className="font-weight-bold">{data.totalDirection}</h4>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="cards dashboard-card">
              <Card className="card custom-card">
                <Card.Body>
                  <div className="card-item">
                    <div className="card-item-icon card-icon">
                      <i
                        className="mdi mdi-web fs-25"
                        style={{ color: "#6259CA" }}
                      ></i>
                    </div>
                    <div className="card-item-title  mb-2">
                      <label className="main-content-label tx-13 font-weight-bold">
                        Total Website clicks
                      </label>
                    </div>
                    <div className="card-item-body">
                      <div className="card-item-stat">
                        <h4 className="font-weight-bold">
                          {data.totalWebsiteClicks}
                        </h4>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            {/* </div> */}
          </div>
          <Row className="row-sm">
            <Col sm={12} lg={12} xl={12}>
              <Card className="custom-card overflow-hidden">
                <Card.Header className="border-bottom-0">
                  <div>
                    <label className="main-content-label mb-2">GRAPH</label>
                    <span className="d-block tx-12 mb-0 text-muted">
                      {/* It's a graph when calls, counsellor and user are created. */}
                    </span>
                  </div>
                </Card.Header>
                <Card.Body className="ps-12">
                {/* pt-0 */}
                  <div>
                    {/* <Container> */}
                    <div className="chart-dropshadow2 ht-300">
                      <div className="chart-dropshadow2 ht-300">
                        <MainGraph
                          data={data}
                          booking={booking}
                          user={user}
                          vendor={vendor}
                          profit={profit}
                          query={query}
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Fragment>
      )}
    </>
  );
};

export default Dashboard;
