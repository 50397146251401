import React from "react";
import { Form, Modal } from "react-bootstrap";

const ImageModal = ({ show, onHide, selectedImage }) => {
  //   console.log("🚀  ImageModal  selectedImage:", selectedImage);
  return (
    <Modal
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "22px", color: "black" }}>Report Image</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="col-12">
          <div className="card custom-card">
            <div className="col-12">
              {selectedImage && (
                <img src={selectedImage} alt="Selected Image" />
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ImageModal;
