import moment from "moment";
import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import "react-phone-input-2/lib/style.css";

const AddVendorModal = ({
  loading,
  show,
  onHide,
  edit,
  formik,
  isDefault,
  options,
  handleSelectChange,
  selectedOption,
  setSelectedOption,
}) => {
  return (
    <Modal
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton onClick={() => setSelectedOption(null)}>
        <Modal.Title style={{ fontSize: "22px", color: "black" }}>
          {edit ? "Update Vendor Detail" : "Add Vendor Detail"}
        </Modal.Title>{" "}
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body className="text-center">
          <div className="col-122">
            <div className="card custom-card">
              {/* <h4>How Many Add Refer Code</h4> */}
              <div className="col-12">
                <Form.Group
                  controlid="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                    }}
                  >
                    First Name
                    <span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.firstName && formik.touched.firstName ? (
                    <p className="text-start error">
                      {" "}
                      {formik.errors.firstName}
                    </p>
                  ) : null}
                </Form.Group>
                <Form.Group
                  controlid="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Last Name
                    <span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.lastName && formik.touched.lastName ? (
                    <p className="text-start error">
                      {" "}
                      {formik.errors.lastName}
                    </p>
                  ) : null}
                </Form.Group>
                {!edit ? (
                  <>
                    <Form.Group
                      controlId="validationFormik101"
                      className="position-relative"
                    >
                      <Form.Label
                        style={{
                          textAlign: "start",
                          color: "#000",
                          marginTop: "15px",
                        }}
                      >
                        Select Email or PhoneNumber
                        <span className="tx-danger">*</span>
                      </Form.Label>
                      <Select
                        value={selectedOption}
                        onChange={handleSelectChange}
                        options={options}
                      />
                    </Form.Group>
                    {isDefault === "email" ? (
                      <>
                        <Form.Group
                          controlid="validationFormik101"
                          className="position-relative"
                        >
                          <Form.Label
                            style={{
                              textAlign: "start",
                              color: "#000",
                              marginTop: "15px",
                            }}
                          >
                            E-mail
                            <span className="tx-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="E-mail"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                          />
                          {formik.errors.email && formik.touched.email ? (
                            <p className="text-start error">
                              {" "}
                              {formik.errors.email}
                            </p>
                          ) : null}
                        </Form.Group>
                        <Form.Group
                          controlid="validationFormik101"
                          className="position-relative"
                        >
                          <Form.Label
                            style={{
                              textAlign: "start",
                              color: "#000",
                              marginTop: "15px",
                            }}
                          >
                            Password
                            <span className="tx-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="password"
                            name="password"
                            placeholder="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                          />
                          {formik.errors.password && formik.touched.password ? (
                            <p className="text-start error">
                              {" "}
                              {formik.errors.password}
                            </p>
                          ) : null}
                        </Form.Group>
                      </>
                    ) : isDefault === "mobileNumber" ? (
                      <Form.Group
                        controlid="validationFormik101"
                        className="position-relative"
                      >
                        <Form.Label
                          style={{
                            textAlign: "start",
                            color: "#000",
                            marginTop: "15px",
                          }}
                        >
                          Phone Number
                          <span className="tx-danger">*</span>
                        </Form.Label>
                        {/* <Form.Control
                      type="number"
                      name="mobileNumber"
                      placeholder="Phone Number"
                      value={formik.values.mobileNumber}
                      onChange={formik.handleChange}
                    /> */}
                        <PhoneInput
                          className="addContacts"
                          country={"in"}
                          value={formik.values.mobileNumber}
                          onChange={(value) =>
                            formik.setFieldValue("mobileNumber", value)
                          }
                        />
                        {formik.errors.mobileNumber &&
                        formik.touched.mobileNumber ? (
                          <p className="text-start error">
                            {" "}
                            {formik.errors.mobileNumber}
                          </p>
                        ) : null}
                      </Form.Group>
                    ) : (
                      ""
                    )}
                  </>
                ) : null}
                {selectedOption && selectedOption.value === "email" && (
                  <>
                    <Form.Group
                      controlid="validationFormik101"
                      className="position-relative"
                    >
                      <Form.Label
                        style={{
                          textAlign: "start",
                          color: "#000",
                          marginTop: "15px",
                        }}
                      >
                        E-mail
                        <span className="tx-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="E-mail"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.email && formik.touched.email ? (
                        <p className="text-start error">
                          {" "}
                          {formik.errors.email}
                        </p>
                      ) : null}
                    </Form.Group>
                  </>
                )}
                {selectedOption && selectedOption.value === "mobileNumber" && (
                  <>
                    {" "}
                    <Form.Group
                      controlid="validationFormik101"
                      className="position-relative"
                    >
                      <Form.Label
                        style={{
                          textAlign: "start",
                          color: "#000",
                          marginTop: "15px",
                        }}
                      >
                        Phone Number
                        <span className="tx-danger">*</span>
                      </Form.Label>
                      {/* <Form.Control
                    type="number"
                    name="mobileNumber"
                    placeholder="Phone Number"
                    value={formik.values.mobileNumber}
                    onChange={formik.handleChange}
                  /> */}
                      <PhoneInput
                        className="addContacts"
                        country={"in"}
                        value={formik.values.mobileNumber}
                        onChange={(value) =>
                          formik.setFieldValue("mobileNumber", value)
                        }
                      />
                      {formik.errors.mobileNumber &&
                      formik.touched.mobileNumber ? (
                        <p className="text-start error">
                          {" "}
                          {formik.errors.mobileNumber}
                        </p>
                      ) : null}
                    </Form.Group>
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                aria-hidden="true"
                animation="border"
                style={{ color: "white", width: "24px", height: "24px" }}
                size="sm"
                role="status"
              />
            </Button>
          ) : (
            <Button type="submit" style={{ marginBottom: "0px" }}>
              {edit ? "UPDATE" : "ADD"}
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddVendorModal;
