import { useSnackbar } from "notistack";
import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

const DeleteUserList = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { loading } = props;
  return (
    <Modal
      {...props}
      centered
      show={props.show}
      onHide={props.onHide}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          <Modal.Title>
            {props.path === "/users" ? (
              <>Delete User Detail</>
            ) : props.path === "/helpandsupport" ? (
              <>Delete Help and Support</>
            ) : props.path === "/users/view/1" ? (
              <>Delete Query Question</>
            ) : props.path === "/vendors/view/1" ? (
              <>Delete Query Question</>
            ) : (
              <>Delete Vendor Details</>
            )}{" "}
          </Modal.Title>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="text-center">
        <i className="fe fe-trash tx-100 tx-danger  mg-t-20 "></i>

        <p className="mg-b-20 mg-x-20 mt-3">
          {props.path === "/users" ? (
            <>
              {" "}
              Are you sure want to <b>delete {} User Details !!</b>
            </>
          ) : props.path === "/helpandsupport" ? (
            <>
              Are you sure want to <b>delete {} Help & Support Details !!</b>
            </>
          ) : props.path === "/users/view/1" ? (
            <>
              Are you sure want to <b>delete {} Query Question Details !!</b>
            </>
          ) : props.path === "/vendors/view/1" ? (
            <>
              Are you sure want to <b>delete {} Query Question Details !!</b>
            </>
          ) : (
            <>
              {" "}
              Are you sure want to <b>delete {} Vender Details !!</b>
            </>
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        {props.loading ? (
          <Button variant="danger" disabled>
            <Spinner
              as="span"
              aria-hidden="true"
              animation="border"
              style={{ color: "white", width: "24px", height: "24px" }}
              size="sm"
              role="status"
            />
          </Button>
        ) : (
          <Button variant="danger" type="submit" onClick={props.onDelete}>
            Delete
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteUserList;
