import serverCall from "../../serverCall";

const updateQueryQue = async (questionId, question) => {
  try {
    const response = await serverCall.post(
      `/query/admin/update-question?questionId=${questionId}&question=${question}`
    );
    return response;
  } catch (err) {
    console.log("server update failed query question", err);
  }
};

const updateQueryAns = async (answerId, answer) => {
  try {
    const response = await serverCall.post(
      `/query/admin/update-answer?answerId=${answerId}&answer=${answer}`
    );
    return response;
  } catch (err) {
    console.log("server update failed ans ", err);
  }
};

const deleteQueryQue = async (questionId) => {
  try {
    const response = await serverCall.delete(
      `/query/admin/delete-question?questionId=${questionId}`
    );
    return response;
  } catch (err) {
    console.log("server delete failed ans", err);
  }
};

const deleteQueryAns = async (answerId) => {
  try {
    const response = await serverCall.delete(
      `/query/admin/delete-answer?answerId=${answerId}`
    );
    return response;
  } catch (err) {
    console.log("server delete failed ans", err);
  }
};

const UpdateQueryService = {
  updateQueryQue,
  updateQueryAns,
  deleteQueryAns,
  deleteQueryQue,
};

export default UpdateQueryService;
