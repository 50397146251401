import React, { Fragment, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import moment from "moment";
import "react-phone-number-input/style.css";
import "react-phone-input-2/lib/style.css";

const AddUserList = (props) => {
  const {
    handleSelectChange,
    selectedOption,
    isDefault,
    dateOnChange,
    options,
    loading,
    setIsEmail,
    setIsDefault,
    setSelectedOption,
  } = props;
  console.log("selectedOption", selectedOption);

  console.log(props);
  return (
    <Modal
      {...props}
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton onClick={() => setSelectedOption(null)}>
        <Modal.Title style={{ fontSize: "22px", color: "black" }}>
          {props.edit ? "Update User List" : "Add User List"}
        </Modal.Title>{" "}
      </Modal.Header>
      <form onSubmit={props.formik.handleSubmit}>
        <Modal.Body className="text-center">
          <div className="col-122">
            <div className="card custom-card">
              {/* <h4>How Many Add Refer Code</h4> */}
              <div className="col-12">
                <Form.Group
                  controlid="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                    }}
                  >
                    First Name
                    <span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={props.formik.values.firstName}
                    onChange={props.formik.handleChange}
                  />
                  {props.formik.errors.firstName &&
                  props.formik.touched.firstName ? (
                    <p className="text-start error">
                      {" "}
                      {props.formik.errors.firstName}
                    </p>
                  ) : null}
                </Form.Group>
                <Form.Group
                  controlid="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Last Name
                    <span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={props.formik.values.lastName}
                    onChange={props.formik.handleChange}
                  />
                  {props.formik.errors.lastName &&
                  props.formik.touched.lastName ? (
                    <p className="text-start error">
                      {" "}
                      {props.formik.errors.lastName}
                    </p>
                  ) : null}
                </Form.Group>
                {!props.edit ? (
                  <>
                    <Form.Group
                      controlId="validationFormik101"
                      className="position-relative"
                    >
                      <Form.Label
                        style={{
                          textAlign: "start",
                          color: "#000",
                          marginTop: "15px",
                        }}
                      >
                        Select Email or PhoneNumber
                        <span className="tx-danger">*</span>
                      </Form.Label>
                      <Select
                        value={selectedOption}
                        onChange={handleSelectChange}
                        options={options}
                      />
                    </Form.Group>
                    {isDefault === "email" ? (
                      <>
                        <Form.Group
                          controlid="validationFormik101"
                          className="position-relative"
                        >
                          <Form.Label
                            style={{
                              textAlign: "start",
                              color: "#000",
                              marginTop: "15px",
                            }}
                          >
                            E-mail
                            <span className="tx-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="E-mail"
                            value={props.formik.values.email}
                            onChange={props.formik.handleChange}
                          />
                          {props.formik.errors.email &&
                          props.formik.touched.email ? (
                            <p className="text-start error">
                              {" "}
                              {props.formik.errors.email}
                            </p>
                          ) : null}
                        </Form.Group>
                        <Form.Group
                          controlid="validationFormik101"
                          className="position-relative"
                        >
                          <Form.Label
                            style={{
                              textAlign: "start",
                              color: "#000",
                              marginTop: "15px",
                            }}
                          >
                            Password
                            <span className="tx-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="password"
                            name="password"
                            placeholder="password"
                            value={props.formik.values.password}
                            onChange={props.formik.handleChange}
                          />
                          {props.formik.errors.password &&
                          props.formik.touched.password ? (
                            <p className="text-start error">
                              {" "}
                              {props.formik.errors.password}
                            </p>
                          ) : null}
                        </Form.Group>
                      </>
                    ) : isDefault === "mobileNumber" ? (
                      <Form.Group
                        controlid="validationFormik101"
                        className="position-relative"
                      >
                        <Form.Label
                          style={{
                            textAlign: "start",
                            color: "#000",
                            marginTop: "15px",
                          }}
                        >
                          Phone Number
                          <span className="tx-danger">*</span>
                        </Form.Label>
                        {/* <Form.Control
                      type="number"
                      name="mobileNumber"
                      placeholder="Phone Number"
                      value={props.formik.values.mobileNumber}
                      onChange={props.formik.handleChange}
                    /> */}
                        <PhoneInput
                          className="addContacts"
                          country={"in"}
                          value={props.formik.values.mobileNumber}
                          onChange={(value) =>
                            props.formik.setFieldValue("mobileNumber", value)
                          }
                        />
                        {props.formik.errors.mobileNumber &&
                        props.formik.touched.mobileNumber ? (
                          <p className="text-start error">
                            {" "}
                            {props.formik.errors.mobileNumber}
                          </p>
                        ) : null}
                      </Form.Group>
                    ) : (
                      ""
                    )}
                  </>
                ) : null}
                {selectedOption && selectedOption.value === "email" && (
                  <>
                    <Form.Group
                      controlid="validationFormik101"
                      className="position-relative"
                    >
                      <Form.Label
                        style={{
                          textAlign: "start",
                          color: "#000",
                          marginTop: "15px",
                        }}
                      >
                        E-mail
                        <span className="tx-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="E-mail"
                        value={props.formik.values.email}
                        onChange={props.formik.handleChange}
                      />
                      {props.formik.errors.email &&
                      props.formik.touched.email ? (
                        <p className="text-start error">
                          {" "}
                          {props.formik.errors.email}
                        </p>
                      ) : null}
                    </Form.Group>
                  </>
                )}
                {selectedOption && selectedOption.value === "mobileNumber" && (
                  <>
                    {" "}
                    <Form.Group
                      controlid="validationFormik101"
                      className="position-relative"
                    >
                      <Form.Label
                        style={{
                          textAlign: "start",
                          color: "#000",
                          marginTop: "15px",
                        }}
                      >
                        Phone Number
                        <span className="tx-danger">*</span>
                      </Form.Label>
                      {/* <Form.Control
                    type="number"
                    name="mobileNumber"
                    placeholder="Phone Number"
                    value={props.formik.values.mobileNumber}
                    onChange={props.formik.handleChange}
                  /> */}
                      <PhoneInput
                        className="addContacts"
                        country={"in"}
                        value={props.formik.values.mobileNumber}
                        onChange={(value) =>
                          props.formik.setFieldValue("mobileNumber", value)
                        }
                      />
                      {props.formik.errors.mobileNumber &&
                      props.formik.touched.mobileNumber ? (
                        <p className="text-start error">
                          {" "}
                          {props.formik.errors.mobileNumber}
                        </p>
                      ) : null}
                    </Form.Group>
                  </>
                )}
                <Form.Group
                  controlid="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Date of Birth
                    <span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="birthdate"
                    placeholder="D-O-B"
                    value={
                      props.formik.values.birthdate
                        ? moment(
                            props.formik.values.birthdate,
                            "DD/MM/YYYY"
                          ).format("YYYY-MM-DD")
                        : ""
                    }
                    onChange={(e) => {
                      const selectedDate = moment(e.target.value, "YYYY-MM-DD");
                      const formattedDate = selectedDate.isValid()
                        ? selectedDate.format("DD/MM/YYYY")
                        : "";
                      console.log("formattedDate", formattedDate);
                      props.formik.handleChange({
                        target: {
                          name: "birthdate",
                          value: formattedDate,
                        },
                      });
                    }}
                    max={moment(new Date())
                      .subtract(18, "years")
                      .format("YYYY-MM-DD")}
                  />

                  {props.formik.errors.birthdate &&
                  props.formik.touched.birthdate ? (
                    <p className="text-start error">
                      {" "}
                      {props.formik.errors.birthdate}
                    </p>
                  ) : null}
                </Form.Group>

                {/* <Form.Group
                  controlid="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Phone Number
                    <span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="phonenumber"
                    placeholder="Phone Number"
                    value={props.formik.values.phonenumber}
                    onChange={props.formik.handleChange}
                  />
                  {props.formik.errors.phonenumber &&
                  props.formik.touched.phonenumber ? (
                    <p className="text-start error">
                      {" "}
                      {props.formik.errors.phonenumber}
                    </p>
                  ) : null}
                </Form.Group> */}
                <Form.Group
                  controlid="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Profile Image
                    {/* <span className="tx-danger">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="profileImage"
                    // value={props.formik.values.profileImage} 
                    onChange={(event) => {
                      const file = event.target.files[0];
                      // Do something with the file, like store it in state
                      props.formik.setFieldValue("profileImage", file);
                    }}
                  />
                  {/* {props.formik.errors.profileImage &&
                  props.formik.touched.profileImage ? (
                    <p className="text-start error">
                      {" "}
                      {props.formik.errors.profileImage}
                    </p>
                  ) : null} */}
                </Form.Group>
                {props.edit && (
                  <div className="mt-3">
                    {props.formik.values.profileImage && (
                      <img
                        src={props.formik.values.profileImage}
                        alt="Current profileImage"
                        style={{ maxWidth: "100%", maxHeight: "200px" }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                aria-hidden="true"
                animation="border"
                style={{ color: "white", width: "24px", height: "24px" }}
                size="sm"
                role="status"
              />
            </Button>
          ) : (
            <Button type="submit" style={{ marginBottom: "0px" }}>
              {props.edit ? "UPDATE" : "ADD"}
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddUserList;
