import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

const AddEcofriendly = ({
  show,
  onHide,
  formik,
  isEcoEdit,
  loading,
  handleFileChangeEco,
  isEcoImage,
  setIsEcoImage,
}) => {
  console.log("AddEco", isEcoEdit);
  return (
    <Modal
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton onClick={() => setIsEcoImage("")}>
        <Modal.Title style={{ fontSize: "22px", color: "black" }}>
          {isEcoEdit ? "Update Eco-Friendly" : "Add Eco-Friendly"}
        </Modal.Title>{" "}
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body className="text-center">
          <div className="col-12">
            <div className="card custom-card">
              {/* <h4>How Many Add Refer Code</h4> */}
              <div className="col-12">
                <Form.Group
                  controlid="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                    }}
                  >
                    Eco-Friendly
                    <span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Eco-Friendly"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.name && formik.touched.name ? (
                    <p className="text-start error"> {formik.errors.name}</p>
                  ) : null}
                </Form.Group>
              </div>
              <div className="col-12">
                <Form.Group
                  controlid="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Logo
                    {/* <span className="tx-danger">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="logo"
                    // value={formik.values.logo}
                    // onChange={(event) => {
                    //   const file = event.target.files[0];
                    //   // Do something with the file, like store it in state
                    //   formik.setFieldValue("logo", file);
                    // }}
                    onChange={handleFileChangeEco}
                  />
                  {formik.errors.logo && formik.touched.logo ? (
                    <p className="text-start error"> {formik.errors.logo}</p>
                  ) : null}
                </Form.Group>
                {isEcoEdit && (
                  <div className="mt-3">
                    {formik.values.logo && (
                      <img
                        src={isEcoImage ? isEcoImage : formik.values.logo}
                        alt="Current logo"
                        style={{ maxWidth: "100%", maxHeight: "200px" }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                aria-hidden="true"
                animation="border"
                style={{ color: "white", width: "24px", height: "24px" }}
                size="sm"
                role="status"
              />
            </Button>
          ) : (
            <Button type="submit" style={{ marginBottom: "0px" }}>
              {isEcoEdit ? "UPDATE" : "ADD"}
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddEcofriendly;
