import { useSnackbar } from "notistack";
import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

const DeleteAns = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <div>
      <Modal
        {...props}
        centered
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <Modal.Title>Delete Query Answer</Modal.Title>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-center">
          <i className="fe fe-trash tx-100 tx-danger  mg-t-20 "></i>

          <p className="mg-b-20 mg-x-20 mt-3">
            Are you sure want to <b>delete {} Query Answer Details !!</b>
          </p>
        </Modal.Body>
        <Modal.Footer>
          {props.loading ? (
          <Button variant="danger" disabled>
            <Spinner
              as="span"
              aria-hidden="true"
              animation="border"
              style={{ color: "white", width: "24px", height: "24px" }}
              size="sm"
              role="status"
            />
          </Button>
        ) : (
          <Button variant="danger" type="submit" onClick={props.onDelete}>
            Delete
          </Button>
          )} 
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteAns;
