import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Form, Link, useLocation, useParams } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import ReportTicketService from "../../services/repoetTicket.service";
import User from "../../../assets/img/users/user.png";
import { enqueueSnackbar } from "notistack";
import { useRef } from "react";
import Select from "react-select";
import { CircularProgress } from "@mui/material";
import ImageModal from "./ImageModal";
import UseDialogState from "../../../hooks/UseDialogState";

const ReportChat = () => {
  const { open, handleClickOpen, handleClickClose } = UseDialogState();
  const { id } = useParams();
  const scrollRef = useRef();
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [statusShow, setStatusShow] = useState();
  const [resportStatus, setResportStatus] = useState([]);
  const [reportDetail, setReportDetail] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  // console.log("reportStatusreportStatus", selectedImage);

  const scrollToBottom = () => {
    const element = document.querySelector(".content-inner");
    // console.log("🚀  scrollToBottom  element:", element);
    if (element !== null) {
      element.behavior = "smooth";
      element.scrollTop = element.scrollHeight;
    }
  };

  const GetMessage = async () => {
    try {
      setLoadingData(false);
      const response = await ReportTicketService.getMessage(id);
      console.log("🚀  GetMessage  response:", response);
      setData(response.data.data);
      setStatusShow(response.data.data.reportId.status);
      setReportDetail(response.data.data.reportId);
      scrollToBottom();
    } catch (error) {
      setLoadingData(false);
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [data, GetMessage]);

  const createReportData = async () => {
    try {
      const response = await ReportTicketService.createReport(id);
      // console.log("Create Report", response);
      GetMessage();
    } catch (error) {
      // console.log("Failed to create", error);
    }
  };

  useEffect(() => {
    createReportData();
  }, []);

  const sendMessage = async () => {
    try {
      setLoading(true);
      await ReportTicketService.sendMessage(id, message);
      setMessage("");
      GetMessage();
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: "error",
      });
    }
    setLoading(false);
  };

  const handleTextChange = (e) => {
    setMessage(e.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && message !== "") {
      sendMessage();
    }
  };

  useEffect(() => {
    // GetMessage();
    scrollToBottom();
  }, []);

  const statusChangeData = async (values) => {
    try {
      setStatusShow(values.value);
      const response = await ReportTicketService.statusChange(id, values.value);
      setResportStatus(response.data.data.status);
      // console.log("Status changed", response);
      enqueueSnackbar("Status Change Successfully!", { variant: "success" });
    } catch (err) {
      // console.log("Error", err);
    }
  };

  const reportOption = [
    // {
    //   label: "In review",
    //   value: "In review",
    // },
    {
      label: "Responded",
      value: "Responded",
    },
    {
      label: "Resolved",
      value: "Resolved",
    },
  ];

  // console.log("ssssssss", reportOption);

  const handleImageOpen = (images) => {
    // console.log("🚀  handleImageOpen  images:", images);
    handleClickOpen();
    setSelectedImage(images);
  };

  return (
    <>
      {loadingData ? (
        <div className="loader-container">
          <div
            style={{ position: "absolute", top: "50%", left: "50%" }}
            className="loader"
          >
            Just A Moment....
          </div>
        </div>
      ) : (
        <>
          <div className="page-header">
            <h2 className="main-content-title tx-24 mb-2 mt-2">REPORT</h2>
          </div>
          {/* <Row className="row-sm">
          <Col sm={12} md={12} lg={12} xl={8}> */}
          <div className="m-0 p-0 row row-sm show-detail">
            <div className="col-lg-8 col-12 position-relative">
              <Card className="custom-card">
                <div className="main-content-app pt-0">
                  <div className="main-content-body main-content-body-chat h-100">
                    <div className="main-chat-header pt-3">
                      <div className="main-img-user online">
                        {data?.participantIds?.map((data) => {
                          return (
                            <>
                              {data._id === state?.reporterId && (
                                <img
                                  alt="avatar"
                                  src={
                                    data.profileImage ? data.profileImage : User
                                  }
                                />
                              )}
                            </>
                          );
                        })}
                      </div>
                      <div className="main-chat-msg-name">
                        <h6>
                          {data?.participantIds?.map(
                            (data) =>
                              data._id === state?.reporterId && data.userName
                          )}
                        </h6>
                      </div>
                      <div style={{ marginLeft: "auto", zIndex: "9" }}>
                        {statusShow === "In review" && (
                          <Select
                            className="text-white"
                            options={reportOption}
                            onChange={statusChangeData}
                            // value={reportOption.find(
                            //   (value) => value.value === statusShow
                            // )}
                            value={{
                              label: "In review",
                              value: "In review",
                            }}
                            placeholder="In review"
                            isSearchable={false}
                            styles={{
                              container: (provided) => ({
                                ...provided,
                                width: 125,
                                borderRadius: 5,
                                backgroundColor: "#ff9b21",
                                zIndex: 9999,
                              }),
                              control: (styles) => ({
                                ...styles,
                                // width: "140px",
                                backgroundColor: "#5353FF",
                                color: "white !important",
                                cursor: "pointer",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                backgroundColor:
                                  state.data.value === "In review"
                                    ? "#5353FF"
                                    : state.data.value === "Resolved"
                                    ? "#808080"
                                    : state.data.value === "Responded"
                                    ? "#01A001"
                                    : provided.backgroundColor,
                                color: "white",
                                padding: "10px",
                                border: "2px solid white",
                                borderRadius: "5px",
                                cursor: "pointer",
                              }),
                              singleValue: (provided, state) => ({
                                ...provided,
                                backgroundColor:
                                  state.data.value === "In review"
                                    ? "#5353FF"
                                    : state.data.value === "Resolved"
                                    ? "#808080"
                                    : state.data.value === "Responded"
                                    ? "#01A001"
                                    : provided.backgroundColor,
                                color: "white",
                                overflow: "visible",
                                marginLeft: "0px",
                              }),
                              color: "white !important",
                            }}
                          />
                        )}
                        {statusShow === "Responded" && (
                          <button
                            className="btn btn-sm"
                            style={{
                              width: "110px",
                              backgroundColor: "#01A001",
                              color: "white",
                            }}
                          >
                            Responded
                          </button>
                        )}
                        {statusShow === "Resolved" && (
                          <button
                            className="btn btn-sm"
                            style={{
                              width: "110px",
                              backgroundColor: "#808080",
                              color: "white",
                            }}
                          >
                            Resolved
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="main-chat-body" id="ChatBody">
                      <PerfectScrollbar
                        className="chat-conversation-box"
                        // ref={scrollRef}
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          overflow: "auto",
                          alignItems: "end",
                          flexDirection: "column-reverse",
                        }}
                      >
                        <div
                          className="content-inner w-100 h-100"
                          style={{ overflowY: "auto" }}
                        >
                          {data?.messages?.map((msg, index) => {
                            console.log(
                              "senderId._id",
                              state,
                              msg.senderId._id
                            );
                            return (
                              <>
                                {state?.reporterId !== msg.senderId._id && (
                                  <div
                                    className="media flex-row-reverse"
                                    key={index}
                                  >
                                    <div className="main-img-user online">
                                      <img
                                        alt="avatar"
                                        src={
                                          msg.senderId.profileImage
                                            ? msg.senderId.profileImage
                                            : User
                                        }
                                      />
                                    </div>
                                    <div className="media-body">
                                      <div className="main-msg-wrapper2">
                                        {msg.message}
                                      </div>
                                      <div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {state?.reporterId === msg.senderId._id && (
                                  <div className="media">
                                    <div className="main-img-user online">
                                      <img
                                        alt="avatar"
                                        src={
                                          msg.senderId.profileImage
                                            ? msg.senderId.profileImage
                                            : User
                                        }
                                      />
                                    </div>
                                    <div className="media-body">
                                      <div className="main-msg-wrapper">
                                        {msg.message}
                                      </div>
                                      <div></div>
                                    </div>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </PerfectScrollbar>
                    </div>
                    <div className="main-chat-footer">
                      {/* <nav className="nav">
                    {["top"].map((placement) => (
                      <OverlayTrigger
                        title="Add Photo"
                        data-bs-toggle="tooltip"
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            Add Photo
                          </Tooltip>
                        }
                      >
                        <i className="fe fe-image nav-link"></i>
                      </OverlayTrigger>
                    ))}
                    {["top"].map((placement) => (
                      <OverlayTrigger
                        title="Emoji"
                        data-bs-toggle="tooltip"
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            Record Voice
                          </Tooltip>
                        }
                      >
                        <i className="fe fe-mic nav-link"></i>
                      </OverlayTrigger>
                    ))}
                  </nav> */}
                      <input
                        className="form-control"
                        placeholder="Type your message here..."
                        type="text"
                        value={message}
                        onKeyPress={handleKeyPress}
                        onChange={handleTextChange}
                      />
                      <div onClick={sendMessage} className="main-msg-send">
                        {loading ? (
                          <div className="d-flex justify-content-center">
                            <CircularProgress />
                          </div>
                        ) : (
                          ["top"].map((placement) => (
                            <OverlayTrigger
                              title="paper"
                              data-bs-toggle="tooltip"
                              key={placement}
                              placement={placement}
                              overlay={
                                <Tooltip id={`tooltip-${placement}`}>
                                  Send
                                </Tooltip>
                              }
                            >
                              <i className="far fa-paper-plane"></i>
                            </OverlayTrigger>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
              {/* </Col> */}
            </div>
            <div className="col-lg-4 col-12">
              {/* <Col sm={12} md={12} lg={12} xl={4}> */}
              <div className="overflow-hidden main-content-app p-4 pt-0 mb-4">
                <div className="overflow-auto w-100" style={{ height: "100%" }}>
                  <div>
                    <Card.Title style={{ fontSize: "22px", fontWeight: "400" }}>
                      {reportDetail.reason}
                    </Card.Title>
                    <Card.Text>{reportDetail.description}</Card.Text>
                    <div className="timeless-main">
                      <div className="timeliness-report">
                        {reportDetail.images &&
                          reportDetail.images.map((item, index) => (
                            <div
                              key={index}
                              onClick={() => handleImageOpen(item)}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={item}
                                alt=""
                                style={{
                                  width: "220px",
                                  height: "220px",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </Col> */}
            </div>
          </div>
          {/* // </Row> */}
          <ImageModal
            show={open}
            onHide={handleClickClose}
            selectedImage={selectedImage}
          />
        </>
      )}
    </>
  );
};

export default ReportChat;
