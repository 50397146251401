import { useState } from "react";

function useMenuState(initialState) {
  const [isOpen, setIsOpen] = useState(initialState);
  const [stateEdit, setStateEdit] = useState(initialState);

  const handleIsOpen = () => {
    setIsOpen(true);
  };

  const handleIsClose = () => {
    setIsOpen(false);
  };

  const handleStateOpen = () => {
    setStateEdit(true);
  };

  const handleStateClose = () => {
    setStateEdit(false);
  };

  return [
    isOpen,
    handleIsOpen,
    handleIsClose,
    stateEdit,
    handleStateOpen,
    handleStateClose,
  ];
}

export default useMenuState;
