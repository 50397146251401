import serverCall from "../../serverCall";

const getFeature = async (page, limit) => {
  try {
    const response = await serverCall.get(
      `/business-feature?page=${page}&limit=${limit}`
    );
    return response;
  } catch (err) {
    console.log("Failed to get feature", err);
  }
};

const getFeatureVendor = async () => {
  try {
    const response = await serverCall.get(`/business-feature`);
    return response;
  } catch (err) {
    console.log("Failed to get feature", err);
  }
};

const addFeature = async (data) => {
  try {
    const response = await serverCall.post(`/business-feature/admin`, data);
    return response;
  } catch (err) {
    console.log("Failed to add feature", err);
  }
};

const updateFeature = async (data) => {
  try {
    const response = await serverCall.patch(`/business-feature/admin`, data);
    return response;
  } catch (err) {
    console.log("Failed to update feature", err);
  }
};

const deleteFeature = async (id) => {
  try {
    const response = await serverCall.delete(
      `/business-feature/admin?id=${id}`
    );
    return response;
  } catch (err) {
    console.log("Failed to delete feature", err);
  }
};

const getEcofriendly = async (page, limit) => {
  try {
    const response = await serverCall.get(
      `/eco-friendly?page=${page}&limit=${limit}`
    );
    return response;
  } catch (err) {
    console.log("err get eco", err);
  }
};

const getEcoVendor = async() => {
  const response = await serverCall.get(`/eco-friendly`)
  return response
}

const addEcofriendly = async (data) => {
  try {
    const response = await serverCall.post(`/eco-friendly/admin`, data);
    return response;
  } catch (err) {
    console.log("add eco er", err);
  }
};

const updateEcofriendly = async (data) => {
  try {
    const response = await serverCall.patch(`/eco-friendly/admin`, data);
    return response;
  } catch (err) {
    console.log("error updateeco friendly", err);
  }
};

const deleteEcofriendly = async (id) => {
  try {
    const response = await serverCall.delete(`/eco-friendly/admin?id=${id}`);
    return response;
  } catch (err) {
    console.log("delete eco err", err);
  }
};

const FeatureandEcofriendlyService = {
  getFeature,
  addFeature,
  updateFeature,
  deleteFeature,
  getEcofriendly,
  addEcofriendly,
  updateEcofriendly,
  deleteEcofriendly,
  getFeatureVendor,
  getEcoVendor
};

export default FeatureandEcofriendlyService;
