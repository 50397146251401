import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const LocationMap = ({ setLocationCoordinates, loctionCoordinates }) => {
  const [clickedLocation, setClickedLocation] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);

  const mapContainerStyle = {
    width: "100%",
    height: "847px",
  };

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const { latitude, longitude } = loctionCoordinates;
  //         setCurrentLocation({
  //           lat: latitude,
  //           lng: longitude,
  //         });
  //         setLocationCoordinates({
  //           lat: latitude,
  //           lng: longitude,
  //         });
  //       },
  //       (error) => {
  //         console.error("Error getting the current location:", error);
  //       }
  //     );
  //   } else {
  //     console.error("Geolocation is not supported by this browser.");
  //   }
  // }, []);
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({
            lat: latitude,
            lng: longitude,
          });
          setLocationCoordinates({
            lat: latitude,
            lng: longitude,
          });
        },
        (error) => {
          console.error("Error getting the current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const handleClick = (e) => {
    setCurrentLocation(null);
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();

    setClickedLocation({
      lat,
      lng,
    });
    setLocationCoordinates({
      lat,
      lng,
    });
  };

  return (
    <LoadScript googleMapsApiKey={"AIzaSyApYjbjv4pQ6NPaF61lhNbKSc3n8giy0WA"}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={
          currentLocation
            ? currentLocation
            : clickedLocation || { lat: 0, lng: 0 }
        }
        zoom={15}
        onClick={handleClick}
      >
        {currentLocation && (
          <Marker
            position={{ lat: currentLocation.lat, lng: currentLocation.lng }}
          />
        )}
        {clickedLocation && (
          <Marker
            position={{ lat: clickedLocation.lat, lng: clickedLocation.lng }}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default LocationMap;
