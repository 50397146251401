import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

const AddHelp = (props) => {
  return (
    <Modal
      {...props}
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "22px", color: "black" }}>
          Add Question & Answer
        </Modal.Title>{" "}
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="col-122">
          <div className="card custom-card">
            <form>
              {/* <h4>How Many Add Refer Code</h4> */}
              <div className="col-12">
                <Form.Group
                  controlid="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Question:
                    <span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    type="text"
                    name="generateReferenceCode"
                    placeholder="Enter Question"
                    // value={formik.values.generateReferenceCode}
                    // onChange={formik.handleChange}
                  />
                </Form.Group>
                <Form.Group
                  controlid="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Answer:
                    <span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    type="text"
                    name="generateReferenceCode"
                    placeholder="Enter Answer"
                  />
                </Form.Group>
              </div>

              <div>
                <Button
                  type="submit"
                  style={{
                    display: "flex",
                    marginLeft: "auto",
                    marginRight: "15px",
                    marginTop: "15px",
                  }}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddHelp;
