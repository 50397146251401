import { Space, TimePicker, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import {
  Accordion,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Row,
} from "react-bootstrap";
import Select from "react-select";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import EditQuery from "../userlist/EditQuery";
import { useSnackbar } from "notistack";
import UseDialogState from "../../../hooks/UseDialogState";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { IoArrowBack } from "react-icons/io5";
import DataTable from "react-data-table-component";
import VendorService from "../../services/vendor.service";
import useMenuState from "../../../hooks/useMenuState";
import moment from "moment";
import dayjs from "dayjs";
import UpdateQueryService from "../../services/query.service";
import DeleteVendorAns from "./DeleteVendorAns";
import DeleteVendoeQue from "./DeleteVendoeQue";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import MapModal from "./MapModal";
import UserListSetvice from "../../services/userList.service";
import FeatureandEcofriendlyService from "../../services/featureandeco.service";
import { useRef } from "react";
import { CircularProgress } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import User from "../../../assets/img/users/user.png";
import * as Yup from "yup";

const ViewVendor = () => {
  const [fileList, setFileList] = useState([]);
  console.log("🚀 ~ fileList:", fileList);
  const [fileListService, setFileListService] = useState([]);
  const {
    open,
    handleClickOpen,
    handleClickClose,
    editQuery: modalShow,
    handleEditQueryOpen: handleDialogOpen,
    handleEditQueryClose: handleDialogClose,
    deleteOpen,
    handleClickDeleteOpen,
    handleClickDeleteClose,
    deleteQueryOpen,
    handleClickDeleteQueryOpen,
    handleClickDeleteQueryClose,
  } = UseDialogState();
  const {
    isOpen,
    handleIsOpen,
    handleIsClose,
    stateEdit,
    handleStateOpen,
    handleStateClose,
  } = useMenuState();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const Location = useLocation();
  const viewVendorId = Location?.state?.id;
  const [updateQuery, setUpdateQuery] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  // const [selectedImage, setSelectedImage] = useState(null);
  const [mainCat, setMainCat] = useState(null);
  const [finalData, setFinalData] = useState([]);
  const [subCat, setSubCat] = useState(null);
  const [features, setFeatures] = useState(null);
  const [ecoFriendly, setEcoFriendly] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditOther, setIsEditOther] = useState(false);
  const [isBusinessKeyword, setIsBusinessKeyword] = useState(false);
  const [isBusinessDetail, setIsBusinessDetail] = useState(false);
  const [isBusinessAddress, setIsBusinessAddress] = useState(false);
  const [isBusinesiHour, setIsBusinesiHour] = useState(false);
  const [isBusinesiOther, setIsBusinesiOther] = useState(false);
  const [isBusiPhoto, setIsBusiPhoto] = useState(false);
  const [isBusiService, setIsBusiService] = useState(false);
  const [query, setQuery] = useState([]);
  const [queAns, setQueAns] = useState("");
  const [truncateDescription, setTruncateDescription] = useState(true);
  const [queryQution, setQueryQution] = useState(true);
  const [data, setData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [deleteAns, setdeleteAns] = useState("");
  const [deleteQue, setdeleteQue] = useState("");
  const [businessService, setBusinessService] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loctionCoordinates, setLocationCoordinates] = useState();
  const [isPassChange, setIsPassChange] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passUpdate, setPassUpdate] = useState("******");
  const [businessId, setBusinessId] = useState("");
  const [menuId, setmenuId] = useState("");
  const [categoryMenu, setCategoryMenu] = useState([]);
  const [dropFeature, setDropFeature] = useState([]);
  const [dropEco, setDropEco] = useState([]);
  const [photosOnly, setPhotosOnly] = useState([]);
  const [newImage, setNewImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const [activeMenuIndex, setActiveMenuIndex] = useState(0);
  const [menuImage, setMenuImage] = useState(false);
  const [selectedMenuIamge, setSelectedMenuIamge] = useState([]);
  const [images, setImages] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [hint, setHint] = useState([]);
  const [getAllItemCalled, setGetAllItemCalled] = useState(false);
  const prevActiveMenuIndexRef = useRef(0);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [isLoadingBusiness, setIsLoadingBusiness] = useState(false);
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const [isLoadingHours, setIsLoadingHours] = useState(false);
  const [isLoadingCategory, setIsLoadingCategory] = useState(false);
  const [isLoadingPhotos, setIsLoadingPhotos] = useState(false);
  const [isLoadingMenu, setIsLoadingMenu] = useState(false);
  const [isLoadingSwiper, setIsLoadingSwiper] = useState(false);
  const [isLoadingKeyword, setIsLoadingKeyword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [codeCountry, setCodeCountry] = useState("");
  const [notFound, setNotFound] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [inputValue, setInputValue] = useState([]);
  console.log("🚀  inputValue:", keywords);

  const handleInputChange = (event) => {
    const inputValues = event.target.value;
    const modifiedString = inputValues
      .split(", ")
      .map((word) => (word.startsWith("#") ? word.substring(1) : word))
      .join(", ");
    setKeywords(modifiedString);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (keywords.trim() !== "") {
        const modifiedString = keywords.replace(/,(\s*)$/, "");
        setInputValue((prevInputValue) => [
          ...prevInputValue,
          modifiedString.trim(),
        ]);
        if (keywordFormik) {
          keywordFormik.setFieldValue("keywords", modifiedString);
        }
        setKeywords("");
      }
    }
  };

  const handleRemoveKeyword = (index) => {
    setInputValue((prevInputValue) =>
      prevInputValue.filter((_, i) => i !== index)
    );
  };

  console.log("queryqueryqueryquery", notFound);

  const showFileToUpload = (e) => {
    setNewImage(true);
    let files = Array.from(e.target.files);
    const selectedFiles = e.target.files;
    if (selectedFiles.length > 0) {
      const maxSize = 1200 * 650;
      const invalidFiles = [];

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];

        if (file.size <= maxSize) {
          // setSelectedImage((prevSelectedFiles) => [...prevSelectedFiles, file]);
          busiPhotoFormik.setFieldValue("photos", files);
          const imagesArray = files.map((file) => {
            const imageUrl = URL.createObjectURL(file);
            return `${imageUrl}///${file.name}`;
          });
          setSelectedImage(imagesArray);
        } else {
          invalidFiles.push(file.name);
        }
      }
    } else {
      setSelectedImage([]);
      // setErrorMessage("No files selected");
    }
  };
  const showFileToUploadMenu = (e) => {
    setMenuImage(true);
    let files = Array.from(e.target.files);
    const selectedFiles = e.target.files;
    if (selectedFiles.length > 0) {
      const maxSize = 1200 * 650;
      const invalidFiles = [];

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];

        if (file.size <= maxSize) {
          // setSelectedImage((prevSelectedFiles) => [...prevSelectedFiles, file]);
          busiServiceFormik.setFieldValue("photos", files);
          const imagesArray = files.map((file) => {
            const imageUrl = URL.createObjectURL(file);
            return `${imageUrl}///${file.name}`;
          });
          setSelectedMenuIamge(imagesArray);
        } else {
          invalidFiles.push(file.name);
        }
      }
    } else {
      setSelectedMenuIamge([]);
      // setErrorMessage("No files selected");
    }
  };

  const toggleDescription = (row) => {
    setTruncateDescription((prev) => ({
      ...prev,
      [row._id]: !prev[row._id],
    }));
  };

  const handleQueryMore = (data) => {
    setQueryQution((prev) => ({
      ...prev,
      [data.id]: !prev[data.id],
    }));
  };

  // console.log(
  //   "updateupdate",
  //   categoryMenu.categoryId.map((item) => item.catalogLabel)
  // );

  const viewVendorDetailData = async (message) => {
    const array = [];
    try {
      setLoading(true);
      // setIsLoadingDetail(true);
      const response = await VendorService.viewVendorDetail(viewVendorId);

      const result = response.data.data.vendor;
      formik.setFieldValue("firstName", result.firstName);
      formik.setFieldValue("lastName", result.lastName);
      formik.setFieldValue("email", result.email);
      formik.setFieldValue("password", result.password);
      formik.setFieldValue("profileImage", result.profileImage);
      formik.setFieldValue("mobileNumber", result.mobileNumber);
      formik.setFieldValue("countryCode", result.countryCode);
      setCodeCountry(result.countryCode);
      console.log("setCodeCountry", result.countryCode + result.mobileNumber);
      if (result.email) {
        setSelectedOption({ value: "email", label: "Email" });
      } else if (result.mobileNumber) {
        setSelectedOption({ value: "mobileNumber", label: "Phone Number" });
      }
      setIsLoadingDetail(false);
      setQuery(response.data.data.bussinessDetail.questionWithAnswer);
      setFinalData(response.data.data);
      setPhotosOnly(response.data.data.bussinessDetail.bussinessDetail.photos);
      setNotFound(response.data.data.bussinessDetail.bussinessDetail);
      const bussinessDetail =
        response.data.data.bussinessDetail.bussinessDetail;
      setInputValue(bussinessDetail.keywords);
      keywordFormik.setFieldValue("keywords", bussinessDetail.keywords);
      setIsLoadingKeyword(false);
      bussinessDetailFormik.setFieldValue(
        "bussinessName",
        bussinessDetail.bussinessName
      );
      bussinessDetailFormik.setFieldValue("bussinessId", bussinessDetail._id);
      bussinessDetailFormik.setFieldValue("website", bussinessDetail.website);
      bussinessDetailFormik.setFieldValue(
        "mobileNumber",
        bussinessDetail.mobileNumber
      );
      bussinessDetailFormik.setFieldValue(
        "countryCode",
        bussinessDetail.countryCode
      );
      console.log("🚀  viewVendorDetailData  response:", bussinessDetail);
      setIsLoadingBusiness(false);
      categoryFormik.setFieldValue("bussinessId", bussinessDetail._id);
      categoryFormik.setFieldValue(
        "features",
        bussinessDetail.features.map((feature) => ({
          value: feature._id,
          label: feature.name,
        }))
      );
      categoryFormik.setFieldValue(
        "ecoFriendly",
        bussinessDetail.ecoFriendly.map((eco) => ({
          value: eco._id,
          label: eco.name,
        }))
      );
      console.log(
        "bussinessDet",
        bussinessDetail.ecoFriendly.map((eco) => ({
          value: eco._id,
          label: eco.name,
        }))
      );

      const newLength = bussinessDetail.paymentMethod.length;
      console.log("bussinessDetail.paymentMethod", newLength);
      bussinessDetail.paymentMethod.forEach((item) => {
        if (newLength === 1) {
          if (item === "Cash") {
            const data = ["Cash", ""];
            categoryFormik.setFieldValue("paymentMethod", data);
          } else if (item === "CreaditCard") {
            const data = ["", "CreaditCard"];
            categoryFormik.setFieldValue("paymentMethod", data);
          }
        } else if (newLength === 2) {
          const data = ["Cash", "CreaditCard"];
          categoryFormik.setFieldValue("paymentMethod", data);
        }
      });

      categoryFormik.setFieldValue(
        "bookingPrice",
        bussinessDetail.bookingPrice
      );

      setIsLoadingCategory(false);

      busiPhotoFormik.setFieldValue("photos", bussinessDetail.photos);
      setIsLoadingPhotos(false);
      // busiPhotoFormik.values.photos
      setFileList(bussinessDetail.photos);
      console.log("bussinessDetail.photos", bussinessDetail.photos);
      const bussinessAddress =
        response.data.data.bussinessDetail.bussinessDetail.bussinessAddressId;
      bussinessAddressFormik.setFieldValue("bussinessId", bussinessDetail._id);
      setBusinessId(bussinessDetail._id);
      bussinessAddressFormik.setFieldValue("address", bussinessAddress.address);
      bussinessAddressFormik.setFieldValue(
        "apartment",
        bussinessAddress.apartment
      );
      console.log("bussinessAddress.country", bussinessAddress.country);
      setCountry(bussinessAddress.country);
      bussinessAddressFormik.setFieldValue("city", bussinessAddress.city);
      bussinessAddressFormik.setFieldValue("country", bussinessAddress.country);
      bussinessAddressFormik.setFieldValue("state", bussinessAddress.state);
      bussinessAddressFormik.setFieldValue("zip", bussinessAddress.zip);
      bussinessAddressFormik.setFieldValue(
        "coordinates",
        bussinessAddress.coordinates
      );
      console.log(
        "bussinessAddressFormikbussinessAddressFormik",
        bussinessAddressFormik
      );
      setIsLoadingAddress(false);
      setCategoryMenu(response.data.data.bussinessDetail.category);
      const bussinessHour =
        response.data.data.bussinessDetail.bussinessDetail.bussinessHourId;
      if (bussinessHour !== undefined) {
        Object.entries(bussinessHour).forEach(([day, hours]) => {
          console.log("day, hours", day, hours);
          bussinessHourFormik.setFieldValue(day, hours);
        });
      }
      setIsLoadingHours(false);
      console.log("bussinessHourbussinessHour", bussinessHour);
      setData(response.data.data.bussinessDetail.reservation);
      if (response.data.data.bussinessDetail.menu.length > 0) {
        setImages(
          response.data.data.bussinessDetail.menu[activeMenuIndex].photos
        );
        // busiServiceFormik.setFieldValue("photos", response.data.data.bussinessDetail.menu[activeMenuIndex].photos);

        busiServiceFormik.setFieldValue(
          "price",
          response.data.data.bussinessDetail.menu[activeMenuIndex].price
        );
        busiServiceFormik.setFieldValue(
          "currency",
          response.data.data.bussinessDetail.menu[activeMenuIndex].currency
        );
        busiServiceFormik.setFieldValue(
          "title",
          response.data.data.bussinessDetail.menu[activeMenuIndex].title
        );
        busiServiceFormik.setFieldValue(
          "detail",
          response.data.data.bussinessDetail.menu[activeMenuIndex].detail
        );
        busiServiceFormik.setFieldValue(
          "menuId",
          response.data.data.bussinessDetail.menu[activeMenuIndex]._id
        );
        setmenuId(response.data.data.bussinessDetail.menu[activeMenuIndex]._id);
      }
      setIsLoadingMenu(false);
      setIsLoadingSwiper(false);
      console.log("busiServiceFormik", busiServiceFormik);

      setBusinessService(response.data.data.bussinessDetail.menu);

      // setLocationCoordinates({lat:, lng: response.data.data.bussinessDetail.latitude, lng: response})
      setLocationCoordinates({
        lat: bussinessDetail.location.coordinates[0],
        lng: bussinessDetail.location.coordinates[1],
      });

      const categoryDetail = response.data.data.bussinessDetail.category;
      categoryFormik.setFieldValue(
        "categoryId",
        categoryDetail[0].categoryId._id
      );
      categoryFormik.setFieldValue(
        "mainCat",
        categoryDetail.map((main) => main.categoryId)
      );
      categoryFormik.setFieldValue(
        "subCat",
        categoryDetail.map((sub) => sub.subCategoryId)
      );
      categoryFormik.setFieldValue(
        "serviceItem",
        categoryDetail.map((ser) => ser.item)
      );

      categoryDetail.map(async (item, index) => {
        const getNewList = [
          {
            subCategoryId: item.subCategoryId._id,
            item: item.item.map((ed) => {
              return {
                label: ed.name,
                value: ed._id,
              };
            }),
          },
        ];
        setHint((prev) => [...prev, ...getNewList]);
        // categoryFormik.setFieldValue("serviceItems", (prev) => [
        //   ...prev,
        //   getNewList,
        // ]);

        const subCategoryId = item.subCategoryId._id;
        const responseItem = await VendorService.getAllItem(subCategoryId);
        const data = responseItem.data.data;
        const newData = data.map((item, index) => {
          return {
            item: item.item.map((ed) => {
              return {
                label: ed.name,
                value: ed._id,
              };
            }),
            subcategoryId: item._id,
          };
        });
        const datass = categoryDetail.map((sub) => sub.subCategoryId);
        console.log("categoryDetail", categoryDetail);
        const restaurantData =
          newData[0].subcategoryId === subCategoryId && newData[0];
        array.push(restaurantData);
        const reorderedCategory = datass.map((dataItem) => {
          const matchingCategory = array.find(
            (categoryItem) => categoryItem.subcategoryId === dataItem._id
          );
          return matchingCategory;
        });
        console.log("arrayarray", datass, reorderedCategory);
        setItemList(reorderedCategory);
        console.log(
          "viewVendorDetailnewList"
          // newList
        );
      });
      console.log("categoryDetail", categoryDetail);
      setLoading(false);
      setLoadingData(false);
      enqueueSnackbar(message, { variant: "success" });
    } catch (err) {
      // setIsLoadingDetail(false);
      setLoading(false);
      setLoadingData(false);
      console.log("viewVendorDetailData err", err);
    }
  };

  const MenuItem =
    categoryMenu &&
    categoryMenu?.filter((item) => item.categoryId.catalogLabel === "Menu");
  const ServiceItem =
    categoryMenu &&
    categoryMenu?.filter((item) => item.categoryId.catalogLabel === "Service");

  console.log("categoryMenu", categoryMenu);
  useEffect(() => {
    if (!getAllItemCalled) {
      viewVendorDetailData();
      setGetAllItemCalled(true);
    }
  }, [getAllItemCalled]);

  useEffect(() => {
    if (activeMenuIndex !== prevActiveMenuIndexRef.current) {
      viewVendorDetailData();
    }
    prevActiveMenuIndexRef.current = activeMenuIndex;
  }, [activeMenuIndex]);

  useEffect(() => {
    setGetAllItemCalled(false);
  }, [activeMenuIndex]);

  const handleEditOtherDetails = () => {
    setIsEditOther(!isEditOther);
  };

  const handleBusinessDetail = (data) => {
    if (data === true) {
      setIsLoadingBusiness(true);
      viewVendorDetailData();
    }
    setIsBusinessDetail(!isBusinessDetail);
  };

  const handleBusinessKeyword = (data) => {
    if (data === true) {
      setIsLoadingKeyword(true);
      viewVendorDetailData();
    }
    setIsBusinessKeyword(!isBusinessKeyword);
    setKeywords("");
  };

  const handleBusinessAddress = (data) => {
    if (data === true) {
      setIsLoadingAddress(true);
      viewVendorDetailData();
    }
    setIsBusinessAddress(!isBusinessAddress);
  };

  const handleBusinesiHour = (data) => {
    if (data === true) {
      setIsLoadingHours(true);
      viewVendorDetailData();
    }
    setIsBusinesiHour(!isBusinesiHour);
  };

  const handleBusinesiOther = (data) => {
    if (data === true) {
      setIsLoadingCategory(true);
      viewVendorDetailData();
    }
    setIsBusinesiOther(!isBusinesiOther);
  };

  const handleBusiPhoto = (data) => {
    if (data === true) {
      setIsLoadingPhotos(true);
      viewVendorDetailData();
    }
    setIsBusiPhoto(!isBusiPhoto);
  };

  const handleBusiService = (data) => {
    if (data === true) {
      setIsLoadingMenu(true);
      viewVendorDetailData();
    }
    setIsBusiService(!isBusiService);
  };

  const WeekMap = [
    { name: "Sunday", value: 0, label: "sunday" },
    { name: "Monday", value: 1, label: "monday" },
    { name: "Tuesday", value: 2, label: "tuesday" },
    { name: "Wednesday", value: 3, label: "wednesday" },
    { name: "Thursday", value: 4, label: "thursday" },
    { name: "Friday", value: 5, label: "friday" },
    { name: "Saturday", value: 6, label: "saturday" },
  ];

  const handleMainCategory = (e) => {
    setMainCat(e);
    otherDetailFormik.setFieldValue("maincategory", e);
  };

  const handleSubCategory = (e) => {
    setSubCat(e);
  };

  const handleServices = (e) => {
    console.log("eeeeeee", e);
    setSelectedServices(e);
  };

  const handleFeatures = (e) => {
    categoryFormik.setFieldValue("features", e);
  };

  // const handleItemChange = (e) => {

  //   categoryFormik.setFieldValue("serviceItems", e);
  // };
  const handleItemChange = (selectedOptions, index) => {
    setHint((prev) => {
      const updatedHint = [...prev];
      updatedHint[index].item = selectedOptions;
      return updatedHint;
    });
  };

  console.log("hinthinthinthinthint", hint);

  const handleEcoFrendly = (e) => {
    setEcoFriendly(e);
    categoryFormik.setFieldValue("ecoFriendly", e);
  };
  const handleImageChangeService = (info) => {
    console.log("dasdasda", info.fileList);
    setFileListService(info.fileList);
  };

  const handleOpen = (data, index, i) => {
    setEditOpen(true);
    handleDialogOpen();
    setUpdateQuery(data);
    if (data === "q") {
      setQueAns(true);
      queryFormik.setFieldValue("question", query[index].question.question);
      queryFormik.setFieldValue("questionId", query[index].question._id);
    } else {
      setQueAns(false);
      queryFormik.setFieldValue({
        ...queryFormik.values,
        answer: queryFormik.setFieldValue(
          "answer",
          query[index].answers[i].answer
        ),
      });
      queryFormik.setFieldValue("answerId", query[index].answers[i]._id);
    }
  };

  const deleteQueryAnsData = async () => {
    try {
      const response = await UpdateQueryService.deleteQueryAns(deleteAns);
      console.log("Response delete query", response);
      handleClickDeleteClose();
      viewVendorDetailData();
      enqueueSnackbar("Data Delete Successfully!", { variant: "success" });
    } catch (e) {
      console.log("Error deleting query", e);
    }
  };

  const handleClickDeleteOpenDialog = (data) => {
    console.log("data", data);
    handleClickDeleteOpen();
    setdeleteAns(data);
  };

  const deleteQueryQuesData = async () => {
    try {
      const response = await UpdateQueryService.deleteQueryQue(deleteQue);
      console.log("Response delete query", response);
      handleClickDeleteQueryClose();
      viewVendorDetailData();
      enqueueSnackbar("Data Delete Successfully!", { variant: "success" });
    } catch (e) {
      console.log("Error deleting query", e);
    }
  };

  const handleClickDeleteOpenDialogQue = (data) => {
    console.log("handleClickDeleteOpenDialogQue", data);
    handleClickDeleteQueryOpen();
    setdeleteQue(data.question._id);
  };

  const keywordSubmit = async () => {
    setIsBusinessKeyword(false);
    try {
      setIsLoadingKeyword(true);
      const response = await VendorService.addKeyword({
        businessId,
        keywords: inputValue,
      });
      if (inputValue.length > 0) {
        inputValue.forEach((item) =>
          keywordFormik.setFieldValue("keywords", item)
        );
      }
      keywordFormik.resetForm();
      console.log("Added keyword", response);
      viewVendorDetailData();
    } catch (err) {
      setIsLoadingKeyword(false);
      console.log("ERROR in keywordSubmit", err);
    }
  };

  const keywordFormik = useFormik({
    initialValues: {
      keywords: [],
    },
    onSubmit: keywordSubmit,
  });
  console.log("keywordFormik", keywordFormik.values.keywords);

  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [regionsForSelectedCountry, setRegionsForSelectedCountry] = useState(
    []
  );

  const businessAddressSubmit = async (values) => {
    setIsBusinessAddress(false);
    try {
      setIsLoadingAddress(true);
      const response = await VendorService.updateBussAddress(values);
      viewVendorDetailData("Data Update Successfully!");
      // enqueueSnackbar("Data Update Successfully!", { variant: "success" });
      console.log("success", response);
    } catch (err) {
      setIsLoadingAddress(false);
      console.log("update busi add", err);
    }
  };

  const bussinessAddressFormik = useFormik({
    initialValues: {
      address: "",
      apartment: "",
      city: "",
      country: "",
      state: "",
      zip: "",
      coordinates: [],
    },
    onSubmit: businessAddressSubmit,
  });

  const handleCountryChange = (val) => {
    setCountry(val);
    const regionsByCountry = {
      "United States": ["New York", "California", "Texas"],
    };
    bussinessAddressFormik.setFieldValue("country", val);
    setRegionsForSelectedCountry(regionsByCountry[val] || []);
  };

  useEffect(() => {
    setRegion("");
  }, [country]);

  const handleRegionChange = (val) => {
    setRegion(val);
    bussinessAddressFormik.setFieldValue("state", val);
  };

  const submitQuery = async (values) => {
    const { questionId, question, answerId, answer } = values;
    if (queAns) {
      try {
        setLoading(true);
        const response = await UpdateQueryService.updateQueryQue(
          questionId,
          question
        );
        console.log("Updated query question", response);
        // (values) => {
        //   console.log("Form submitted with values:", values);

        // enqueueSnackbar("Data Update Successfully!", { variant: "success" });
        handleDialogClose();
        setEditOpen(false);
        viewVendorDetailData();
        setLoading(false);
        // }
      } catch (err) {
        setLoading(false);
        console.log("Error submitting query", err);
      }
    } else {
      try {
        setLoading(true);
        const response = await UpdateQueryService.updateQueryAns(
          answerId,
          answer
        );
        handleDialogClose();
        setEditOpen(false);
        viewVendorDetailData();
        setLoading(false);
        // enqueueSnackbar("Data Update Successfully!", { variant: "success" });
        console.log("Updated query", response);
      } catch (e) {
        setLoading(false);
        console.log("Error submitting update query", e);
      }
    }
  };

  const queryFormik = useFormik({
    initialValues: {
      question: "",
      answer: "",
    },
    onSubmit: submitQuery,
  });

  const handleImageChange = (info) => {
    setFileList("target", info.fileList);
    console.log(info.fileList);
    busiPhotoFormik.setFieldValue("photos", info.photos);
  };

  const customRequest = ({ file, onSuccess }) => {
    // Implement your own logic for handling file upload (e.g., using a backend server)
    setTimeout(() => {
      onSuccess();
    }, 0);
  };
  const onChange = (time, timeString) => {
    console.log(time, timeString);
  };

  const [selectedAccordions, setSelectedAccordions] = useState(["0"]);

  const handleAccordionToggle = (eventKey) => {
    const updatedAccordions = selectedAccordions.includes(eventKey)
      ? selectedAccordions.filter((key) => key !== eventKey)
      : [...selectedAccordions, eventKey];

    setSelectedAccordions(updatedAccordions);
  };

  console.log(
    "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
    moment(1711395000388).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
  );

  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const fullDetails =
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.";

  const [showFullDetails, setShowFullDetails] = useState(false);

  const toggleDetails = () => {
    setShowFullDetails(!showFullDetails);
  };

  const handleToggleEditMode = (data) => {
    if (data === true) {
      setIsLoadingDetail(true);
      viewVendorDetailData();
    }
    setIsEdit(!isEdit);
    setIsPassChange(false);
    setShowPassword(false);
    setPassUpdate("******");
  };

  const submit = async (values) => {
    setIsEdit(false);
    try {
      setIsLoadingDetail(true);
      const response = await VendorService.updateVendorDetail(
        viewVendorId,
        values
      );
      console.log("Update vendor", response);
      viewVendorDetailData("Data Update Successfully!");
      // enqueueSnackbar("Data Update Successfully!", { variant: "success" });
      // setIsLoadingDetail(false);
    } catch (e) {
      setIsLoadingDetail(false);
      console.log("Error submitting ", e);
    }
    if (passUpdate !== "******") {
      try {
        setIsLoadingDetail(true);
        const response = await UserListSetvice.changePassword(
          viewVendorId,
          passUpdate
        );
        console.log("change password", response);
        setIsPassChange(false);
        setShowPassword(false);
        setIsLoadingDetail(false);
      } catch (err) {
        setIsLoadingDetail(false);
        console.log("Form submitted update err", err);
      }
    }
  };

  const handlePasswordChange = (e) => {
    setIsPassChange(true);
    setPassUpdate(e.target.value);
    console.log("e.target.value", e.target.value);
  };

  const handlePasswordFieldClick = () => {
    if (isEdit) {
      setPassUpdate("");
      formik.setFieldValue("password", "");
      console.log("clicked");
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      dob: "",
      feedback: "",
      mobileNumber: "",
      status: "",
      profile: "",
      countryCode: "",
    },
    onSubmit: submit,
  });
  console.log("formikformik", formik.values);

  const bussDetailSubmit = async (values) => {
    setIsBusinessDetail(false);
    try {
      setIsLoadingBusiness(true);
      const response = await VendorService.updateBussDetail(values);
      // enqueueSnackbar("Data Update Successfully!", { variant: "success" });
      viewVendorDetailData("Data Update Successfully!");
      console.log("response:", response);
    } catch (err) {
      setIsLoadingBusiness(false);
      console.log("Error get update bussDetailSubmit", err);
    }
  };

  const bussinessDetailFormik = useFormik({
    initialValues: {
      bussinessName: "",
      mobileNumber: "",
      website: "",
      countryCode: "",
    },
    onSubmit: bussDetailSubmit,
  });

  const busiHourSubmit = async (values) => {
    console.log("valuesvaluesvalues", values);
    const { sunday, monday, tuesday, wednesday, thursday, friday, saturday } =
      values;

    setIsBusinesiHour(false);
    try {
      setIsLoadingHours(true);
      const response = await VendorService.updateBusiHour({
        bussinessId: businessId,
        sunday: sunday,
        monday: monday,
        tuesday: tuesday,
        wednesday: wednesday,
        thursday: thursday,
        friday: friday,
        saturday: saturday,
      });
      // enqueueSnackbar("Data Update Successfully!", { variant: "success" });
      viewVendorDetailData("Data Update Successfully!");
      console.log("BusiHour", response);
    } catch (err) {
      setIsLoadingHours(false);
      console.log("Error busiHourSubmit", err);
    }
  };

  const validationSchema = Yup.object().shape({
    sunday: Yup.array().of(
      Yup.object().shape({
        opensAt: Yup.string().required("openAt is required"),
        closesAt: Yup.string().required("closesAt is required"),
      })
    ),
    monday: Yup.array().of(
      Yup.object().shape({
        opensAt: Yup.string().required("openAt is required"),
        closesAt: Yup.string().required("closesAt is required"),
      })
    ),
    tuesday: Yup.array().of(
      Yup.object().shape({
        opensAt: Yup.string().required("openAt is required"),
        closesAt: Yup.string().required("closesAt is required"),
      })
    ),
    wednesday: Yup.array().of(
      Yup.object().shape({
        opensAt: Yup.string().required("openAt is required"),
        closesAt: Yup.string().required("closesAt is required"),
      })
    ),
    friday: Yup.array().of(
      Yup.object().shape({
        opensAt: Yup.string().required("openAt is required"),
        closesAt: Yup.string().required("closesAt is required"),
      })
    ),
    saturday: Yup.array().of(
      Yup.object().shape({
        opensAt: Yup.string().required("openAt is required"),
        closesAt: Yup.string().required("closesAt is required"),
      })
    ),
  });

  const bussinessHourFormik = useFormik({
    initialValues: {
      sunday: [
        {
          closesAt: "",
          opensAt: "",
        },
        {
          closesAt: "",
          opensAt: "",
        },
      ],
      monday: [
        {
          closesAt: "",
          opensAt: "",
        },
        {
          closesAt: "",
          opensAt: "",
        },
      ],
      tuesday: [
        {
          closesAt: "",
          opensAt: "",
        },
        {
          closesAt: "",
          opensAt: "",
        },
      ],
      wednesday: [
        {
          closesAt: "",
          opensAt: "",
        },
        {
          closesAt: "",
          opensAt: "",
        },
      ],
      thursday: [
        {
          closesAt: "",
          opensAt: "",
        },
        {
          closesAt: "",
          opensAt: "",
        },
      ],
      friday: [
        {
          closesAt: "",
          opensAt: "",
        },
        {
          closesAt: "",
          opensAt: "",
        },
      ],
      saturday: [
        {
          closesAt: "",
          opensAt: "",
        },
        {
          closesAt: "",
          opensAt: "",
        },
      ],
    },
    // validationSchema,
    onSubmit: busiHourSubmit,
  });

  console.log("bussinessHourFormikssss", bussinessHourFormik);

  const getFeatureVendorData = async () => {
    try {
      const response = await FeatureandEcofriendlyService.getFeatureVendor();
      setDropFeature(response.data.data);
      console.log("get response feature", response);
    } catch (err) {
      console.log("Failed to get feature vendor data", err);
    }
  };

  useEffect(() => {
    getFeatureVendorData();
  }, []);

  const featureOption = [
    ...dropFeature.map((item) => ({
      value: item._id,
      label: item.name,
    })),
  ];

  const getEcoVendorData = async () => {
    try {
      const response = await FeatureandEcofriendlyService.getEcoVendor();
      setDropEco(response.data.data);
      console.log("get eco vendor data", response);
    } catch (error) {
      console.log("Error", error);
    }
  };

  console.log("regionsForSelectedCountry", regionsForSelectedCountry);

  useEffect(() => {
    getEcoVendorData();
  }, []);

  const ecoOption = [
    ...dropEco.map((item) => ({
      value: item._id,
      label: item.name,
    })),
  ];

  const categorySubmit = async (values) => {
    const {
      features,
      ecoFriendly,
      serviceItems,
      serviceItem,
      service,
      subCat,
      mainCat,
      paymentMethod,
      ...data
    } = values;
    console.log("paymentMethod", paymentMethod);
    const newPayment = paymentMethod.filter((method) => method !== "");
    const newFeature = features.map((feature) => feature.value);
    const newEcofrd = ecoFriendly.map((feature) => feature.value);
    const updatedData = hint.map((category) => ({
      ...category,
      item: category.item.map((item) => item.value),
    }));
    console.log("🚀  categorySubmit  onlyValues:", updatedData);

    //     const updatedData = hint.reduce((accumulator, subArray) => {
    //       // Group subCategories by their value
    //       const groupedSubCategories = hint.reduce((groups, subCategory) => {
    //         const key = subCategory.value;
    //         if (!groups[key]) {
    //           groups[key] = [];
    //         }
    //         groups[key].push(subCategory);
    //         return groups;
    //       }, {});

    //       Object.values(groupedSubCategories).forEach((subCategories) => {
    //         if (subCategories.length > 1) {
    //           let maxItemLength = 0;
    //           let maxItemIndex = -1;
    //           subCategories.forEach((subCategory, index) => {
    //             const itemLength = subCategory.item.length;
    //             if (itemLength > maxItemLength) {
    //               maxItemLength = itemLength;
    //               maxItemIndex = index;
    //             }
    //           });
    //           const filteredSubCategories = subCategories.filter(
    //             (_, index) => index === maxItemIndex
    //           );
    //           const items = filteredSubCategories[0].item.map((item) => item.value);
    //           accumulator.push({ ...filteredSubCategories[0], item: items });
    //         } else {
    //           const items = subCategories[0].item.map((item) => item.value);
    //           accumulator.push({ ...subCategories[0], item: items });
    //         }
    //       });
    // console.log("groupedSubCategories", groupedSubCategories);
    //       return accumulator;
    //     }, []);
    console.log("features", {
      ...data,
      ecoFriendly: newEcofrd,
      services: hint,
    });
    setIsBusinesiOther(false);
    try {
      setIsLoadingCategory(true);
      const response = await VendorService.updateOtherInfo({
        ...data,
        features: newFeature,
        ecoFriendly: newEcofrd,
        services: updatedData,
        paymentMethod: newPayment,
      });
      viewVendorDetailData("Data Update Successfully!");
      setHint([]);
    } catch (err) {
      setIsLoadingCategory(false);
      console.log("error updating", err);
    }
  };

  const categoryFormik = useFormik({
    initialValues: {
      categoryId: "",
      mainCat: "",
      subCat: "",
      serviceItem: "",
      service: [],
      ecoFriendly: [],
      features: [],
      paymentMethod: ["", ""],
      bookingPrice: "",
      currency: "",
      // serviceItems: [],
    },
    onSubmit: categorySubmit,
  });

  console.log(
    "categoryFormikcategoryFormikcategoryFormik",
    categoryFormik.values
  );

  // const cashCheck =
  //   categoryFormik &&
  //   categoryFormik.values.paymentMethod.length > 0 &&
  //   categoryFormik.values.paymentMethod.map((item) => item === "Cash" && true);

  // const creditCheck =
  //   categoryFormik &&
  //   categoryFormik.values.paymentMethod.length > 0 &&
  //   categoryFormik.values.paymentMethod.map(
  //     (item) => item === "CreaditCard" && true
  //   );

  const BusiPhotoSubmit = async (values) => {
    console.log("🚀 ~ BusiPhotoSubmit ~ values:", values);
    setIsBusiPhoto(false);
    const formData = new FormData();
    try {
      setIsLoadingPhotos(true);
      if (newImage && values.photos.length > 0 && values.photos !== undefined) {
        for (let i = 0; i < values.photos.length; i++) {
          formData.append("photos", values.photos[i]);
        }
        const response = await VendorService.updateVendorImage(
          businessId,
          formData
        );
        console.log("Vendor image updated", response);
      }
      formik.resetForm();
      viewVendorDetailData("Data Update Successfully!");
    } catch (err) {
      setIsLoadingPhotos(false);
      console.log("Error: " + err);
    }
  };

  const busiPhotoFormik = useFormik({
    initialValues: {
      photos: [],
    },
    onSubmit: BusiPhotoSubmit,
  });

  const deleteHandler2 = async (imageUrl) => {
    console.log("businessId", businessId, imageUrl);
    const response = await VendorService.deleteImage({
      bussinessId: businessId,
      imageUrl: imageUrl,
    });
    // setSelectedImage((previousImages) =>
    //   previousImages.filter((image) => image !== imageUrl)
    // );

    busiPhotoFormik.setFieldValue("photos", (previousImages) =>
      previousImages.filter((image) => image !== imageUrl)
    );
    viewVendorDetailData();
  };

  const deleteHandlerMenu2 = async (imageUrl) => {
    console.log("businessId", menuId, imageUrl);
    const response = await VendorService.deleteMenu({
      menuId: menuId,
      imageUrl: imageUrl,
    });
    setSelectedImage((previousImages) =>
      previousImages.filter((image) => image !== imageUrl)
    );

    busiServiceFormik.setFieldValue("photos", (previousImages) =>
      previousImages.filter((image) => image !== imageUrl)
    );
    viewVendorDetailData();
  };

  const deleteHandler = async (imageUrl) => {
    console.log(
      "busiPhotoFormik",
      imageUrl,
      busiPhotoFormik.values.photos.filter(
        (image) => image.name !== imageUrl.split("///")[1]
      )
    );

    setSelectedImage((previousImages) =>
      previousImages.filter((image) => image !== imageUrl)
    );
    const selected = busiPhotoFormik.values.photos.filter(
      (image) => image.name !== imageUrl.split("///")[1]
    );
    busiPhotoFormik.setFieldValue("photos", selected);
  };

  const deleteHandlerMenu = async (imageUrl) => {
    setSelectedImage((previousImages) =>
      previousImages.filter((image) => image !== imageUrl)
    );
    const selected = busiServiceFormik.values.photos.filter(
      (image) => image.name !== imageUrl.split("///")[1]
    );
    busiServiceFormik.setFieldValue("photos", selected);
  };

  const BusiServiceSubmit = async (values) => {
    console.log("busiPhotoFormik", values);
    setIsBusiService(false);
    const formData = new FormData();
    try {
      setIsLoadingMenu(true);
      formData.append("menuId", values.menuId);
      if (
        menuImage &&
        values.photos.length > 0 &&
        values.photos !== undefined
      ) {
        for (let i = 0; i < values.photos.length; i++) {
          formData.append("photos", values.photos[i]);
        }
      }
      // formData.append("photos", values.photos);
      formData.append("title", values.title);
      formData.append("currency", values.currency);
      formData.append("price", values.price);
      formData.append("detail", values.detail);
      const response = await VendorService.updateMenu(formData);
      viewVendorDetailData("Data Update Successfully!");
      console.log("BusiServiceSubmit response", response);
    } catch (error) {
      setIsLoadingMenu(false);
      console.log("BusiServiceSubmit error", error);
    }
  };

  const handleCheckChange = (name, index) => {
    const updatedPaymentMethod = [...categoryFormik.values.paymentMethod];
    updatedPaymentMethod[index] = name;
    categoryFormik.setFieldValue("paymentMethod", updatedPaymentMethod);
  };

  const busiServiceFormik = useFormik({
    initialValues: {
      menuId: "",
      currency: "",
      detail: "",
      photos: [],
      price: "",
      title: "",
    },
    onSubmit: BusiServiceSubmit,
  });

  console.log("queryqueryquery", categoryFormik.values.subCat, itemList);
  // categoryFormik.values.subCat.length > 0 && categoryFormik.values.subCat.map((item, index) => item._id === itemList[0].subcategoryId &&  itemList.item.length > 0 && itemList[0].item )

  const otherDetailSubmit = () => {
    setIsEditOther(false);
    formik.resetForm();
    otherDetailFormik.resetForm();
    navigate("/vendors");
  };

  const otherDetailFormik = useFormik({
    initialValues: {
      bhopen: "",
      bhclose: "",
      maincategory: "",
      bookingprice: "",
      ecofriendly: "",
      features: "",
      services: "",
      subcategory: "",
      title: "",
      serviceimage: "",
      price: "",
      detail: "",
      acceptcash: "",
      acceptcredit: "",
    },
    onSubmit: otherDetailSubmit,
  });

  const handleBack = () => {
    navigate("/vendors");
  };

  const customRowStyles = {
    gap: "10px",
    // padding:"0 20px"
  };
  const columns = [
    {
      name: <b>USER NAME</b>,
      selector: (row) => [row.userId.userName],
      sortable: true,
      cell: (row) => <div>{row.userId.userName}</div>,
    },
    {
      name: <b>TOTAL NUMBER OF PEOPLE</b>,
      selector: (row) => [row.numberOfPeople],
      sortable: true,
      cell: (row) => <div>{row.numberOfPeople}</div>,
    },
    {
      name: <b>PAYMENT AMOUNT</b>,
      selector: (row) => [row.paymentAmount],
      sortable: true,
      cell: (row) => <div>{row.paymentAmount}</div>,
    },
    {
      name: <b>BOOKING TIME</b>,
      selector: (row) => [row.startTime] - [row.endTime],
      sortable: true,
      cell: (row) => (
        <div>
          {moment(row.startTime).format("hh:mm A")} -{" "}
          {moment(row.endTime).format("hh:mm A")}
        </div>
      ),
    },
    {
      name: <b>BOOKING DATE</b>,
      selector: (row) => [row.date],
      sortable: true,
      cell: (row) => <div>{moment(row.date).format("DD/MM/YYYY")}</div>,
      //   <Moment format="DD-MM-YYYY">{row.createdat}</Moment>
    },
    {
      name: <b>STATUS</b>,
      sortable: true,
      cell: (row) => (
        <Button
          variant={
            row.status === "Pending"
              ? "warning"
              : row.status === "Accepted"
              ? "success"
              : "danger"
          }
          size="sm"
          style={{ cursor: "default" }}
        >
          {row.status}
        </Button>
      ),
    },
  ];

  // const formattedTime = (timeString) => {
  //   console.log("timeString:", timeString);
  //   if (!timeString) return null;
  //   const [hours, minutes] = timeString.split(":");
  //   const date = new Date();
  //   date.setHours(parseInt(hours, 10));
  //   date.setMinutes(parseInt(minutes, 10));
  //   const timeInMilliseconds = date.getTime();

  //   return dayjs(`${moment(timeInMilliseconds).format("hh:mm A")}`, "hh:mm A");
  // };

  // const formattedTime = (timeString) => {
  //   if (!timeString) return null;
  //   const [hours, minutes, period] = timeString
  //     .match(/(\d+):(\d+)\s*(AM|PM)/i)
  //     .slice(1);
  //   const hourValue =
  //     period.toUpperCase() === "PM" && hours !== "12"
  //       ? parseInt(hours, 10) + 12
  //       : parseInt(hours, 10);
  //   const formattedTimeString = `${hourValue
  //     .toString()
  //     .padStart(2, "0")}:${minutes} ${period.toUpperCase()}`;

  //   return dayjs(
  //     `${moment(formattedTimeString, "HH:mm A").format("hh:mm A")}`,
  //     "hh:mm A"
  //   );
  // };
  const formattedTime = (timeString) => {
    if (!timeString) return null;
    const match = timeString.match(/(\d+):(\d+)\s*(AM|PM)/i);
    if (!match) return null;
    const [hours, minutes, period] = match.slice(1);
    const hourValue =
      period.toUpperCase() === "PM" && hours !== "12"
        ? parseInt(hours, 10) + 12
        : parseInt(hours, 10);
    const formattedTimeString = `${hourValue
      .toString()
      .padStart(2, "0")}:${minutes} ${period.toUpperCase()}`;

    return dayjs(
      `${moment(formattedTimeString, "HH:mm A").format("hh:mm A")}`,
      "hh:mm A"
    );
  };

  // const formattedTime = (timeString) => {
  //   if (!timeString) return null;
  //   console.log("datedate", timeString);
  //   const date = new Date(parseInt(timeString, 10));
  //   const hours = date.getHours();
  //   const minutes = date.getMinutes();
  //   const formattedTimeString = `${hours.toString().padStart(2, "0")}:${minutes
  //     .toString()
  //     .padStart(2, "0")}`;

  //   return dayjs(formattedTimeString, "HH:mm");
  // };

  // const handleTimeChange = (index, day, newTime, fieldName, slotIndex) => {
  //   const milliseconds = new Date(newTime).getTime();
  //   const time = moment(milliseconds).format("HH:mm");
  //   console.log("🚀  handleTimeChange  time:", time, milliseconds, newTime);

  //   WeekMap.filter(
  //     (res, i) =>
  //       i === index &&
  //       bussinessHourFormik.setFieldValue(
  //         `${res.label}[${slotIndex}].${fieldName}`,
  //         time === "Invalid date" ? "" : moment(time, "HH:mm").format("hh:mm A")
  //       )
  //   );
  // };
  const handleTimeChange = (index, day, newTime, fieldName, slotIndex) => {
    if (!newTime || isNaN(newTime.getTime())) {
      bussinessHourFormik.setFieldValue(
        `${day}[${slotIndex}].${fieldName}`,
        ""
      );
      return;
    }
    const milliseconds = newTime.getTime();
    const time = moment(milliseconds).format("HH:mm");

    WeekMap.filter(
      (res, i) =>
        i === index &&
        bussinessHourFormik.setFieldValue(
          `${res.label}[${slotIndex}].${fieldName}`,
          moment(time, "HH:mm").format("hh:mm A")
        )
    );
  };

  console.log("bussinessHourFormik", bussinessHourFormik.values);

  // const handleTimeChange = (day, newTime, fieldName, slotIndex) => {
  //   console.log("newTime", newTime);
  //   const milliseconds = moment.duration(newTime).asMilliseconds();

  //   const updatedValues = [...bussinessHourFormik.values[day]];
  //   updatedValues[slotIndex] = {
  //     ...updatedValues[slotIndex],
  //     [fieldName]: milliseconds.toString(),
  //   };

  //   console.log("updatedValues", updatedValues);

  //   bussinessHourFormik.setFieldValue(day, updatedValues);
  // };

  // const handleTimeChange = (index, newTime, fieldName) => {
  //   const milliseconds = moment
  //     .duration(newTime?.$H + ":" + newTime?.$m + ":" + newTime?.$s, "hours")
  //     .asMilliseconds();

  //   formik.setFieldValue(`slotList[${index}].${fieldName}`, milliseconds);
  // };

  // const mainCatFormik = categoryFormik?.values?.serviceItem;
  // console.log("mainCatFormikmainCatFormik", mainCatFormik);
  // console.log(
  //   categoryFormik,
  //   categoryFormik &&
  //     categoryFormik.values.paymentMethod.length > 0 &&
  //     categoryFormik.values.paymentMethod.map((item) => item === "Cash" && true)
  // );

  // console.log(
  //   "mainCatFormikQueryvvvv",
  //   itemList[0] &&
  //     itemList[0].item.filter((item) =>
  //       hint[0].some((data) => data.value === item.value)
  //     )
  //   // hint
  // );
  // // .filter((item) =>
  // //   categoryFormik.values.features.some(
  // //     (data) => data.value === item.value
  // //   )
  // // )
  // );
  //   const newList =  categoryFormik.values.subCat.length > 0 && categoryFormik.values.subCat.map(sub => {
  //     const subListData = itemList.find(item => item.subcategoryId === sub._id);
  //     if (subListData) {
  //         const newItem = { ...sub };
  //         newItem.item = subListData.item.map(item => item.value);
  //         return newItem;
  //     }
  //     return null;
  // }).filter(item => item !== null);

  // const newList = itemList.map(listItem => {
  //     const matchingSublist = categoryFormik.values.subCat.length > 0 && categoryFormik.values.subCat.find(sublistItem => sublistItem._id === listItem.subcategoryId);
  //     return {
  //         sublistName: matchingSublist ? matchingSublist.value : "",
  //         items: listItem.item.map(item => item.value)
  //     };
  // });
  // const newList = categoryFormik?.values.subCat && categoryFormik?.values.subCat.map(item => {
  //   const matchingCategory = itemList.find(categoryItem => categoryItem.subcategoryId === item._id);
  //   if (matchingCategory) {
  //     return {
  //           ...item,
  //           name: matchingCategory.item.map(subItem => subItem.value).join(", ")
  //         };
  //   } else {
  //       return item;
  //   }
  // });

  console.log("🚀  hint:", itemList, categoryFormik?.values.subCat);

  const truncateText = (text, maxLength) => {
    if (text && text.length <= maxLength) {
      return text;
    } else {
      return text && text.substr(0, maxLength) + "...";
    }
  };

  return (
    <>
      {loadingData ? (
        <div className="loader-container">
          <div
            style={{ position: "absolute", top: "50%", left: "50%" }}
            className="loader"
          >
            Just A Moment....
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="page-header">
            <div className="me-auto">
              <Breadcrumb>
                <BreadcrumbItem
                  className="mb-2"
                  onClick={() => handleBack()}
                  active
                  style={{
                    cursor: "pointer",
                    fontSize: "20px",
                    color: "#170C6B",
                  }}
                >
                  <IoArrowBack /> Back
                </BreadcrumbItem>
              </Breadcrumb>
              <h2 className="main-content-title tx-24 mg-b-5">
                Vendor Details
              </h2>
            </div>
          </div>
          <Row className="row row-sm">
            <Col xl={12} lg={12} md={12}>
              <Card className="custom-card" style={{ position: "relative" }}>
                {isLoadingDetail && (
                  <div
                    className="loader"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}
                <Card.Body>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="d-flex align-items-start pb-3 border-bottom">
                      <div
                      // style={{ marginTop: "10px" }}
                      >
                        <h4>Profile Detail</h4>
                      </div>
                      {/* <img
                        src={formik.values.profileImage}
                        className="img rounded-circle avatar-xl ms-auto mb-3"
                        alt="user1"
                        onClick={() =>
                          document.getElementById("imageInput").click()
                        }
                        style={{ cursor: "pointer" }}
                      /> */}
                      <input
                        id="imageInput"
                        type="file"
                        accept="image/*"
                        name="profile"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            const reader = new FileReader();
                            reader.onloadend = () => {
                              setSelectedImage(reader.result);
                              // formik.setFieldValue("profile", reader.result);
                            };
                            reader.readAsDataURL(file);
                          }
                        }}
                        disabled={!isEdit}
                      />
                    </div>
                    <div className="py-2">
                      <div className="row py-2">
                        <div className="col-md-6 pb-3">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                // textAlign: "start",
                                color: "#000",
                                // marginTop: "15px",
                              }}
                            >
                              First Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="firstName"
                              placeholder="First Name"
                              value={formik.values.firstName}
                              onChange={formik.handleChange}
                              disabled={!isEdit}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6 pb-3">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                color: "#000",
                              }}
                            >
                              Last Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="lastName"
                              placeholder="Last Name"
                              value={formik.values.lastName}
                              onChange={formik.handleChange}
                              disabled={!isEdit}
                            />
                            {/* {formik.errors.lastName && formik.touched.lastName ? (
                          <p className="text-start error">
                            {" "}
                            {formik.errors.lastName}
                          </p>
                        ) : null} */}
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row py-2">
                        {selectedOption && selectedOption.value === "email" && (
                          <>
                            <div className="col-md-6 pb-3">
                              <Form.Group
                                controlid="validationFormik101"
                                className="position-relative"
                              >
                                <Form.Label
                                  style={{
                                    color: "#000",
                                  }}
                                >
                                  Email Address
                                </Form.Label>
                                <Form.Control
                                  type="email"
                                  name="email"
                                  placeholder="E-mail"
                                  value={formik.values.email}
                                  onChange={formik.handleChange}
                                  disabled={!isEdit}
                                />
                                {/* {formik.errors.email && formik.touched.email ? (
                            <p className="text-start error">
                              {" "}
                              {formik.errors.email}
                            </p>
                          ) : null} */}
                              </Form.Group>
                            </div>
                            <div className="col-md-6 pb-3">
                              <Form.Group
                                controlid="validationFormik101"
                                className="position-relative"
                              >
                                <Form.Label
                                  style={{
                                    color: "#000",
                                  }}
                                >
                                  Password
                                </Form.Label>
                                <Form.Control
                                  type={`${showPassword ? "text" : "password"}`}
                                  name="password"
                                  placeholder="password"
                                  readOnly={!isEdit}
                                  value={passUpdate}
                                  onChange={(e) => handlePasswordChange(e)}
                                  onClick={handlePasswordFieldClick}
                                />
                                {isPassChange && (
                                  <i
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                    className={`fa ${
                                      showPassword ? "fa-eye-slash" : "fa-eye"
                                    } position-absolute text-dark`}
                                    style={{ top: 38, right: 15 }}
                                  />
                                )}
                              </Form.Group>
                            </div>
                          </>
                        )}
                        <div className="col-md-6 pb-3">
                          {selectedOption &&
                            selectedOption.value === "mobileNumber" && (
                              <Form.Group
                                controlid="validationFormik101"
                                className="position-relative"
                              >
                                <Form.Label
                                  style={{
                                    color: "#000",
                                  }}
                                >
                                  Phone Number
                                </Form.Label>
                                {/* <Form.Control
                                  type="number"
                                  name="mobileNumber"
                                  placeholder="Phone Number"
                                  value={formik.values.mobileNumber}
                                  onChange={formik.handleChange}
                                  disabled={!isEdit}
                                /> */}
                                <PhoneInput
                                  className="addContacts"
                                  // country={"in"}
                                  value={
                                    formik.values.countryCode +
                                    formik.values.mobileNumber
                                  }
                                  onChange={(value, country) => {
                                    formik.setFieldValue(
                                      "mobileNumber",
                                      value.split(country.dialCode)[1]
                                    );
                                    formik.setFieldValue(
                                      "countryCode",
                                      country.dialCode
                                    );
                                  }}
                                  disabled={!isEdit}
                                />
                                {/* {formik.errors.mobileNumber &&
                                formik.touched.mobileNumber ? (
                                  <p className="text-start error">
                                    {" "}
                                    {formik.errors.mobileNumber}
                                  </p>
                                ) : null} */}
                              </Form.Group>
                            )}
                        </div>
                      </div>
                      <div className="row py-2">
                        {/* <div
                      className="col-md-12 pb-3"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "end",
                      }}
                    >
                      {!isEdit ? (
                        <Button type="submit" disabled="!isEdit">
                          submit
                        </Button>
                      ) : (
                        <Button type="submit">submit</Button>
                      )}
                    </div> */}
                        <div
                          className="col-md-12 pb-3"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "end",
                          }}
                        >
                          {isEdit ? (
                            <div>
                              <Button
                                className="ms-auto"
                                onClick={() => {
                                  handleToggleEditMode(true);
                                }}
                              >
                                <i className="fa fa-close"></i>
                              </Button>
                              <Button
                                className="ms-2"
                                onClick={formik.submitForm}
                              >
                                Update
                              </Button>
                            </div>
                          ) : (
                            <Button
                              className="ms-auto"
                              onClick={() => {
                                handleToggleEditMode(false);
                              }}
                            >
                              <i className="fa fa-pencil"></i>
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* <div
        className="col-md-12 pb-3 mb-2"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "end",
        }}
      >
        {isEditOther ? (
          <div>
            <Button className="ms-auto" onClick={handleEditOtherDetails}>
              <i className="fa fa-close"></i>
            </Button>
            <Button className="ms-2" onClick={formik.submitForm}>
              Update
            </Button>
          </div>
        ) : (
          <Button
            className="ms-auto"
            onClick={() => {
              handleEditOtherDetails();
            }}
          >
            <i className="fa fa-pencil"></i>
          </Button>
        )}
      </div> */}
          {notFound ? (
            <>
              <Row className="row row-sm">
                <Col xl={12} lg={12} md={12}>
                  <Card
                    className="custom-card"
                    style={{ position: "relative" }}
                  >
                    {isLoadingKeyword && (
                      <div
                        className="loader"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          zIndex: "99999999",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    )}
                    <Card.Body>
                      <form>
                        <div className="d-flex justify-content-between align-items-start pb-3 border-bottom">
                          <div>
                            <h4>Business Keywords</h4>
                          </div>
                          <div className="mb-2">
                            {isBusinessKeyword ? (
                              <div>
                                <Button
                                  className="ms-auto"
                                  onClick={() => {
                                    handleBusinessKeyword(true);
                                  }}
                                >
                                  <i className="fa fa-close"></i>
                                </Button>
                                <Button
                                  className="ms-2"
                                  onClick={keywordFormik.submitForm}
                                >
                                  Add
                                </Button>
                              </div>
                            ) : (
                              <Button
                                className="ms-auto"
                                onClick={() => {
                                  handleBusinessKeyword(false);
                                }}
                              >
                                <i className="fa fa-pencil"></i>
                              </Button>
                            )}
                          </div>
                        </div>
                        <div className="py-2">
                          <div className="row py-2">
                            <div className="col-md-12 pb-3">
                              {!isBusinessKeyword ? (
                                inputValue.length > 0 ? (
                                  inputValue.map((feature, index) => (
                                    <Badge
                                      className="p-2"
                                      key={index}
                                      pill
                                      bg="primary"
                                      style={{
                                        marginRight: "5px",
                                        fontSize: "12px",
                                        wordBreak: "break-all",
                                        marginTop: "5px",
                                        backgroundColor: "#FC9D4A",
                                        color: "white",
                                      }}
                                    >
                                      {feature}
                                    </Badge>
                                  ))
                                ) : (
                                  <div>Business Keywords Not Found</div>
                                )
                              ) : (
                                <>
                                  <Form.Group className="position-relative">
                                    <Form.Label
                                      style={{
                                        color: "#000",
                                      }}
                                    >
                                      Bussiness Keywords
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="keywords"
                                      placeholder="Bussiness Keywords"
                                      disabled={!isBusinessKeyword}
                                      value={keywords}
                                      onChange={handleInputChange}
                                      onKeyDown={handleInputKeyDown}
                                    />
                                  </Form.Group>
                                  <div>
                                    {inputValue &&
                                      inputValue.length > 0 &&
                                      inputValue.map((keyword, index) => (
                                        <Badge
                                          className="p-2"
                                          key={index}
                                          pill
                                          variant="primary"
                                          style={{
                                            marginRight: "5px",
                                            marginTop: "9px",
                                            marginBottom: "5px",
                                            cursor: "pointer",
                                            fontSize: "12px",
                                          }}
                                          onClick={() =>
                                            handleRemoveKeyword(index)
                                          }
                                        >
                                          {keyword} &#10005;
                                        </Badge>
                                      ))}
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="col-md-6 pb-3"></div>
                          </div>
                        </div>
                      </form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="row row-sm">
                <Col xl={12} lg={12} md={12}>
                  <Card
                    className="custom-card"
                    style={{ position: "relative" }}
                  >
                    {isLoadingBusiness && (
                      <div
                        className="loader"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          zIndex: "99999999",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    )}
                    <Card.Body>
                      <form>
                        <div className="d-flex justify-content-between align-items-start pb-3 border-bottom">
                          <div>
                            <h4>Business Details</h4>
                          </div>
                          <div className="mb-2">
                            {isBusinessDetail ? (
                              <div>
                                <Button
                                  className="ms-auto"
                                  onClick={() => {
                                    handleBusinessDetail(true);
                                  }}
                                >
                                  <i className="fa fa-close"></i>
                                </Button>
                                <Button
                                  className="ms-2"
                                  onClick={bussinessDetailFormik.submitForm}
                                >
                                  Update
                                </Button>
                              </div>
                            ) : (
                              <Button
                                className="ms-auto"
                                onClick={() => {
                                  handleBusinessDetail(false);
                                }}
                              >
                                <i className="fa fa-pencil"></i>
                              </Button>
                            )}
                          </div>
                        </div>
                        <div className="py-2">
                          <div className="row py-2">
                            <div className="col-md-6 pb-3">
                              <Form.Group
                                controlid="validationFormik101"
                                className="position-relative"
                              >
                                <Form.Label
                                  style={{
                                    color: "#000",
                                  }}
                                >
                                  Bussiness Name
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="bussinessName"
                                  placeholder="Bussiness Name"
                                  value={
                                    bussinessDetailFormik.values.bussinessName
                                  }
                                  onChange={bussinessDetailFormik.handleChange}
                                  disabled={!isBusinessDetail}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-md-6 pb-3">
                              <Form.Group
                                controlid="validationFormik101"
                                className="position-relative"
                              >
                                <Form.Label
                                  style={{
                                    color: "#000",
                                  }}
                                >
                                  Website
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="website"
                                  placeholder="Enter website link"
                                  value={bussinessDetailFormik.values.website}
                                  onChange={bussinessDetailFormik.handleChange}
                                  disabled={!isBusinessDetail}
                                />
                                {/* {formik.errors.website && formik.touched.website ? (
                          <p className="text-start error">
                            {" "}
                            {formik.errors.website}
                          </p>
                        ) : null} */}
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row py-2">
                            <div className="col-md-6 pb-3">
                              <Form.Group
                                controlid="validationFormik101"
                                className="position-relative"
                              >
                                <Form.Label
                                  style={{
                                    color: "#000",
                                  }}
                                >
                                  Business Phone Number
                                </Form.Label>
                                {/* <Form.Control
                              type="number"
                              name="mobileNumber"
                              placeholder="Phone Number"
                              value={bussinessDetailFormik.values.mobileNumber}
                              onChange={bussinessDetailFormik.handleChange}
                              disabled={!isBusinessDetail}
                            /> */}
                                <PhoneInput
                                  className="addContacts"
                                  country={"in"}
                                  // value={bussinessDetailFormik.values.mobileNumber}
                                  // onChange={(value) =>
                                  //   bussinessDetailFormik.setFieldValue("mobileNumber", value.split(codeCountry)[1])
                                  // }
                                  value={
                                    bussinessDetailFormik.values.countryCode +
                                    bussinessDetailFormik.values.mobileNumber
                                  }
                                  onChange={(value, country) => {
                                    bussinessDetailFormik.setFieldValue(
                                      "mobileNumber",
                                      value.split(country.dialCode)[1]
                                    );
                                    bussinessDetailFormik.setFieldValue(
                                      "countryCode",
                                      country.dialCode
                                    );
                                  }}
                                  disabled={!isBusinessDetail}
                                />
                                {/* {bussinessDetailFormik.errors.mobileNumber &&
                            bussinessDetailFormik.touched.mobileNumber ? (
                              <p className="text-start error">
                                {" "}
                                {bussinessDetailFormik.errors.mobileNumber}
                              </p>
                            ) : null} */}
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="row row-sm">
                <Col xl={12} lg={12} md={12}>
                  <Card
                    className="custom-card"
                    style={{ position: "relative" }}
                  >
                    {isLoadingAddress && (
                      <div
                        className="loader"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          zIndex: "99999",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    )}
                    <Card.Body>
                      <form>
                        <div className="d-flex justify-content-between align-items-start pb-3 border-bottom">
                          <div>
                            <h4>Business Address</h4>
                          </div>
                          <div className="mb-2">
                            {isBusinessAddress ? (
                              <div>
                                <Button
                                  className="ms-auto"
                                  onClick={() => {
                                    handleBusinessAddress(true);
                                  }}
                                >
                                  <i className="fa fa-close"></i>
                                </Button>
                                <Button
                                  className="ms-2"
                                  onClick={bussinessAddressFormik.submitForm}
                                >
                                  Update
                                </Button>
                              </div>
                            ) : (
                              <Button
                                className="ms-auto"
                                onClick={() => {
                                  handleBusinessAddress(false);
                                }}
                              >
                                <i className="fa fa-pencil"></i>
                              </Button>
                            )}
                          </div>
                        </div>
                        <div className="py-2">
                          <div className="row py-2">
                            <div className="col-md-6 pb-3">
                              <Form.Group
                                controlid="validationFormik101"
                                className="position-relative"
                              >
                                <Form.Label
                                  style={{
                                    // textAlign: "start",
                                    color: "#000",
                                    // marginTop: "15px",
                                  }}
                                >
                                  Address
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="address"
                                  disabled={!isBusinessAddress}
                                  placeholder="Address"
                                  value={bussinessAddressFormik.values.address}
                                  onChange={bussinessAddressFormik.handleChange}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-md-6 pb-3">
                              <Form.Group
                                controlid="validationFormik101"
                                className="position-relative"
                              >
                                <Form.Label
                                  style={{
                                    color: "#000",
                                  }}
                                >
                                  Apt/Suite/Other
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="apartment"
                                  disabled={!isBusinessAddress}
                                  placeholder="Apt/Suite/Other"
                                  value={
                                    bussinessAddressFormik.values.apartment
                                  }
                                  onChange={bussinessAddressFormik.handleChange}
                                />
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row py-2">
                            <div className="col-md-6 pb-3">
                              <Form.Group
                                controlid="validationFormik101"
                                className="position-relative"
                              >
                                <Form.Label
                                  style={{
                                    color: "#000",
                                  }}
                                >
                                  Country
                                </Form.Label>
                                <CountryDropdown
                                  name="country"
                                  value={bussinessAddressFormik.values.country}
                                  onChange={handleCountryChange}
                                  disabled={!isBusinessAddress}
                                  classes="form-control"
                                />
                              </Form.Group>
                            </div>
                            <div className="col-md-6 pb-3">
                              <Form.Group
                                controlid="validationFormik101"
                                className="position-relative"
                              >
                                <Form.Label
                                  style={{
                                    color: "#000",
                                  }}
                                >
                                  Zip Code
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="zip"
                                  disabled={!isBusinessAddress}
                                  placeholder="Zip Code"
                                  value={bussinessAddressFormik.values.zip}
                                  onChange={bussinessAddressFormik.handleChange}
                                />
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row py-2">
                            <div className="col-md-6 pb-3">
                              <Form.Group
                                controlid="validationFormik101"
                                className="position-relative"
                              >
                                <Form.Label
                                  style={{
                                    color: "#000",
                                  }}
                                >
                                  State
                                </Form.Label>
                                <RegionDropdown
                                  name="state"
                                  value={bussinessAddressFormik.values.state}
                                  // value={region}
                                  onChange={handleRegionChange}
                                  classes="form-control"
                                  disableWhenEmpty
                                  country={country}
                                  disabled={!isBusinessAddress}
                                  defaultOptionLabel="Select Region"
                                  options={regionsForSelectedCountry}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-md-6 pb-3">
                              <Form.Group
                                controlid="validationFormik101"
                                className="position-relative"
                              >
                                <Form.Label
                                  style={{
                                    color: "#000",
                                  }}
                                >
                                  City
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="city"
                                  disabled={!isBusinessAddress}
                                  placeholder="City"
                                  value={bussinessAddressFormik.values.city}
                                  onChange={bussinessAddressFormik.handleChange}
                                />
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row py-2">
                            <div className="col-md-6 pb-3">
                              <div className="mb-2">
                                <Button
                                  variant="primary"
                                  type="button"
                                  className="btn btn-primary btn-icon-text ms-auto"
                                  // className="btn btn-primary btn-sm"
                                  onClick={() => handleClickOpen("")}
                                >
                                  <i className="fa fa-map-marker me-2"></i> Map
                                </Button>

                                <MapModal
                                  show={open}
                                  onHide={handleClickClose}
                                  loctionCoordinates={loctionCoordinates}
                                  setLocationCoordinates={
                                    setLocationCoordinates
                                  }
                                  isBusinessAddress={!isBusinessAddress}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="row row-sm">
                <Col xl={4} lg={12} md={12}>
                  <Card
                    className="custom-card"
                    style={{ position: "relative" }}
                  >
                    {isLoadingHours && (
                      <div
                        className="loader"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          zIndex: "99999",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    )}
                    <Card.Body>
                      <form>
                        <div className="d-flex justify-content-between align-items-start pb-3 border-bottom">
                          <div>
                            <h4>Business Hours</h4>
                          </div>
                          <div className="mb-2">
                            {isBusinesiHour ? (
                              <div>
                                <Button
                                  className="ms-auto"
                                  onClick={() => {
                                    handleBusinesiHour(true);
                                  }}
                                >
                                  <i className="fa fa-close"></i>
                                </Button>
                                <Button
                                  className="ms-2"
                                  onClick={bussinessHourFormik.submitForm}
                                >
                                  Update
                                </Button>
                              </div>
                            ) : (
                              <Button
                                className="ms-auto"
                                onClick={() => {
                                  handleBusinesiHour(false);
                                }}
                              >
                                <i className="fa fa-pencil"></i>
                              </Button>
                            )}
                          </div>
                        </div>
                        {finalData != "" && (
                          <>
                            {Object.entries(bussinessHourFormik.values).length >
                            0 ? (
                              Object.entries(bussinessHourFormik.values).map(
                                ([day, dayData], index) => {
                                  console.log("daydaya", dayData);
                                  const dayOfWeek = WeekMap.find(
                                    (dayMap) => dayMap.label === day
                                  );
                                  return (
                                    <div className="row py-2" key={index}>
                                      <div
                                        className="col-md-12"
                                        style={{
                                          fontSize: "15px",
                                          fontWeight: "bolder",
                                        }}
                                      >
                                        {WeekMap.map(
                                          (res, i) => i === index && res.name
                                        )}
                                      </div>
                                      {dayData.map((timeSlot, slotIndex) =>
                                        timeSlot.opensAt === "" &&
                                        timeSlot.closesAt === "" ? (
                                          <>
                                            <div className="m-0">
                                              <span
                                                className="col-md-6 px-2 d-flex m-0"
                                                style={{
                                                  fontSize: "15px",
                                                  color: "red",
                                                }}
                                              >
                                                Closed
                                              </span>
                                            </div>
                                          </>
                                        ) : (
                                          <div
                                            className="row m-0"
                                            key={slotIndex}
                                          >
                                            <div className="row py-2 m-0 p-0 d-flex">
                                              <div className="col-md-6 px-2">
                                                <Form.Group className="position-relative">
                                                  <Form.Label
                                                    style={{
                                                      color: "#000",
                                                    }}
                                                  >
                                                    Open at
                                                  </Form.Label>
                                                  <Space wrap>
                                                    <TimePicker
                                                      format={"hh:mm A"}
                                                      defaultValue={formattedTime(
                                                        timeSlot.opensAt
                                                      )}
                                                      onChange={(newDate) =>
                                                        handleTimeChange(
                                                          index,
                                                          day,
                                                          newDate,
                                                          "opensAt",
                                                          slotIndex
                                                        )
                                                      }
                                                      disabled={!isBusinesiHour}
                                                    />
                                                  </Space>
                                                  {bussinessHourFormik.errors
                                                    .opensAt &&
                                                  bussinessHourFormik.touched
                                                    .opensAt ? (
                                                    <p className="text-start error">
                                                      {" "}
                                                      {
                                                        bussinessHourFormik
                                                          .errors.opensAt
                                                      }
                                                    </p>
                                                  ) : null}
                                                </Form.Group>
                                              </div>
                                              <div className="col-md-6 px-2">
                                                <Form.Group className="position-relative">
                                                  <Form.Label
                                                    style={{
                                                      color: "#000",
                                                    }}
                                                  >
                                                    Close at
                                                  </Form.Label>
                                                  <Space wrap>
                                                    <TimePicker
                                                      format="hh:mm A"
                                                      onChange={(newDate) =>
                                                        handleTimeChange(
                                                          index,
                                                          day,
                                                          newDate,
                                                          "closesAt",
                                                          slotIndex
                                                        )
                                                      }
                                                      defaultValue={formattedTime(
                                                        timeSlot.closesAt
                                                      )}
                                                      disabled={!isBusinesiHour}
                                                    />
                                                  </Space>
                                                  {bussinessHourFormik.errors
                                                    .closesAt &&
                                                  bussinessHourFormik.touched
                                                    .closesAt ? (
                                                    <p className="text-start error">
                                                      {" "}
                                                      {
                                                        bussinessHourFormik
                                                          .errors.closesAt
                                                      }
                                                    </p>
                                                  ) : null}
                                                </Form.Group>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  );
                                }
                              )
                            ) : (
                              <div>Business Hour Not Found</div>
                            )}
                          </>
                        )}
                      </form>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xl={8} lg={12} md={12}>
                  <Card
                    className="custom-card"
                    style={{ position: "relative" }}
                  >
                    {isLoadingCategory && (
                      <div
                        className="loader"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          zIndex: "99999",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    )}
                    <Card.Body>
                      <form>
                        <div className="d-flex justify-content-between align-items-start pb-3 border-bottom">
                          <div>
                            <h4>Business Category & Services</h4>
                          </div>
                          <div className="mb-2">
                            {isBusinesiOther ? (
                              <div>
                                <Button
                                  className="ms-auto"
                                  onClick={() => {
                                    handleBusinesiOther(true);
                                  }}
                                >
                                  <i className="fa fa-close"></i>
                                </Button>
                                <Button
                                  className="ms-2"
                                  onClick={categoryFormik.submitForm}
                                >
                                  Update
                                </Button>
                              </div>
                            ) : (
                              <Button
                                className="ms-auto"
                                onClick={() => {
                                  handleBusinesiOther(false);
                                }}
                              >
                                <i className="fa fa-pencil"></i>
                              </Button>
                            )}
                          </div>
                        </div>
                        <div>
                          {categoryFormik?.values.mainCat.length > 0 ? (
                            categoryFormik?.values.mainCat &&
                            categoryFormik?.values.mainCat.length > 0 &&
                            categoryFormik?.values.mainCat.map(
                              (category, index) => {
                                return (
                                  <div
                                    class="card-body border my-2 p-3 rounded-3"
                                    key={index}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "baseline",
                                        gap: "10px",
                                      }}
                                    >
                                      <h3 class="main-content-label tx-dark tx-medium mg-b-10">
                                        {category.name}
                                      </h3>
                                      <i className="fas fa-angle-right"></i>
                                      <p>
                                        {
                                          categoryFormik?.values.subCat[index]
                                            ?.name
                                        }
                                      </p>
                                    </div>
                                    {!isBusinesiOther ? (
                                      <div className="row gap-2 m-0 pl-2">
                                        {categoryFormik?.values.serviceItem &&
                                          categoryFormik?.values.serviceItem
                                            .length > 0 &&
                                          categoryFormik?.values.serviceItem[
                                            index
                                          ].map((sub) => (
                                            <div
                                              className="border rounded-pill px-3 py-1 d-flex flex-wrap"
                                              style={{ width: "fit-content" }}
                                            >
                                              {sub.name}
                                            </div>
                                          ))}
                                      </div>
                                    ) : (
                                      <>
                                        <Select
                                          value={
                                            hint[index]?.subCategoryId ===
                                              categoryFormik?.values.subCat[
                                                index
                                              ]._id &&
                                            hint[index].item.length > 0 &&
                                            hint[index].item
                                          }
                                          onChange={(selectedOptions) =>
                                            handleItemChange(
                                              selectedOptions,
                                              index
                                            )
                                          }
                                          isMulti
                                          options={
                                            itemList[index]?.subcategoryId ===
                                              categoryFormik?.values.subCat[
                                                index
                                              ]._id &&
                                            itemList[index].item.length > 0 &&
                                            itemList[index].item
                                          }
                                          //   options={
                                          //     categoryFormik.values.subCat.length > 0 &&categoryFormik.values.subCat.map((item) => item._id === itemList[index]?.subcategoryId &&  itemList[index].item.length > 0 && itemList[index].item )
                                          // }
                                          // options={categoryFormik.values.subCat.length > 0 && categoryFormik.values.subCat.map((item) => item._id === itemList[0].subcategoryId &&  itemList.item.length > 0 && itemList[0].item )}
                                          // options={newList}
                                          isSearchable
                                          placeholder="Select Subcategory Item"
                                          name="features"
                                        />
                                      </>
                                    )}
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <div
                              style={{
                                paddingTop: "16px",
                                paddingBottom: "16px",
                              }}
                            >
                              Business Category Not Found
                            </div>
                          )}
                        </div>
                        <div className="d-flex align-items-start pb-3 border-bottom">
                          <div>
                            <div>
                              <h4>Business Features</h4>
                            </div>
                          </div>
                        </div>
                        <div className="py-2">
                          <div className="row py-2">
                            <div className="col-md-6">
                              <Form.Group className="position-relative">
                                {!isBusinesiOther ? (
                                  <div>
                                    {/* <div className="col-md-6"> */}
                                    {/* <label
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  Business Features:
                                </label>{" "} */}
                                    <span>
                                      {categoryFormik.values.features.length >
                                      0 ? (
                                        categoryFormik.values.features.map(
                                          (feature, index) => (
                                            <Badge
                                              className="p-2"
                                              key={index}
                                              pill
                                              bg="primary"
                                              style={{
                                                marginLeft: "15px",
                                                fontSize: "12px",
                                                wordBreak: "break-all",
                                                marginTop: "5px",
                                                backgroundColor: "#FC9D4A",
                                                color: "white",
                                              }}
                                            >
                                              {feature.label}
                                            </Badge>
                                          )
                                        )
                                      ) : (
                                        <div>Business Feature Not Found</div>
                                      )}
                                    </span>
                                    {/* </div> */}
                                  </div>
                                ) : (
                                  <>
                                    <Form.Label
                                      style={{
                                        color: "#000",
                                      }}
                                    >
                                      Business Features
                                    </Form.Label>
                                    <Select
                                      // value={featureOption.find((item)=> categoryFormik.values.features.map((data)=>data.value === item.value))}
                                      value={featureOption.filter((item) =>
                                        categoryFormik.values?.features?.some(
                                          (data) => data.value === item.value
                                        )
                                      )}
                                      onChange={(selectedOption) =>
                                        handleFeatures(selectedOption)
                                      }
                                      isMulti
                                      options={featureOption}
                                      isSearchable
                                      placeholder="Select Business Features"
                                      name="features"
                                    />
                                  </>
                                )}
                              </Form.Group>
                              {/* {!isBusinesiOther ? (
                            ""
                          ) : (
                            <div className="row py-2">
                              <div className="col-md-6">
                                <label
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  Business Features:
                                </label>{" "}
                                <span>
                                  {features
                                    ?.map((res) => res?.label)
                                    .join(", ")}
                                </span>
                              </div>
                            </div>
                          )} */}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-start pb-3 border-bottom">
                          <div>
                            <div>
                              <h4>Business Eco-friendly</h4>
                            </div>
                          </div>
                        </div>
                        <div className="py-2">
                          <div className="row py-2">
                            <div className="col-md-6">
                              <Form.Group className="position-relative">
                                {!isBusinesiOther ? (
                                  <div>
                                    {/* <label
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  Business Eco-friendly:
                                </label>{" "} */}
                                    <span>
                                      {categoryFormik.values.ecoFriendly
                                        .length > 0 ? (
                                        categoryFormik.values.ecoFriendly.map(
                                          (ecofriendly, index) => (
                                            <Badge
                                              className="p-2"
                                              key={index}
                                              pill
                                              bg="primary" // You can change the background color here
                                              style={{
                                                marginLeft: "15px",
                                                fontSize: "12px",
                                                wordBreak: "break-all",
                                                marginTop: "5px",
                                                backgroundColor: "#FC9D4A",
                                                color: "white",
                                              }}
                                            >
                                              {ecofriendly.label}
                                            </Badge>
                                          )
                                        )
                                      ) : (
                                        <div>
                                          Business Eco-friendly Not Found
                                        </div>
                                      )}
                                    </span>
                                  </div>
                                ) : (
                                  <>
                                    <Form.Label
                                      style={{
                                        color: "#000",
                                      }}
                                    >
                                      Business Eco-friendly
                                    </Form.Label>
                                    <Select
                                      value={ecoOption.filter((item) =>
                                        categoryFormik.values.ecoFriendly?.some(
                                          (data) => data.value === item.value
                                        )
                                      )}
                                      onChange={(selectedOption) =>
                                        handleEcoFrendly(selectedOption)
                                      }
                                      isMulti
                                      options={ecoOption}
                                      isSearchable
                                      placeholder="Select Eco-friendly"
                                      name="ecofriendly"
                                    />
                                  </>
                                )}
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-start pb-3 border-bottom">
                          <div>
                            <h4>Payments & Booking Price</h4>
                          </div>
                        </div>
                        <div className="py-2">
                          {/* {categoryFormik.values.paymentMethod &&
                        categoryFormik.values.paymentMethod.map(
                          (cash, index) => ( */}
                          <div className="row py-2">
                            <div className="col-md-6">
                              {categoryFormik.values.paymentMethod &&
                                categoryFormik.values.paymentMethod?.map(
                                  (cash, index) => (
                                    <Form.Group className="m-0">
                                      <div className="custom-controls-stacked">
                                        {index === 0 && (
                                          <label className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              checked={cash === "Cash"}
                                              className="custom-control-input"
                                              name="CreaditCard"
                                              value={
                                                categoryFormik.values
                                                  .paymentMethod
                                              }
                                              onChange={() =>
                                                handleCheckChange(
                                                  cash === "Cash" ? "" : "Cash",
                                                  0
                                                )
                                              }
                                              disabled={!isBusinesiOther}
                                            />
                                            <span className="custom-control-label">
                                              Are you accepting Cash?
                                            </span>
                                          </label>
                                        )}
                                        {index === 1 && (
                                          <label className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              checked={cash === "CreaditCard"}
                                              className="custom-control-input"
                                              name="Cash"
                                              value={
                                                categoryFormik.values
                                                  .paymentMethod
                                              }
                                              onChange={() =>
                                                handleCheckChange(
                                                  cash === "CreaditCard"
                                                    ? ""
                                                    : "CreaditCard",
                                                  1
                                                )
                                              }
                                              disabled={!isBusinesiOther}
                                            />
                                            <span className="custom-control-label">
                                              Are you accepting Credit Cards?
                                            </span>
                                          </label>
                                        )}
                                      </div>
                                    </Form.Group>
                                  )
                                )}
                            </div>
                            <div className="col-md-6 pb-3">
                              <Form.Group
                                controlid="validationFormik101"
                                className="position-relative"
                              >
                                <Form.Label
                                  style={{
                                    // textAlign: "start",
                                    color: "#000",
                                    // marginTop: "15px",
                                  }}
                                >
                                  Price
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="bookingPrice"
                                  placeholder="Enter Price"
                                  value={categoryFormik.values.bookingPrice}
                                  onChange={categoryFormik.handleChange}
                                  disabled={!isBusinesiOther}
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              {/* <Row className="row row-sm">
            <Col xl={12} lg={12} md={12}>
              <Card className="custom-card">
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-start pb-3 border-bottom">
                    <div>
                      <h4>Business Photos</h4>
                    </div>
                    <div className="mb-2">
                      {isBusiPhoto ? (
                        <div>
                          <Button className="ms-auto" onClick={handleBusiPhoto}>
                            <i className="fa fa-close"></i>
                          </Button>
                          <Button
                            className="ms-2"
                            onClick={busiPhotoFormik.submitForm}
                          >
                            Update
                          </Button>
                        </div>
                      ) : (
                        <Button
                          className="ms-auto"
                          onClick={() => {
                            handleBusiPhoto();
                          }}
                        >
                          <i className="fa fa-pencil"></i>
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="py-2">
                    {!isBusiPhoto ? (
                      <div>
                        {busiPhotoFormik.values.photos &&
                          busiPhotoFormik.values.photos.length > 0 && (
                            <div>
                              {busiPhotoFormik.values.photos.map(
                                (photo, index) => (
                                  <img
                                    key={index}
                                    src={photo}
                                    alt={`photo-${index}`}
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      marginRight: "10px",
                                    }}
                                  />
                                )
                              )}
                            </div>
                          )}
                      </div>
                    ) : (
                      <Upload
                        customRequest={customRequest}
                        fileList={fileList}
                        onChange={handleImageChange}
                        listType="picture-card"
                        maxCount={5}
                        disabled={!isBusiPhoto}
                        name="photos"
                      >
                        {fileList.length < 5 && (
                          <div>
                            <UploadOutlined />
                            <div style={{ marginTop: 8 }}>Upload</div>
                          </div>
                        )}
                      </Upload>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row> */}{" "}
              <Row className="row row-sm">
                <Col xl={12} lg={12} md={12}>
                  <Card
                    className="custom-card"
                    style={{ position: "relative" }}
                  >
                    {isLoadingPhotos && (
                      <div
                        className="loader"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          zIndex: "99999",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    )}
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-start pb-3 border-bottom">
                        <div>
                          <h4>Business Photos</h4>
                        </div>
                        <div className="mb-2">
                          {isBusiPhoto ? (
                            <div>
                              <Button
                                className="ms-auto"
                                onClick={() => {
                                  handleBusiPhoto(true);
                                  setSelectedImage("");
                                }}
                              >
                                <i className="fa fa-close"></i>
                              </Button>
                              <Button
                                className="ms-2"
                                onClick={() => {
                                  busiPhotoFormik.submitForm();
                                  setSelectedImage("");
                                }}
                              >
                                Update
                              </Button>
                            </div>
                          ) : (
                            <Button
                              className="ms-auto"
                              onClick={() => {
                                handleBusiPhoto(false);
                              }}
                            >
                              <i className="fa fa-pencil"></i>
                            </Button>
                          )}
                        </div>
                      </div>
                      <div className="py-2">
                        {!isBusiPhoto ? (
                          <div>
                            {photosOnly && photosOnly.length > 0 ? (
                              photosOnly &&
                              photosOnly.length > 0 && (
                                <div>
                                  {photosOnly.map((photo, index) => (
                                    <img
                                      key={index}
                                      src={photo}
                                      alt="image"
                                      // alt={`photo-${index}`}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        marginRight: "10px",
                                        marginBottom: "10px",
                                        objectFit: "cover",
                                      }}
                                    />
                                  ))}
                                </div>
                              )
                            ) : (
                              <div>Business Photos Not Found</div>
                            )}
                          </div>
                        ) : (
                          <>
                            <FormGroup className="form-group">
                              <input
                                type="file"
                                accept="image/*"
                                multiple
                                name="photos"
                                id="files"
                                onChange={(e) => showFileToUpload(e)}
                                className="form-control"
                              />
                              {/* {formik.errors.files && formik.touched.files ? (
                            <p style={error}>{formik.errors.files}</p>
                          ) : null} */}
                            </FormGroup>
                            <div
                              className="d-flex"
                              style={{ flexWrap: "wrap" }}
                            >
                              {selectedImage.length > 0 &&
                                selectedImage.map((image) => {
                                  return (
                                    <div
                                      key={image}
                                      className="image d-flex text-start m-2 pointer"
                                    >
                                      <Button
                                        onClick={() => deleteHandler(image)}
                                        className="position-absolute z-index-10"
                                      >
                                        <i className="fa fa-times"></i>
                                      </Button>
                                      <img
                                        src={image.split("///")[0]}
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          marginRight: "10px",
                                        }}
                                        alt="upload"
                                      />
                                    </div>
                                  );
                                })}
                              {photosOnly &&
                                photosOnly.map((imageUrl, index) => (
                                  <div
                                    key={index}
                                    style={{ position: "relative" }}
                                    // className="me-2"
                                    className="image d-flex text-start m-2 pointer "
                                  >
                                    <img
                                      src={imageUrl}
                                      alt={""}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        marginRight: "10px",
                                      }}
                                    />
                                    <Button
                                      onClick={() => deleteHandler2(imageUrl)}
                                      style={{
                                        position: "absolute",
                                        left: 0,
                                      }}
                                    >
                                      <i className="fas fa-trash"></i>
                                    </Button>
                                  </div>
                                ))}
                            </div>
                          </>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="row row-sm">
                <Col xl={12} lg={12} md={12}>
                  <Card
                    className="custom-card"
                    style={{ position: "relative" }}
                  >
                    {isLoadingMenu && (
                      <div
                        className="loader"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          zIndex: "99999",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    )}
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-start pb-3 border-bottom">
                        <div>
                          {/* {categoryMenu.map((category, index) => (
                        <div key={index}>
                          <h4>
                            {category.categoryId.catalogLabel === "Menu"
                              ? "Business Menu"
                              : "Business Services"}
                          </h4>
                        </div>
                      ))} */}
                          <h4>
                            {MenuItem.length > 0
                              ? "Business Menu"
                              : "Business Services"}
                          </h4>
                        </div>
                        <div className="mb-2">
                          {isBusiService ? (
                            <div>
                              <Button
                                className="ms-auto"
                                onClick={() => {
                                  handleBusiService(true);
                                }}
                              >
                                <i className="fa fa-close"></i>
                              </Button>
                              <Button
                                className="ms-2"
                                onClick={busiServiceFormik.submitForm}
                              >
                                Update
                              </Button>
                            </div>
                          ) : (
                            <Button
                              className="ms-auto"
                              onClick={() => {
                                handleBusiService(false);
                              }}
                            >
                              <i className="fa fa-pencil"></i>
                            </Button>
                          )}
                        </div>
                      </div>
                      <div className="py-2 relative Business-Services">
                        {isLoadingSwiper && (
                          <div
                            className="loader"
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              zIndex: "99999",
                            }}
                          >
                            <CircularProgress />
                          </div>
                        )}
                        {businessService && businessService.length > 0 ? (
                          businessService &&
                          businessService.length > 0 && (
                            <Swiper
                              navigation={true}
                              modules={[Navigation]}
                              className="mySwiper"
                              style={{ borderRadius: "20px" }}
                              onNavigationPrev={(index) => {
                                setActiveMenuIndex(index.activeIndex);
                                setIsLoadingSwiper(true);
                              }}
                              onNavigationNext={(index) => {
                                setActiveMenuIndex(index.activeIndex);
                                setIsLoadingSwiper(true);
                              }}
                            >
                              {businessService.map((menu, index) => {
                                console.log("indexxxxxxxxxxxx", index);
                                return (
                                  <SwiperSlide key={index} className="px-5">
                                    <div className="row py-2">
                                      <div className="col-md-12 pb-3">
                                        <Form.Group
                                          controlid="validationFormik101"
                                          className="position-relative"
                                        >
                                          <Form.Label
                                            style={{
                                              // textAlign: "start",
                                              color: "#000",
                                              // marginTop: "15px",
                                            }}
                                          >
                                            Add Service Photos
                                          </Form.Label>
                                          <div className="py-2">
                                            {!isBusiService ? (
                                              <div>
                                                {images &&
                                                  images.length > 0 && (
                                                    <div>
                                                      {images.map(
                                                        (photo, index) => (
                                                          <img
                                                            key={index}
                                                            src={photo}
                                                            alt={`photo-${index}`}
                                                            style={{
                                                              width: "100px",
                                                              height: "100px",
                                                              marginRight:
                                                                "10px",
                                                              marginBottom:
                                                                "10px",
                                                              objectFit:
                                                                "cover",
                                                            }}
                                                          />
                                                        )
                                                      )}
                                                    </div>
                                                  )}
                                              </div>
                                            ) : (
                                              <>
                                                <FormGroup className="form-group">
                                                  <input
                                                    type="file"
                                                    accept="image/*"
                                                    multiple
                                                    name="photos"
                                                    id="files"
                                                    onChange={(e) =>
                                                      showFileToUploadMenu(e)
                                                    }
                                                    className="form-control"
                                                  />
                                                  {/* {formik.errors.files && formik.touched.files ? (
                                            <p style={error}>{formik.errors.files}</p>
                                          ) : null} */}
                                                </FormGroup>
                                                <div
                                                  className="d-flex"
                                                  style={{ flexWrap: "wrap" }}
                                                >
                                                  {selectedMenuIamge.length >
                                                    0 &&
                                                    selectedMenuIamge.map(
                                                      (image) => {
                                                        return (
                                                          <div
                                                            key={image}
                                                            className="image d-flex text-start m-2 pointer"
                                                          >
                                                            <Button
                                                              onClick={() =>
                                                                deleteHandlerMenu(
                                                                  image
                                                                )
                                                              }
                                                              className="position-absolute z-index-10"
                                                            >
                                                              <i className="fa fa-times"></i>
                                                            </Button>
                                                            <img
                                                              src={
                                                                image.split(
                                                                  "///"
                                                                )[0]
                                                              }
                                                              style={{
                                                                width: "100px",
                                                                height: "100px",
                                                                marginRight:
                                                                  "10px",
                                                              }}
                                                              alt="upload"
                                                            />
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  {images &&
                                                    images.map(
                                                      (imageUrl, index) => (
                                                        <div
                                                          key={index}
                                                          style={{
                                                            position:
                                                              "relative",
                                                          }}
                                                          // className="me-2"
                                                          className="image d-flex text-start m-2 pointer "
                                                        >
                                                          <img
                                                            src={imageUrl}
                                                            alt={""}
                                                            style={{
                                                              width: "100px",
                                                              height: "100px",
                                                              marginRight:
                                                                "10px",
                                                            }}
                                                          />
                                                          <Button
                                                            onClick={() =>
                                                              deleteHandlerMenu2(
                                                                imageUrl
                                                              )
                                                            }
                                                            style={{
                                                              position:
                                                                "absolute",
                                                              left: 0,
                                                            }}
                                                          >
                                                            <i className="fas fa-trash"></i>
                                                          </Button>
                                                        </div>
                                                      )
                                                    )}
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </Form.Group>
                                      </div>
                                    </div>
                                    <div className="row py-2">
                                      <div className="col-md-6 pb-3">
                                        <Form.Group
                                          controlid="validationFormik101"
                                          className="position-relative"
                                        >
                                          <Form.Label
                                            style={{
                                              color: "#000",
                                            }}
                                          >
                                            Title
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            name="title"
                                            value={
                                              busiServiceFormik.values.title
                                            }
                                            onChange={
                                              busiServiceFormik.handleChange
                                            }
                                            readOnly={!isBusiService}
                                          />
                                        </Form.Group>
                                      </div>
                                      <div className="col-md-6 pb-3">
                                        <Form.Group
                                          controlid="validationFormik101"
                                          className="position-relative"
                                        >
                                          <Form.Label
                                            style={{
                                              color: "#000",
                                            }}
                                          >
                                            Price
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            name="price"
                                            value={
                                              busiServiceFormik.values.price
                                            }
                                            onChange={
                                              busiServiceFormik.handleChange
                                            }
                                            readOnly={!isBusiService}
                                          />
                                        </Form.Group>
                                      </div>
                                    </div>
                                    <div className="row py-2">
                                      <div className="col-md-6 pb-3">
                                        <Form.Group
                                          controlid="validationFormik101"
                                          className="position-relative"
                                        >
                                          <Form.Label
                                            style={{
                                              color: "#000",
                                            }}
                                          >
                                            Details
                                          </Form.Label>
                                          <Form.Control
                                            as="textarea"
                                            type="text"
                                            name="detail"
                                            readOnly={!isBusiService}
                                            rows={showFullDetails ? 8 : 4}
                                            // value={
                                            //   showFullDetails
                                            //     ? fullDetails
                                            //     : fullDetails.slice(0, 400)
                                            // }
                                            value={
                                              busiServiceFormik.values.detail
                                            }
                                            onChange={
                                              busiServiceFormik.handleChange
                                            }
                                          />
                                          {/* <div
                                    className="mt-2"
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      alignItems: "end",
                                    }}
                                  >
                                    <Button
                                      variant="primary"
                                      onClick={toggleDetails}
                                    >
                                      <span>
                                        {showFullDetails ? "LESS" : "MORE"}
                                      </span>
                                    </Button>
                                  </div> */}
                                        </Form.Group>
                                      </div>
                                    </div>
                                  </SwiperSlide>
                                );
                              })}
                            </Swiper>
                            // ) : (
                            //   <div></div>
                          )
                        ) : (
                          <div>Business Menu or Service Not Found</div>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="row-sm" style={{ overflowX: "auto" }}>
                <Col lg={12} style={{ padding: "0" }}>
                  <Card className="custom-card overflow-hidden">
                    <Card.Body>
                      <div>
                        <h2
                          className="main-content-label mb-4"
                          // style={{ fontSize: "20px" }}
                        >
                          booking detail
                        </h2>
                      </div>
                      <div className="responsive">
                        <DataTable
                          columns={columns}
                          data={data}
                          noHeader
                          persistTableHead
                          pagination
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                          highlightOnHover
                          // customStyles={{
                          //   rows: {
                          //     style: customRowStyles,
                          //   },
                          // }}
                        />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="row row-sm">
                <Col xl={12} lg={12} md={12}>
                  <div>
                    <h4>Queries</h4>
                  </div>
                  <Accordion defaultActiveKey={selectedAccordions} alwaysOpen>
                    {query.length > 0 ? (
                      query.length > 0 &&
                      query.map((querys, index) => (
                        <Accordion.Item
                          key={index}
                          eventKey={!editOpen ? `q${index + 1}` : ""}
                          style={{ marginBottom: "25px" }}
                        >
                          <Accordion.Header
                            style={{
                              backgroundColor: selectedAccordions.includes("0")
                                ? "#29295d"
                                : "#cfd9eb",
                            }}
                            onClick={() =>
                              handleAccordionToggle(`q${index + 1}`)
                            }
                          >
                            <div className="custom-accordion-header justify-content-between d-flex align-items-center w-100">
                              <div>
                                <div
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "50px 1fr",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {querys.question.userId.profileImage ? (
                                    <div className="Queries-imges">
                                      <img
                                        src={
                                          querys.question.userId.profileImage
                                        }
                                        alt="Profile"
                                        className="img rounded-circle ms-auto"
                                      />
                                    </div>
                                  ) : (
                                    <div className="Queries-imges">
                                      <img
                                        src={User}
                                        alt="Profile"
                                        className="img rounded-circle ms-auto"
                                      />
                                    </div>
                                  )}
                                  <div>
                                    <div
                                      className="name"
                                      style={{
                                        marginLeft: "15px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {querys.question.userId.userName}
                                    </div>
                                    <div
                                      className="question"
                                      style={{
                                        marginTop: "10px",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      {/* {querys.question.question} */}
                                      {truncateText(
                                        querys.question.question,
                                        350
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end">
                                <Button
                                  type="button"
                                  variant="info"
                                  className="me-2"
                                  size="sm"
                                  onClick={() => handleOpen("q", index)}
                                >
                                  <i className="fa fa-pencil"></i>
                                </Button>
                                <Button
                                  variant="danger"
                                  className="me-2"
                                  size="sm"
                                  onClick={() =>
                                    handleClickDeleteOpenDialogQue(querys)
                                  }
                                >
                                  <i className="fas fa-trash"></i>
                                </Button>
                              </div>
                              {/* </div> */}
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            {querys.answers.map((ans, i) => {
                              return (
                                <div
                                  className="profile-container d-flex align-items-start justify-content-between"
                                  key={i}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      // alignItems: "center",
                                      marginLeft: "55px",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: "45px",
                                        width: "100%",
                                        maxWidth: "45px",
                                        minWidth: "45px",
                                      }}
                                    >
                                      {ans.userId?.profileImage ? (
                                        <img
                                          src={ans.userId?.profileImage}
                                          alt="Profile"
                                          className="img rounded-circle avatar-xl"
                                          style={{
                                            height: "45px",
                                            width: "45px",
                                            objectFit: "cover",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={User}
                                          alt="Profile"
                                          className="img rounded-circle avatar-xl"
                                          style={{
                                            height: "45px",
                                            width: "45px",
                                            objectFit: "cover",
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div>
                                      <div
                                        style={{
                                          marginLeft: "15px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {/* {querys.answers.map((ans, index) => ( */}
                                        <>{ans.userId.userName}</>
                                        {/* ))} */}
                                      </div>
                                      <div
                                        className="profile-details"
                                        style={{ marginLeft: "15px" }}
                                      >
                                        <span>
                                          {ans.answer && (
                                            <span>
                                              {truncateDescription[ans._id]
                                                ? ans.answer
                                                : `${ans.answer.slice(0, 250)}`}
                                            </span>
                                          )}
                                          {ans.answer &&
                                            ans.answer.length > 250 && (
                                              <span
                                                style={{
                                                  cursor: "pointer",
                                                  fontWeight: "bolder",
                                                }}
                                                onClick={() =>
                                                  toggleDescription(ans)
                                                }
                                              >
                                                {truncateDescription[ans._id]
                                                  ? " ...LESS"
                                                  : " ...MORE"}
                                              </span>
                                            )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-end">
                                    <Button
                                      variant="info"
                                      className="me-2"
                                      size="sm"
                                      onClick={() => handleOpen("a1", index, i)}
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Button>
                                    <Button
                                      variant="danger"
                                      size="sm"
                                      onClick={() =>
                                        handleClickDeleteOpenDialog(ans._id)
                                      }
                                    >
                                      <i className="fas fa-trash"></i>
                                    </Button>
                                  </div>
                                </div>
                              );
                            })}
                          </Accordion.Body>
                        </Accordion.Item>
                        // ))}
                      ))
                    ) : (
                      <div style={{ marginBottom: "25px" }}>
                        Query Not Found
                      </div>
                    )}
                  </Accordion>
                  {/* </Card.Body>
            </Card> */}
                </Col>
              </Row>{" "}
            </>
          ) : (
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Business Not Found
            </h3>
          )}
          <EditQuery
            show={modalShow}
            onHide={handleDialogClose}
            updateQuery={updateQuery}
            formik={queryFormik}
            setEditOpen={setEditOpen}
            loading={loading}
          />
          {/* <DeleteUserList
        show={deleteOpen}
        onHide={handleClickDeleteClose}
        path={"/vendors/view/1"}
      /> */}
          {/* <DeleteQueryAns
        show={deleteQueryOpen}
        onHide={handleClickDeleteQueryClose}
      /> */}
          <DeleteVendoeQue
            show={deleteQueryOpen}
            onHide={handleClickDeleteQueryClose}
            onDelete={deleteQueryQuesData}
            loading={loading}
          />
          <DeleteVendorAns
            show={deleteOpen}
            onHide={handleClickDeleteClose}
            onDelete={deleteQueryAnsData}
            loading={loading}
          />
        </Fragment>
      )}
    </>
  );
};

export default ViewVendor;
