import serverCall from "../../serverCall";

const getAllVendor = async (page, limit) => {
  try {
    const response = await serverCall.get(
      `/users/admin/get-all-vendor?page=${page}&limit=${limit}`
    );
    return response;
  } catch (e) {
    console.log("Error getting all vendor", e);
  }
};

const addVendorDetail = async (data) => {
  try {
    const response = await serverCall.post(
      `/users/admin/create-vender-information`,
      data
    );
    return response;
  } catch (e) {
    console.log("Error adding vendor", e);
  }
};

const updateVendor = async (vendorId, data) => {
  try {
    const response = await serverCall.put(
      `/users/admin/update-vender-information?vendorId=${vendorId}`,
      data
    );
    return response;
  } catch (e) {
    console.log("Error updating vendor", e);
  }
};

const updateVendorDetail = async (vendorId, data) => {
  try {
    const response = await serverCall.put(
      `/users/admin/update-vender-information?vendorId=${vendorId}`,
      data
    );
    return response;
  } catch (e) {
    console.log("Error updating vendor", e);
  }
};

const updateBussDetail = async (data) => {
  try {
    const response = await serverCall.post(
      `/bussiness/admin/update-bussiness-detail`,
      data
    );
    return response;
  } catch (e) {
    console.log("Error updating vendor bussi detail", e);
  }
};

const updateBussAddress = async (data) => {
  try {
    const response = await serverCall.post(
      `/bussiness/admin/bussiness-address`,
      data
    );
  } catch (e) {
    console.log("Error updating vendor buss add", e);
  }
};

const updateBusiHour = async (data) => {
  try {
    const response = await serverCall.post(
      `/bussiness/admin/bussiness-hour`,
      data
    );
    return response;
  } catch (e) {
    console.log("busi hour error", e);
  }
};

const viewVendorDetail = async (vendorId) => {
  try {
    const response = await serverCall.get(
      `/users/admin/get-vendor-detail?vendorId=${vendorId}`
    );
    return response;
  } catch (e) {
    console.log("Error getting view vwndor detail", e);
  }
};

const deleteVendor = async (id) => {
  try {
    const response = await serverCall.delete(
      `/users/admin/delete-vendor?vendorId=${id}`
    );
    return response;
  } catch (e) {
    console.log("Error deleting vendor", e);
  }
};

const updateOtherInfo = async (data) => {
  console.log("Updating", data);
  const response = await serverCall.post(
    `/bussiness/admin/update-other-information`,
    data
  );
  return response;
};

const updateVendorImage = async (bussinessId, data) => {
  const response = await serverCall.post(
    `/bussiness/admin/add-bussiness-photos?bussinessId=${bussinessId}`,
    data
  );
  return response;
};

const deleteImage = async (data) => {
  console.log("🚀 ~ deleteImage ~ data:", data);
  const response = await serverCall.delete(
    `/bussiness/admin/delete-bussiness-photos`,
    { data: data }
  );
  return response;
};

const updateMenu = async (data) => {
  const response = await serverCall.post(`/bussiness/vendor/update-menu`, data);
  return response;
};

const deleteMenu = async (data) => {
  const response = await serverCall.delete(
    `/bussiness/vendor/delete-menu-photos`,
    { data: data }
  );
  return response;
};

const getAllItem = async (subCategoryId) => {
  const response = await serverCall.get(
    `/category/get-all-item?subCategoryId=${subCategoryId}`
  );
  return response;
};

const updateStatus = async (businessId) => {
  const response = await serverCall.post(
    `/bussiness/admin/verify-business?businessId=${businessId}`
  );
  return response;
};

const addKeyword = async(data) => {
  const response = await serverCall.post(`/bussiness/admin/add-keyword`, data)
  return response;
}

const VendorService = {
  getAllVendor,
  addVendorDetail,
  updateVendor,
  updateVendorDetail,
  updateBussDetail,
  updateBussAddress,
  updateBusiHour,
  viewVendorDetail,
  deleteVendor,
  updateOtherInfo,
  updateVendorImage,
  deleteImage,
  updateMenu,
  deleteMenu,
  getAllItem,
  updateStatus,
  addKeyword
};

export default VendorService;
