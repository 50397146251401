import serverCall from "../../serverCall";

const getAllNotification = async (page, limit) => {
  try {
    const response = await serverCall.get(
      `/notification/get-all-notification?page=${page}&limit=${limit}`
    );
    return response;
  } catch (err) {
    console.log("getallnotification error: " + err);
  }
};

const seenNotification = async (notificationId) => {
  try {
    const response = await serverCall.post(
      `/notification/seen-notification?notificationId=${notificationId}`
    );
    return response;
  } catch (err) {
    console.log("seenNotification error:", err);
  }
};

const UnseenNotification = async () => {
  try {
    const response = await serverCall.get(
      `/notification/admin/get-unseen-notification`
    );
    return response;
  } catch (err) {
    console.log("UnseenNotification error", err);
  }
};

const sendNotification = async (title, body) => {
  try {
    const response = await serverCall.post(
      `/notification/admin/send-notification?title=${title}&body=${body}`
    );
    return response;
  } catch (err) {
    console.log("sendNotification error:", err);
  }
};

const NotificationService = {
  getAllNotification,
  seenNotification,
  UnseenNotification,
  sendNotification,
};

export default NotificationService;
