import serverCall from "../../serverCall";

const getAllUser = async (page, limit, search) => {
  try {
    const response = await serverCall.get(
      `/users/admin/get-all-user?page=${page}&limit=${limit}&search=${search}`
    );
    return response;
  } catch (e) {
    console.log("Error getting all users", e);
  }
};

const addUserDetail = async (data) => {
  try {
    const response = await serverCall.post(
      `/users/admin/create-user-information`,
      data
    );
    return response;
  } catch (e) {
    console.log("Error adding user", e);
  }
};

const updateUserDetail = async (data) => {
  try {
    const response = await serverCall.post(
      `users/admin/update-user-detail
    `,
      data
    );
    return response;
  } catch (e) {
    console.log("Error updating user", e);
  }
};

const viewUserDetail = async (userId) => {
  try {
    const response = await serverCall.get(
      `/users/admin/get-user-detail?userId=${userId}`
    );
    return response;
  } catch (e) {
    console.log("Error getting user detail view", e);
  }
};

const deleteUser = async (userId) => {
  try {
    const response = await serverCall.delete(
      `/users/admin/delete-user?userId=${userId}`
    );
    return response;
  } catch (e) {
    console.log("Error deleting user", e);
  }
};

const changePassword = async (userId, password) => {
  try {
    const response = await serverCall.put(
      `/users/admin/change-password?userId=${userId}&password=${password}`
    );
    return response;
  } catch (e) {
    console.log("Error", e);
  }
};

const changeUserStatus = async (userId, isActive) => {
  const response = await serverCall.put(
    `/users/admin/change-user-status?userId=${userId}&isActive=${isActive}`
  );
  return response;
};

const UserListSetvice = {
  getAllUser,
  addUserDetail,
  updateUserDetail,
  viewUserDetail,
  deleteUser,
  changePassword,
  changeUserStatus
};

export default UserListSetvice;
