import React, { Fragment } from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Form, Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";

const UserVendorChat = () => {
  return (
    <>
      <div className="page-header">
        <h2 className="main-content-title tx-24 mb-2 mt-2">REPORT</h2>
      </div>
      <Row className="row-sm">
        <Col sm={12} md={12} lg={12} xl={12}>
          <Card className="custom-card">
            <div className="main-content-app pt-0">
              <div className="main-content-body main-content-body-chat">
                <div className="main-chat-header pt-3">
                  <div className="main-img-user online">
                    <img
                      alt="avatar"
                      src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=600"
                    />
                  </div>
                  <div className="main-chat-msg-name">
                    <h6>Sonia Taylor</h6>
                    {/* <span className="dot-label bg-success"></span>
                    <small className="me-3">online</small> */}
                  </div>
                </div>
                {/* <!-- main-chat-header --> */}
                <div className="main-chat-body" id="ChatBody">
                  <PerfectScrollbar style={{ width: "100%", height: "100%" }}>
                    <div className="content-inner">
                      <label className="main-chat-time">
                        <span>3 days ago</span>
                      </label>
                      <div className="media flex-row-reverse">
                        <div className="main-img-user online">
                          <img
                            alt="avatar"
                            src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=600"
                          />
                        </div>
                        <div className="media-body">
                          <div className="main-msg-wrapper">
                            Nulla consequat massa quis enim. Donec pede justo,
                            fringilla vel...
                          </div>
                          <div className="main-msg-wrapper2">
                            rhoncus ut, imperdiet a, venenatis vitae, justo...
                          </div>
                          <div>
                            <span>9:48 am</span>
                            <Link to="#">
                              <i className="icon ion-android-more-horizontal"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="media">
                        <div className="main-img-user online">
                          <img
                            alt="avatar"
                            src="https://images.pexels.com/photos/1374510/pexels-photo-1374510.jpeg?auto=compress&cs=tinysrgb&w=600"
                          />
                        </div>
                        <div className="media-body">
                          <div className="main-msg-wrapper">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing
                            elit. Aenean commodo ligula eget dolor.
                          </div>
                          <div>
                            <span>9:32 am</span>
                            <Link to="#">
                              <i className="icon ion-android-more-horizontal"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="media flex-row-reverse">
                        <div className="main-img-user online">
                          <img
                            alt="avatar"
                            src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=600"
                          />
                        </div>
                        <div className="media-body">
                          <div className="main-msg-wrapper">
                            Nullam dictum felis eu pede mollis pretium
                          </div>
                          <div>
                            <span>11:22 am</span>
                            <Link to="#">
                              <i className="icon ion-android-more-horizontal"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <label className="main-chat-time">
                        <span>Yesterday</span>
                      </label>
                      <div className="media">
                        <div className="main-img-user online">
                          <img
                            alt="avatar"
                            src="https://images.pexels.com/photos/1374510/pexels-photo-1374510.jpeg?auto=compress&cs=tinysrgb&w=600"
                          />
                        </div>
                        <div className="media-body">
                          <div className="main-msg-wrapper">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing
                            elit. Aenean commodo ligula eget dolor.
                          </div>
                          <div>
                            <span>9:32 am</span>
                            <Link to="#">
                              <i className="icon ion-android-more-horizontal"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="media flex-row-reverse">
                        <div className="main-img-user online">
                          <img
                            alt="avatar"
                            src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=600"
                          />
                        </div>
                        <div className="media-body">
                          <div className="main-msg-wrapper">
                            Donec quam felis, ultricies nec, pellentesque eu,
                            pretium quis, sem. Nulla consequat massa quis enim.
                            Donec pede justo, fringilla vel, aliquet nec. In
                            enim justo, rhoncus ut, imperdiet a, venenatis
                            vitae, justo.
                          </div>
                          <div className="main-msg-wrapper2">
                            Nullam dictum felis eu pede mollis pretium
                          </div>
                          <div>
                            <span>9:48 am</span>
                            <Link to="#">
                              <i className="icon ion-android-more-horizontal"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <label className="main-chat-time">
                        <span>Today</span>
                      </label>
                      <div className="media">
                        <div className="main-img-user online">
                          <img
                            alt="avatar"
                            src="https://images.pexels.com/photos/1374510/pexels-photo-1374510.jpeg?auto=compress&cs=tinysrgb&w=600"
                          />
                        </div>
                        <div className="media-body">
                          <div className="main-msg-wrapper">
                            Maecenas tempus, tellus eget condimentum rhoncus
                          </div>
                          <div className="pd-0">
                            {/* <img
                            alt="avatar"
                            className="wd-150 m-1"
                            src={require("../../../assets/img/media/3.jpg")}
                          />
                          <img
                            alt="avatar"
                            className="wd-150 m-1"
                            src={require("../../../assets/img/media/4.jpg")}
                          />
                          <img
                            alt="avatar"
                            className="wd-150 m-1"
                            src={require("../../../assets/img/media/5.jpg")}
                          /> */}
                          </div>
                          <div>
                            <span>10:12 am</span>
                            <Link to="#">
                              <i className="icon ion-android-more-horizontal"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="media flex-row-reverse">
                        <div className="main-img-user online">
                          <img
                            alt="avatar"
                            src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=600"
                          />
                        </div>
                        <div className="media-body">
                          <div className="main-msg-wrapper">
                            Maecenas tempus, tellus eget condimentum rhoncus
                          </div>
                          <div className="main-msg-wrapper2">
                            Nam quam nunc, blandit vel, luctus pulvinar,
                            hendrerit id, lorem. Maecenas nec odio et ante
                            tincidunt tempus. Donec vitae sapien ut libero
                            venenatis faucibus.
                          </div>
                          <div>
                            <span>09:40 am</span>
                            <Link to="#">
                              <i className="icon ion-android-more-horizontal"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </PerfectScrollbar>
                </div>
                <div className="main-chat-footer">
                  <nav className="nav">
                    {["top"].map((placement) => (
                      <OverlayTrigger
                        title="Add Photo"
                        data-bs-toggle="tooltip"
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            Add Photo
                          </Tooltip>
                        }
                      >
                        <i className="fe fe-image nav-link"></i>
                      </OverlayTrigger>
                    ))}
                    {["top"].map((placement) => (
                      <OverlayTrigger
                        title="Emoji"
                        data-bs-toggle="tooltip"
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            Record Voice
                          </Tooltip>
                        }
                      >
                        <i className="fe fe-mic nav-link"></i>
                      </OverlayTrigger>
                    ))}
                  </nav>
                  <input
                    className="form-control"
                    placeholder="Type your message here..."
                    type="text"
                  />
                  <Link className="main-msg-send" to="#">
                    {["top"].map((placement) => (
                      <OverlayTrigger
                        title="paper"
                        data-bs-toggle="tooltip"
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>Send</Tooltip>
                        }
                      >
                        <i className="far fa-paper-plane"></i>
                      </OverlayTrigger>
                    ))}
                  </Link>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default UserVendorChat;
