import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import DataTableExtensions from "react-data-table-component-extensions";
import { useEffect } from "react";
import { useState } from "react";
import ReportTicketService from "../../services/repoetTicket.service";

const ReportTicket = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [loadingData, setLoadingData] = useState(true);

  const getAllReportTicket = async () => {
    try {
      const response = await ReportTicketService.getAllTicket(
        currentPage,
        pageSize
      );
      setData(response.data.data.tickets);
      setTotalItems(response.data.data.totalTicket);
      setLoadingData(false);
      console.log("Response", response);
    } catch (err) {
      setLoadingData(false);
      console.log("Failed to get all report", err);
    }
  };

  useEffect(() => {
    getAllReportTicket();
  }, [currentPage, pageSize]);

  const handleRowsPerPageChange = (newPageSize, currentPage) => {
    setPageSize(newPageSize);
    setCurrentPage(currentPage);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      name: <strong>TICKET NUMBER</strong>,
      selector: (row) => [row.ticketId],
      width: "120px",
      sortable: true,
    },
    // {
    //   name: <strong>COUNSELLOR NAME</strong>,
    //   selector: (row) => [
    //     row.counsellor?.CounsellerName !== undefined &&
    //     row.counsellor?.CounsellerName !== null &&
    //     row.counsellor?.CounsellerName !== ""
    //       ? row.counsellor.CounsellerName
    //       : "--",
    //   ],
    //   sortable: true,
    // },
    {
      name: <strong>REPORTED NAME</strong>,
      selector: (row) => [
        row.reportedId?.userName !== undefined &&
        row.reportedId?.userName !== null &&
        row.reportedId?.userName !== ""
          ? row.reportedId.userName
          : "--",
      ],
      width: "180px",
      // selector: (row) => [row.reportedId.userName],
      sortable: true,
    },
    {
      name: <strong>REPORTER NAME</strong>,
      selector: (row) => [
        row.reporterId?.userName !== undefined &&
        row.reporterId?.userName !== null &&
        row.reporterId?.userName !== ""
          ? row.reporterId.userName
          : "--",
      ],
      width: "180px",
      // selector: (row) => [row.vendor ? row.vendor : "--"],
      sortable: true,
    },
    {
      name: <strong>REPORT TYPE</strong>,
      // selector: (row) => [row.entityType ? row.entityType : "--"],
      selector: (row) => [
        row.entityType !== undefined &&
        row.entityType !== null &&
        row.entityType !== ""
          ? row.entityType
          : "--",
      ],
      width: "120px",
      sortable: true,
    },
    {
      name: <strong>REPORT REASON</strong>,
      // selector: (row) => [row.reason ? row.reason : "--"],
      selector: (row) => [
        row.reason !== undefined && row.reason !== null && row.reason !== ""
          ? row.reason
          : "--",
      ],
      width: "330px",
      sortable: true,
    },
    {
      name: <strong>DESCRIPTION</strong>,
      // selector: (row) => [row.description],
      selector: (row) => [
        row?.description !== undefined &&
        row?.description !== null &&
        row?.description !== ""
          ? row.description
          : "--",
      ],
      width: "520px",
      sortable: true,
    },
    {
      name: <strong>ACTION</strong>,
      selector: (row) => (
        <Link
          className="btn btn-primary p-1 btn-sm"
          id={row.VIEW}
          to={`/reportticket/${row._id}`}
          state={{
            reportedId: row.reportedId?._id,
            reporterId: row.reporterId?._id,
          }}
          // to={`/uservendorchat`}
        >
          <i className="fe fe-eye"></i> View
        </Link>
      ),
      sortable: true,
    },
  ];

  const tableData = {
    columns,
    data,
  };
  return (
    <>
      {loadingData ? (
        <div className="loader-container">
          <div
            style={{ position: "absolute", top: "50%", left: "50%" }}
            className="loader"
          >
            Just A Moment....
          </div>
        </div>
      ) : (
        <>
          <div className="page-header2"></div>
          <div className="row">
            <Col sm={12} md={6} lg={6} xl={3}>
              <Card className="card custom-card mb-4">
                <Card.Body>
                  <div className="card-item">
                    <div className="card-item-icon card-icon">
                      <i
                        className="ti-bookmark-alt fs-20"
                        style={{ color: "#6259CA" }}
                      ></i>
                    </div>
                    <div className="card-item-title  mb-2">
                      <label className="main-content-label tx-13 font-weight-bold ">
                        Total Report
                      </label>
                      {/* <span className="d-block tx-12 mb-0 text-muted">
                  Total Audio calls
                </span> */}
                    </div>
                    <div className="card-item-body">
                      <div className="card-item-stat">
                        <h4 className="font-weight-bold">{totalItems}</h4>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={6} lg={6} xl={4} className="ms-auto mt-auto">
              <div
                className="custom-card text-end  pointer"
                style={{ marginRight: "18px" }}
              >
                {" "}
                {/* <Link
            className="btn btn-primary text-white"
            //  to={`/users/adduser/`}
          //   onClick={() => handleOpen(null, false)}
          >
            <i className="fas fa-plus"></i> &nbsp; Add User Details
          </Link> */}
              </div>
            </Col>
          </div>
          <Row className="row-sm" style={{ overflowX: "auto" }}>
            <Col lg={12} style={{ padding: "0" }}>
              <Card className="custom-card overflow-hidden">
                <Card.Body>
                  <div>
                    <h6 className="main-content-label mb-4">
                      Total Report detail
                    </h6>
                  </div>
                  <div className="responsive">
                    <div className="d-flex justify-content-center">
                      {/* <input
                  style={{
                    borderRadius: "5px",
                    padding: "5px",
                    border: "1px solid #e8e8f7",
                  }}
                  // value={filterValue}
                  // onChange={handleFilterChange}
                  placeholder="Search here"
                  className="ms-auto mb-2 border-rounded"
                /> */}
                    </div>
                    <DataTable
                      columns={columns}
                      data={data}
                      noHeader
                      persistTableHead
                      pagination
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                      paginationServer
                      highlightOnHover
                      paginationTotalRows={totalItems}
                      paginationPerPage={pageSize}
                      paginationRowsPerPageOptions={[10, 25, 50]}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onChangePage={handlePageChange}
                      // progressPending={loading}
                      progressComponent={
                        <div className="text-center"> Just A Moment....</div>
                      }
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ReportTicket;
