import serverCall from "../../serverCall";

const getAllTicket = async (page, limit) => {
  try {
    const response = await serverCall.get(
      `/report/admin/get-all-ticket?page=${[page]}&limit=${limit}`
    );
    return response;
  } catch (err) {
    console.log("Failed to get all tickets", err);
  }
};

const getMessage = async (id) => {
  const response = await serverCall.get(`/report/get-messages?reportId=${id}`);
  return response;
};

const sendMessage = async (reportId, message) => {
  const response = await serverCall.post(
    `/report/send-message?reportId=${reportId}&message=${message}`
  );
  return response;
};

const createReport = async (reportId) => {
  const response = await serverCall.post(
    `/report/create-report-chat?reportId=${reportId}`
  );
  return response;
};

const statusChange = async (ticketId, status) => {
  const response = await serverCall.post(
    `/report/ticket-action?ticketId=${ticketId}&status=${status}`
  );
  return response;
};

const ReportTicketService = {
  getAllTicket,
  getMessage,
  sendMessage,
  createReport,
  statusChange,
};

export default ReportTicketService;
