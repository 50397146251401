import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

const SendNotification = (props) => {
  return (
    <Modal
      {...props}
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "22px", color: "black" }}>
          Send Notification
        </Modal.Title>{" "}
      </Modal.Header>
      <form onSubmit={props.formik.handleSubmit}>
        <Modal.Body className="text-center">
          <div className="col-12">
            <div className="card custom-card">
              {/* <h4>How Many Add Refer Code</h4> */}
              <div className="col-12">
                <Form.Group
                  controlid="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "2px",
                    }}
                  >
                    Title
                    <span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    // as="textarea"
                    type="text"
                    // rows={4}
                    name="title"
                    placeholder="Write text"
                    value={props.formik.values.title}
                    onChange={props.formik.handleChange}
                  />
                  {props.formik.errors.title && props.formik.touched.title ? (
                    <p className="text-start error">
                      {" "}
                      {props.formik.errors.title}
                    </p>
                  ) : null}
                </Form.Group>
              </div>
              <div className="col-12">
                <Form.Group
                  controlid="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Body
                    <span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    rows={4}
                    name="body"
                    placeholder="Write text"
                    value={props.formik.values.body}
                    onChange={props.formik.handleChange}
                  />
                  {props.formik.errors.body && props.formik.touched.body ? (
                    <p className="text-start error">
                      {" "}
                      {props.formik.errors.body}
                    </p>
                  ) : null}
                </Form.Group>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="mg-t-20">
          {props.loading ? (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                aria-hidden="true"
                animation="border"
                style={{ color: "white", width: "24px", height: "24px" }}
                size="sm"
                role="status"
              />
            </Button>
          ) : (
            <Button type="submit" style={{ marginBottom: "0px" }}>
              Submit
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default SendNotification;
