import {
  MDBAccordion,
  MDBAccordionItem,
  MDBContainer,
  MDBTypography,
} from "mdb-react-ui-kit";
import React, { Fragment } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import AddHelp from "./AddHelp";
import UseDialogState from "../../../hooks/UseDialogState";
import DeleteUserList from "../userlist/DeleteUserList";

const HelpandSupport = () => {
  const {
    open,
    handleClickOpen,
    handleClickClose,
    deleteOpen,
    handleClickDeleteOpen,
    handleClickDeleteClose,
  } = UseDialogState();
  return (
    <Fragment>
      <Row className="row-sm mt-5 page-header">
        <Col md={12} lg={12} xl={12}>
          <Card
            className="custom-card transcation-crypto"
            style={{
              fullwidth: "100%",
              marginTop: "0px",
              backgroundColor: "#EAEDF7",
            }}
          >
            <Card.Body>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h2
                  className="main-content-title tx-24 "
                  style={{ marginBottom: "40px" }}
                >
                  Help and Support
                </h2>
                <Button
                  size="md"
                  variant="primary"
                  style={{
                    borderRadius: "10px",
                    marginLeft: "auto",
                  }}
                  className="ms-auto AddFaq"
                  onClick={handleClickOpen}
                >
                  + Add
                </Button>
              </div>

              {/* {data &&
                data?.map((item, index) => ( */}
              <div style={{ marginBottom: "20px", width: "100%" }}>
                {/* <MDBContainer className="" style={{ width: "100%" }}> */}
                <MDBAccordion
                  alwaysOpen
                  // initialActive={index}
                >
                  <MDBAccordionItem
                    style={{
                      whiteSpace: "pre-line",
                    }}
                    collapseId={1}
                    // headerTitle={item.question}
                    headerTitle="What is Lorem Ipsum?"
                  >
                    <div style={{ display: "flex", marginBottom: "5px" }}>
                      <MDBTypography
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "0px",
                          whiteSpace: "pre-line",
                          fontSize: "15px",
                        }}
                        // tag="h5"
                        // key={index}
                      >
                        {" "}
                        {/* {item.question} */}Lorem Ipsum is simply dummy text
                        of the printing and typesetting industry. Lorem Ipsum
                        has been the industry's standard dummy text ever since
                        the 1500s, when an unknown printer took a galley of type
                        and scrambled it to make a type specimen book.
                      </MDBTypography>

                      <Button
                        style={{
                          marginLeft: "auto",
                          marginTop: "-5px",
                          marginBottom: "auto",
                        }}
                        // onClick={() => handleShow(item)}
                        className="btn btn-primary btn-sm"
                      >
                        <i className="fa fa-pencil"></i>
                      </Button>

                      <Button
                        variant="danger"
                        style={{
                          marginTop: "-5px",
                          marginBottom: "auto",
                          marginLeft: "5px",
                        }}
                        onClick={() => handleClickDeleteOpen()}
                        className="btn btn-danger btn-sm"
                      >
                        <i className="fa fa-trash" />
                      </Button>
                    </div>
                    {/* {item.answer} */}
                  </MDBAccordionItem>
                </MDBAccordion>
                {/* </MDBContainer> */}
              </div>
              <div style={{ marginBottom: "20px", width: "100%" }}>
                {/* <MDBContainer className="" style={{ width: "100%" }}> */}
                <MDBAccordion
                  alwaysOpen
                  // initialActive={index}
                >
                  <MDBAccordionItem
                    style={{
                      whiteSpace: "pre-line",
                    }}
                    collapseId={1}
                    // headerTitle={item.question}
                    headerTitle="Where can I get some?"
                  >
                    <div style={{ display: "flex", marginBottom: "5px" }}>
                      <MDBTypography
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "0px",
                          whiteSpace: "pre-line",
                          fontSize: "15px",
                        }}
                        // tag="h5"
                        // key={index}
                      >
                        {" "}
                        {/* {item.question} */}There are many variations of
                        passages of Lorem Ipsum available, but the majority have
                        suffered alteration in some form, by injected humour, or
                        randomised words which don't look even slightly
                        believable.
                      </MDBTypography>

                      <Button
                        style={{
                          marginLeft: "auto",
                          marginTop: "-5px",
                          marginBottom: "auto",
                        }}
                        // onClick={() => handleShow(item)}
                        className="btn btn-primary btn-sm"
                      >
                        <i className="fa fa-pencil"></i>
                      </Button>

                      <Button
                        variant="danger"
                        style={{
                          marginTop: "-5px",
                          marginBottom: "auto",
                          marginLeft: "5px",
                        }}
                        onClick={() => handleClickDeleteOpen()}
                        className="btn btn-danger btn-sm"
                      >
                        <i className="fa fa-trash" />
                      </Button>
                    </div>
                    {/* {item.answer} */}
                  </MDBAccordionItem>
                </MDBAccordion>
                {/* </MDBContainer> */}
              </div>
              {/* ))} */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <Pagination
        className="pagination-circle mb-0 ms-lg-3"
        style={{
          justifyContent: "center",
          // backgroundColor: "#F7F3EA",
        }}
      >
        {currentPage !== 1 && (
          <div
            onClick={() => previousPage(currentPage - 1)}
            style={{
              cursor: "pointer",
              width: "32px",
              height: "32px",
              backgroundColor: "#fff",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <i className="fas fa-angle-double-left"></i>
          </div>
        )}
        {Array.from({ length: Math.ceil(count / 12) }).map((_, index) => {
          const pageNumber = index + 1;
          return (
            <Pagination.Item
              onClick={() => nextPage(pageNumber)}
              key={pageNumber}
              active={pageNumber === currentPage}
              style={{
                color: pageNumber === currentPage ? "red" : undefined,
              }}
            >
              {pageNumber}
            </Pagination.Item>
          );
        })}
        {data.length > 10 && (
          <div
            onClick={() => nextPage(currentPage + 1)}
            style={{
              cursor: "pointer",
              width: "32px",
              height: "32px",
              backgroundColor: "#fff",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <i className="fas fa-angle-double-right"></i>
          </div>
        )}
      </Pagination> */}
      <AddHelp show={open} onHide={handleClickClose} />
      <DeleteUserList
        show={deleteOpen}
        onHide={handleClickDeleteClose}
        path={"/helpandsupport"}
      />
    </Fragment>
  );
};

export default HelpandSupport;
