import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AuthLogin from "./app/authentication/login";
import { lazy } from "react";
import MatxLayout from "./app/component/maxLayout";
import NotFound from "./app/layout/sessions/NotFound";
import { Suspense } from "react";
import Loader from "./app/layout/loader/loader";
import Dashboard from "./app/component/dashboard/Dashboard";
import UserList from "./app/component/userlist/UserList";
import Vendor from "./app/component/vendor/Vendor";
import AddVender from "./app/component/vendor/AddVender";
import ViewVendor from "./app/component/vendor/ViewVendor";
import Notification from "./app/component/notifications/Notification";
import UserNotification from "./app/component/userlist/UserNotification";
import VendorNotification from "./app/component/vendor/VendorNotification";
import HelpandSupport from "./app/component/helpandsupport/HelpandSupport";
import AddUserList from "./app/component/userlist/AddUserList";
import ViewUserList from "./app/component/userlist/ViewUserList";
import Profits from "./app/component/profits/Profits";
import Bookings from "./app/component/bookings/Bookings";
import TrendingSearch from "./app/component/trendingSearch/TrendingSearch";
import UserVendorChat from "./app/component/chat/UserVendorChat";
import ReportTicket from "./app/component/repportTicket/ReportTicket";
import ReportChat from "./app/component/repportTicket/ReportChat";
import FeatureandEcoFriendly from "./app/component/featureandecofriendly/FeatureandEcoFriendly";

const Loading = (Component) => (props) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );

function RedirectionWrapper({ to }) {
  const queryString = window.location.search;
  if (queryString) {
    return <Navigate to={`${to}${queryString}`} />;
  }
  return <Navigate to={to} />;
}

const routes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? <MatxLayout /> : <RedirectionWrapper to="/login" />,
    children: [
      {
        index: true,
        element: <RedirectionWrapper to="/dashboard/" />,
      },
      { path: "/dashboard/", element: <Dashboard /> },
      {
        path: "/users/",
        element: <UserList />,
      },
      {
        path: "/users/adduser/",
        element: <AddUserList />,
      },
      {
        path: "/users/view/:id/",
        element: <ViewUserList />,
      },
      {
        path: "/users/:id/",
        element: <UserNotification />,
      },
      {
        path: "/vendors/",
        element: <Vendor />,
      },
      {
        path: "/vendors/:id/",
        element: <AddVender />,
      },
      {
        path: "/vendors/view/:id/",
        element: <ViewVendor />,
      },
      {
        path: "/vendors/noti/:id/",
        element: <VendorNotification />,
      },
      {
        path: "/bookings/",
        element: <Bookings />,
      },
      {
        path: "/trendingsearch/",
        element: <TrendingSearch />,
      },
      {
        path: "/featureandecofriendly/",
        element: <FeatureandEcoFriendly />,
      },
      {
        path: "/uservendorchat/",
        element: <UserVendorChat />,
      },
      {
        path: "/notifications/",
        element: <Notification />,
      },
      {
        path: "/helpandsupport/",
        element: <HelpandSupport />,
      },
      {
        path: "/profit/",
        element: <Profits />,
      },
      {
        path: "/reportticket/",
        element: <ReportTicket />,
      },
      {
        path: "/reportticket/:id",
        element: <ReportChat />,
      },
    ],
  },
  {
    path: "/login",
    element: !isLoggedIn ? (
      <AuthLogin />
    ) : (
      <RedirectionWrapper to="/dashboard/" />
    ),
  },
  { path: "*", element: <NotFound /> },
];

export default function Routes(props) {
  const { isLoggedIn } = props;
  return useRoutes(routes(isLoggedIn));
}
