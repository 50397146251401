import { Space, TimePicker, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import {
  Accordion,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  Col,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import Select from "react-select";
import { UploadOutlined } from "@ant-design/icons";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { upload } from "@testing-library/user-event/dist/upload";
import { IoArrowBack } from "react-icons/io5";
import PhoneInput from "react-phone-input-2";

const AddVender = () => {
  const Location = useLocation();
  const update = Location.state.id;
  const [selectedImage, setSelectedImage] = useState(null);
  const [mainCat, setMainCat] = useState(null);
  const [subCat, setSubCat] = useState(null);
  const [features, setFeatures] = useState(null);
  const [ecoFriendly, setEcoFriendly] = useState(null);
  const [showAllDetails, setShowAllDetails] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const navigate = useNavigate();
  const [isEmail, setIsEmail] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isDefault, setIsDefault] = useState("");

  console.log("updateupdate", update);

  const WeekMap = [
    { name: "Monday" },
    { name: "Tuesday" },
    { name: "Wednesday" },
    { name: "Thursday" },
    { name: "Friday" },
    { name: "Saturday" },
    { name: "Sunday" },
  ];

  const mainCategoryOptions = [
    { value: "category1", label: "Category 1" },
    { value: "category2", label: "Category 2" },
  ];

  const handleMainCategory = (e) => {
    setMainCat(e);
    otherDetailFormik.setFieldValue("maincategory", e);
  };

  const handleSubCategory = (e) => {
    setSubCat(e);
  };

  const handleServices = (e) => {
    console.log("eeeeeee", e);
    setSelectedServices(e);
  };

  const handleFeatures = (e) => {
    setFeatures(e);
  };

  const handleEcoFrendly = (e) => {
    setEcoFriendly(e);
  };

  const categoryOptions = [
    { value: "category1", label: "Category 1" },
    { value: "category2", label: "Category 2" },
  ];

  const [fileList, setFileList] = useState([
    {
      uid: "-1",
      name: "image.png",
      status: "done",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
  ]);

  const [fileListService, setFileListService] = useState([
    {
      uid: "-1",
      name: "image.png",
      status: "done",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
  ]);

  const handleImageChange = (info) => {
    console.log("dasdasda", info.fileList);
    setFileList(info.fileList);
  };
  const handleImageChangeService = (info) => {
    console.log("dasdasda", info.fileList);
    setFileListService(info.fileList);
  };

  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess();
    }, 0);
  };

  const serviceOptions = [
    { value: "service1", label: "Service 1" },
    { value: "service2", label: "Service 2" },
    { value: "service1", label: "Service 1" },
    { value: "service2", label: "Service 2" },
    { value: "service3", label: "Service 3" },
    { value: "service2", label: "Service 2" },
    { value: "service3", label: "Service 3" },
    { value: "service2", label: "Service 2" },
    { value: "service1", label: "Service 1" },
    { value: "service2", label: "Service 2" },
  ];
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [regionsForSelectedCountry, setRegionsForSelectedCountry] = useState(
    []
  );

  const handleCountryChange = (val) => {
    setCountry(val);
    const regionsByCountry = {
      "United States": ["New York", "California", "Texas"],
    };
    setRegionsForSelectedCountry(regionsByCountry[val] || []);
    otherDetailFormik.setFieldValue("country", val);
  };

  useEffect(() => {
    setRegion("");
  }, [country]);

  const handleRegionChange = (val) => {
    setRegion(val);
    otherDetailFormik.setFieldValue("state", val);
  };

  const submit = () => {
    setShowAllDetails(true);
    // formik.resetForm();
  };

  const validationSchema = Yup.object({
    firstname: edit ? "" : Yup.string().required("First name is required"),
    lastname: edit ? "" : Yup.string().required("Last name is required"),
    email: isEmail
      ? Yup.string()
          .email("Invalid email address")
          .required("Email is required")
      : "",
    password: isEmail ? Yup.string().required("Password is required") : "",
    phonenumber: isEmail
      ? ""
      : Yup.number()
          .required("Phone number is required")
          .transform((originalValue, originalObject) =>
            isNaN(originalValue) ? undefined : originalValue
          )
          .test(
            "is-ten-digits",
            "Phone number must be 10 digits",
            (value) => String(value).length === 10
          ),
  });

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      phonenumber: "",
      profile: "",
    },
    onSubmit: submit,
    validationSchema,
  });

  console.log("Formik", formik);

  const otherDetailSubmit = () => {
    formik.resetForm();
    otherDetailFormik.resetForm();
    navigate("/vendors");
  };

  const handleBack = () => {
    navigate("/vendors");
  };

  const otherValidationSchema = Yup.object({
    address: Yup.string().required("Address is required"),
    apt: Yup.string().required("apt/suite/other is required"),
    country: Yup.mixed().required("Country is required"),
    zipcode: Yup.string().required("Zipcode is required"),
    state: Yup.mixed().required("State is required"),
    city: Yup.string().required("City is required"),
    maincategory: Yup.mixed().required("MainCategory is required"),
    businessname: edit
      ? ""
      : Yup.string().required("Business Name is required"),
    phonenumbers: edit
      ? ""
      : Yup.number()
          .required("Phone number is required")
          .transform((originalValue, originalObject) =>
            isNaN(originalValue) ? undefined : originalValue
          ) // Transform to undefined if not a number
          .test(
            "is-ten-digits",
            "Phone number must be 10 digits",
            (value) => String(value).length === 10
          ),
  });

  useEffect(
    (data) => {
      if (update) {
        otherDetailFormik.setFieldValue("maincategory", "data");
        otherDetailFormik.setFieldValue("address", "mota varacha, surat");
        otherDetailFormik.setFieldValue("apt", "1a");
        otherDetailFormik.setFieldValue("country", "India");
        otherDetailFormik.setFieldValue("zipcode", "56847");
        otherDetailFormik.setFieldValue("state", "Gujarat");
        otherDetailFormik.setFieldValue("city", "Surat");
        otherDetailFormik.setFieldValue("businessname", "Pizza Factor");
        otherDetailFormik.setFieldValue("website", "www.test.com");
        otherDetailFormik.setFieldValue("phonenumbers", "95674 86574");
        formik.setFieldValue("firstname", "John");
        formik.setFieldValue("lastname", "joe");
        formik.setFieldValue("email", "test@gmail.com");
        formik.setFieldValue("phonenumber", "85674 86574");
        formik.setFieldValue("profile", "");
      }
    },
    [update]
  );

  const otherDetailFormik = useFormik({
    initialValues: {
      address: "",
      apt: "",
      country: "",
      zipcode: "",
      state: "",
      city: "",
      bhopen: "",
      bhclose: "",
      maincategory: "",
      bookingprice: "",
      ecofriendly: "",
      features: "",
      services: "",
      subcategory: "",
      title: "",
      serviceimage: "",
      price: "",
      detail: "",
      acceptcash: "",
      acceptcredit: "",
      businessname: "",
      website: "",
      phonenumbers: "",
    },
    onSubmit: otherDetailSubmit,
    validationSchema: otherValidationSchema,
  });

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption.value);
    setIsEmail(selectedOption.value === "email" ? true : false);
    formik.setFieldValue("selectedOption", selectedOption);
    setIsDefault(selectedOption.value);
  };
  const options = [
    { value: "email", label: "Email" },
    { value: "mobileNumber", label: "Phone Number" },
  ];

  const renderSelectedOptionFields = () => {
    switch (selectedOption?.value) {
      case "email":
        return (
          <>
            <div className="col-md-6 pb-3">
              <Form.Group
                controlId="validationFormik101"
                className="position-relative"
              >
                <Form.Label style={{ color: "#000" }}>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="E-mail"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.errors.email && formik.touched.email ? (
                  <p className="text-start error">{formik.errors.email}</p>
                ) : null}
              </Form.Group>
            </div>
            <div className="col-md-6 pb-3">
              <Form.Group
                controlId="validationFormik101"
                className="position-relative"
              >
                <Form.Label style={{ color: "#000" }}>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Enter Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                {formik.errors.password && formik.touched.password ? (
                  <p className="text-start error">{formik.errors.password}</p>
                ) : null}
              </Form.Group>
            </div>
          </>
        );
      case "phonenumber":
        return (
          <div className="col-md-6 pb-3">
            <Form.Group
              controlId="validationFormik101"
              className="position-relative"
            >
              <Form.Label style={{ color: "#000" }}>Phone Number</Form.Label>
              <Form.Control
                type="number"
                name="phonenumber"
                placeholder="Phone Number"
                value={formik.values.phonenumber}
                onChange={formik.handleChange}
              />
              {formik.errors.phonenumber && formik.touched.phonenumber ? (
                <p className="text-start error">{formik.errors.phonenumber}</p>
              ) : null}
            </Form.Group>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <div className="page-header">
        <div>
          <Breadcrumb>
            <BreadcrumbItem
              className="mb-2"
              onClick={() => handleBack()}
              active
              style={{ cursor: "pointer", fontSize: "20px", color: "#170C6B" }}
            >
              <IoArrowBack /> Back
            </BreadcrumbItem>
          </Breadcrumb>
          <h2 className="main-content-title tx-24 mg-b-5">
            {update ? "Update Vendor Details" : "Add Vendor Details"}
          </h2>
        </div>
      </div>
      <Row className="row row-sm">
        <Col xl={12} lg={12} md={12}>
          <Card className="custom-card">
            <Card.Body>
              <form onSubmit={formik.handleSubmit}>
                <div className="d-flex align-items-start pb-3 border-bottom">
                  <div style={{ marginTop: "10px" }}>
                    <h4>Profile Detail</h4>
                  </div>
                  <img
                    src={
                      selectedImage ||
                      "https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=600"
                    }
                    className="img rounded-circle avatar-xl ms-auto mb-3"
                    alt="user1"
                    onClick={() =>
                      document.getElementById("imageInput").click()
                    }
                    style={{ cursor: "pointer" }}
                  />
                  <input
                    id="imageInput"
                    type="file"
                    accept="image/*"
                    name="profile"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          setSelectedImage(reader.result);
                          // formik.setFieldValue("profile", reader.result);
                        };
                        reader.readAsDataURL(file);
                      }
                    }}
                  />
                </div>
                <div className="py-2">
                  <div className="row py-2">
                    <div className="col-md-6 pb-3">
                      <Form.Group
                        controlid="validationFormik101"
                        className="position-relative"
                      >
                        <Form.Label
                          style={{
                            // textAlign: "start",
                            color: "#000",
                            // marginTop: "15px",
                          }}
                        >
                          First Name
                          <span className="tx-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="firstname"
                          placeholder="First Name"
                          value={formik.values.firstname}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.firstname && formik.touched.firstname ? (
                          <p className="text-start error">
                            {" "}
                            {formik.errors.firstname}
                          </p>
                        ) : null}
                      </Form.Group>
                    </div>
                    <div className="col-md-6 pb-3">
                      <Form.Group
                        controlid="validationFormik101"
                        className="position-relative"
                      >
                        <Form.Label
                          style={{
                            color: "#000",
                          }}
                        >
                          Last Name
                          <span className="tx-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="lastname"
                          placeholder="Last Name"
                          value={formik.values.lastname}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.lastname && formik.touched.lastname ? (
                          <p className="text-start error">
                            {" "}
                            {formik.errors.lastname}
                          </p>
                        ) : null}
                      </Form.Group>
                    </div>
                  </div>
                  {/* <div className="row py-2">
                    <div className="col-md-6 pb-3">
                      <Form.Group controlId="selectOption">
                        <Form.Label style={{ color: "#000" }}>
                          Email or Phone Number
                        </Form.Label>
                        <Select
                          options={[
                            { value: "email", label: "Email" },
                            { value: "phonenumber", label: "Phone Number" },
                          ]}
                          value={selectedOption}
                          onChange={handleSelectChange}
                        />
                      </Form.Group>
                    </div>
                    {renderSelectedOptionFields()}
                  </div> */}
                  {!edit ? (
                    <>
                      <div className="row py-2">
                        <div className="col-md-6 pb-3">
                          <Form.Group
                            controlId="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                textAlign: "start",
                                color: "#000",
                                marginTop: "15px",
                              }}
                            >
                              Select Email or PhoneNumber
                              <span className="tx-danger">*</span>
                            </Form.Label>
                            <Select
                              value={selectedOption}
                              onChange={handleSelectChange}
                              options={options}
                            />
                          </Form.Group>
                        </div>
                        {isDefault === "email" ? (
                          <>
                            <div className="col-md-6 pb-3">
                              <Form.Group
                                controlid="validationFormik101"
                                className="position-relative"
                              >
                                <Form.Label
                                  style={{
                                    textAlign: "start",
                                    color: "#000",
                                    marginTop: "15px",
                                  }}
                                >
                                  E-mail
                                  <span className="tx-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="email"
                                  name="email"
                                  placeholder="E-mail"
                                  value={formik.values.email}
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.email && formik.touched.email ? (
                                  <p className="text-start error">
                                    {" "}
                                    {formik.errors.email}
                                  </p>
                                ) : null}
                              </Form.Group>
                            </div>
                            <div className="col-md-6 pb-3">
                              <Form.Group
                                controlid="validationFormik101"
                                className="position-relative"
                              >
                                <Form.Label
                                  style={{
                                    textAlign: "start",
                                    color: "#000",
                                    marginTop: "15px",
                                  }}
                                >
                                  Password
                                  <span className="tx-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="password"
                                  name="password"
                                  placeholder="password"
                                  value={formik.values.password}
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.password &&
                                formik.touched.password ? (
                                  <p className="text-start error">
                                    {" "}
                                    {formik.errors.password}
                                  </p>
                                ) : null}
                              </Form.Group>
                            </div>
                          </>
                        ) : isDefault === "mobileNumber" ? (
                          <div className="col-md-6 pb-3">
                            <Form.Group
                              controlid="validationFormik101"
                              className="position-relative"
                            >
                              <Form.Label
                                style={{
                                  textAlign: "start",
                                  color: "#000",
                                  marginTop: "15px",
                                }}
                              >
                                Phone Number
                                <span className="tx-danger">*</span>
                              </Form.Label>
                              {/* <Form.Control
                      type="number"
                      name="mobileNumber"
                      placeholder="Phone Number"
                      value={formik.values.mobileNumber}
                      onChange={formik.handleChange}
                    /> */}
                              <PhoneInput
                                className="addContacts"
                                country={"in"}
                                value={formik.values.mobileNumber}
                                onChange={(value) =>
                                  formik.setFieldValue("mobileNumber", value)
                                }
                              />
                              {formik.errors.mobileNumber &&
                              formik.touched.mobileNumber ? (
                                <p className="text-start error">
                                  {" "}
                                  {formik.errors.mobileNumber}
                                </p>
                              ) : null}
                            </Form.Group>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : null}
                  {selectedOption && selectedOption.value === "email" && (
                    <>
                      <Form.Group
                        controlid="validationFormik101"
                        className="position-relative"
                      >
                        <Form.Label
                          style={{
                            textAlign: "start",
                            color: "#000",
                            marginTop: "15px",
                          }}
                        >
                          E-mail
                          <span className="tx-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="E-mail"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.email && formik.touched.email ? (
                          <p className="text-start error">
                            {" "}
                            {formik.errors.email}
                          </p>
                        ) : null}
                      </Form.Group>
                    </>
                  )}
                  {selectedOption &&
                    selectedOption.value === "mobileNumber" && (
                      <>
                        {" "}
                        <Form.Group
                          controlid="validationFormik101"
                          className="position-relative"
                        >
                          <Form.Label
                            style={{
                              textAlign: "start",
                              color: "#000",
                              marginTop: "15px",
                            }}
                          >
                            Phone Number
                            <span className="tx-danger">*</span>
                          </Form.Label>
                          <PhoneInput
                            className="addContacts"
                            country={"in"}
                            value={formik.values.mobileNumber}
                            onChange={(value) =>
                              formik.setFieldValue("mobileNumber", value)
                            }
                          />
                          {formik.errors.mobileNumber &&
                          formik.touched.mobileNumber ? (
                            <p className="text-start error">
                              {" "}
                              {formik.errors.mobileNumber}
                            </p>
                          ) : null}
                        </Form.Group>
                      </>
                    )}
                  <div className="row py-2">
                    {update ? (
                      <div
                        className="col-md-12 pb-3"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "end",
                        }}
                      >
                        <Button type="submit">submit</Button>
                      </div>
                    ) : (
                      <div
                        className="col-md-12 pb-3"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "end",
                        }}
                      >
                        <Button type="submit">Continue</Button>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {(update || showAllDetails) && (
        <>
          <form onSubmit={otherDetailFormik.handleSubmit}>
            <Row className="row row-sm">
              <Col xl={12} lg={12} md={12}>
                <Card className="custom-card">
                  <Card.Body>
                    <form>
                      <div className="d-flex align-items-start pb-3 border-bottom">
                        <div>
                          <h4>Business Details</h4>
                        </div>
                      </div>
                      <div className="py-2">
                        <div className="row py-2">
                          <div className="col-md-6 pb-3">
                            <Form.Group
                              controlid="validationFormik101"
                              className="position-relative"
                            >
                              <Form.Label
                                style={{
                                  color: "#000",
                                }}
                              >
                                Bussiness Name
                                <span className="tx-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="businessname"
                                placeholder="Bussiness Name"
                                value={otherDetailFormik.values.businessname}
                                onChange={otherDetailFormik.handleChange}
                              />
                              {otherDetailFormik.errors.businessname &&
                              otherDetailFormik.touched.businessname ? (
                                <p className="text-start error">
                                  {" "}
                                  {otherDetailFormik.errors.businessname}
                                </p>
                              ) : null}
                            </Form.Group>
                          </div>
                          <div className="col-md-6 pb-3">
                            <Form.Group
                              controlid="validationFormik101"
                              className="position-relative"
                            >
                              <Form.Label
                                style={{
                                  color: "#000",
                                }}
                              >
                                Website
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="website"
                                placeholder="Enter website link"
                                value={otherDetailFormik.values.website}
                                onChange={otherDetailFormik.handleChange}
                              />
                              {/* {formik.errors.website && formik.touched.website ? (
                        <p className="text-start error">
                          {" "}
                          {formik.errors.website}
                        </p>
                      ) : null} */}
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      {/* <div className="py-2"> */}
                      <div className="row py-2">
                        <div className="col-md-6 pb-3">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                color: "#000",
                              }}
                            >
                              Business Phone Number
                              <span className="tx-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name="phonenumbers"
                              placeholder="Phone Number"
                              value={otherDetailFormik.values.phonenumbers}
                              onChange={otherDetailFormik.handleChange}
                            />
                            {otherDetailFormik.errors.phonenumbers &&
                            otherDetailFormik.touched.phonenumbers ? (
                              <p className="text-start error">
                                {" "}
                                {otherDetailFormik.errors.phonenumbers}
                              </p>
                            ) : null}
                          </Form.Group>
                        </div>
                      </div>
                      {/* </div> */}
                    </form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="row row-sm">
              <Col xl={12} lg={12} md={12}>
                <Card className="custom-card">
                  <Card.Body>
                    <form>
                      <div className="d-flex align-items-start pb-3 border-bottom">
                        <div>
                          <h4>Business Address</h4>
                        </div>
                      </div>
                      <div className="py-2">
                        <div className="row py-2">
                          <div className="col-md-6 pb-3">
                            <Form.Group
                              controlid="validationFormik101"
                              className="position-relative"
                            >
                              <Form.Label
                                style={{
                                  // textAlign: "start",
                                  color: "#000",
                                  // marginTop: "15px",
                                }}
                              >
                                Address
                                <span className="tx-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="address"
                                placeholder="Address"
                                value={otherDetailFormik.values.address}
                                onChange={otherDetailFormik.handleChange}
                              />
                              {otherDetailFormik.errors.address &&
                              otherDetailFormik.touched.address ? (
                                <p className="text-start error">
                                  {" "}
                                  {otherDetailFormik.errors.address}
                                </p>
                              ) : null}
                            </Form.Group>
                          </div>
                          <div className="col-md-6 pb-3">
                            <Form.Group
                              controlid="validationFormik101"
                              className="position-relative"
                            >
                              <Form.Label
                                style={{
                                  color: "#000",
                                }}
                              >
                                Apt/Suite/Other
                                <span className="tx-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="apt"
                                placeholder="Apt/Suite/Other"
                                value={otherDetailFormik.values.apt}
                                onChange={otherDetailFormik.handleChange}
                              />
                              {otherDetailFormik.errors.apt &&
                              otherDetailFormik.touched.apt ? (
                                <p className="text-start error">
                                  {" "}
                                  {otherDetailFormik.errors.apt}
                                </p>
                              ) : null}
                            </Form.Group>
                          </div>
                        </div>
                        <div className="row py-2">
                          <div className="col-md-6 pb-3">
                            <Form.Group
                              controlid="validationFormik101"
                              className="position-relative"
                            >
                              <Form.Label
                                style={{
                                  color: "#000",
                                }}
                              >
                                Country
                                <span className="tx-danger">*</span>
                              </Form.Label>
                              <CountryDropdown
                                name="country"
                                value={country}
                                onChange={handleCountryChange}
                                classes="form-control"
                              />
                              {otherDetailFormik.errors.country &&
                              otherDetailFormik.touched.country ? (
                                <p className="text-start error">
                                  {" "}
                                  {otherDetailFormik.errors.country}
                                </p>
                              ) : null}
                            </Form.Group>
                          </div>
                          <div className="col-md-6 pb-3">
                            <Form.Group
                              controlid="validationFormik101"
                              className="position-relative"
                            >
                              <Form.Label
                                style={{
                                  color: "#000",
                                }}
                              >
                                Zip Code
                                <span className="tx-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="number"
                                name="zipcode"
                                placeholder="Zip Code"
                                value={otherDetailFormik.values.zipcode}
                                onChange={otherDetailFormik.handleChange}
                              />
                              {otherDetailFormik.errors.zipcode &&
                              otherDetailFormik.touched.zipcode ? (
                                <p className="text-start error">
                                  {" "}
                                  {otherDetailFormik.errors.zipcode}
                                </p>
                              ) : null}
                            </Form.Group>
                          </div>
                        </div>
                        <div className="row py-2">
                          <div className="col-md-6 pb-3">
                            <Form.Group
                              controlid="validationFormik101"
                              className="position-relative"
                            >
                              <Form.Label
                                style={{
                                  color: "#000",
                                }}
                              >
                                State
                                <span className="tx-danger">*</span>
                              </Form.Label>
                              <RegionDropdown
                                name="state"
                                value={region}
                                onChange={handleRegionChange}
                                classes="form-control"
                                disableWhenEmpty
                                country={country}
                                defaultOptionLabel="Select Region"
                                options={regionsForSelectedCountry}
                              />
                              {otherDetailFormik.errors.state &&
                              otherDetailFormik.touched.state ? (
                                <p className="text-start error">
                                  {" "}
                                  {otherDetailFormik.errors.state}
                                </p>
                              ) : null}
                            </Form.Group>
                          </div>
                          <div className="col-md-6 pb-3">
                            <Form.Group
                              controlid="validationFormik101"
                              className="position-relative"
                            >
                              <Form.Label
                                style={{
                                  color: "#000",
                                }}
                              >
                                City
                                <span className="tx-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="city"
                                placeholder="City"
                                value={otherDetailFormik.values.city}
                                onChange={otherDetailFormik.handleChange}
                              />
                              {otherDetailFormik.errors.city &&
                              otherDetailFormik.touched.city ? (
                                <p className="text-start error">
                                  {" "}
                                  {otherDetailFormik.errors.city}
                                </p>
                              ) : null}
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="row row-sm">
              <Col xl={4} lg={12} md={12}>
                <Card className="custom-card">
                  <Card.Body>
                    <form>
                      <div className="d-flex align-items-start pb-3 border-bottom">
                        <div>
                          <h4>Business Hours</h4>
                        </div>
                      </div>
                      {/* <div className="py-2">
                      <div className="row py-2">
                        <div
                          className="col-md-12"
                          style={{ fontSize: "15px", fontWeight: "bolder" }}
                        >
                          Monday
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                color: "#000",
                              }}
                            >
                              Open at
                              <span className="tx-danger">*</span>
                            </Form.Label>
                            <Space wrap>
                              <TimePicker
                                use12Hours
                                format="h:mm a"
                                onChange={onChange}
                              />
                            </Space>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                color: "#000",
                              }}
                            >
                              Close at
                              <span className="tx-danger">*</span>
                            </Form.Label>
                            <Space wrap>
                              <TimePicker
                                use12Hours
                                format="h:mm a"
                                onChange={onChange}
                              />
                            </Space>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row py-2">
                        <div
                          className="col-md-12"
                          style={{ fontSize: "15px", fontWeight: "bolder" }}
                        >
                          Tuesday
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                color: "#000",
                              }}
                            >
                              Open at
                              <span className="tx-danger">*</span>
                            </Form.Label>
                            <Space wrap>
                              <TimePicker
                                use12Hours
                                format="h:mm a"
                                onChange={onChange}
                              />
                            </Space>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                color: "#000",
                              }}
                            >
                              Close at
                              <span className="tx-danger">*</span>
                            </Form.Label>
                            <Space wrap>
                              <TimePicker
                                use12Hours
                                format="h:mm a"
                                onChange={onChange}
                              />
                            </Space>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row py-2">
                        <div
                          className="col-md-12"
                          style={{ fontSize: "15px", fontWeight: "bolder" }}
                        >
                          Wednesday
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                color: "#000",
                              }}
                            >
                              Open at
                              <span className="tx-danger">*</span>
                            </Form.Label>
                            <Space wrap>
                              <TimePicker
                                use12Hours
                                format="h:mm a"
                                onChange={onChange}
                              />
                            </Space>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                color: "#000",
                              }}
                            >
                              Close at
                              <span className="tx-danger">*</span>
                            </Form.Label>
                            <Space wrap>
                              <TimePicker
                                use12Hours
                                format="h:mm a"
                                onChange={onChange}
                              />
                            </Space>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row py-2">
                        <div
                          className="col-md-12"
                          style={{ fontSize: "15px", fontWeight: "bolder" }}
                        >
                          Thuesday
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                color: "#000",
                              }}
                            >
                              Open at
                              <span className="tx-danger">*</span>
                            </Form.Label>
                            <Space wrap>
                              <TimePicker
                                use12Hours
                                format="h:mm a"
                                onChange={onChange}
                              />
                            </Space>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                color: "#000",
                              }}
                            >
                              Close at
                              <span className="tx-danger">*</span>
                            </Form.Label>
                            <Space wrap>
                              <TimePicker
                                use12Hours
                                format="h:mm a"
                                onChange={onChange}
                              />
                            </Space>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row py-2">
                        <div
                          className="col-md-12"
                          style={{ fontSize: "15px", fontWeight: "bolder" }}
                        >
                          Friday
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                color: "#000",
                              }}
                            >
                              Open at
                              <span className="tx-danger">*</span>
                            </Form.Label>
                            <Space wrap>
                              <TimePicker
                                use12Hours
                                format="h:mm a"
                                onChange={onChange}
                              />
                            </Space>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                color: "#000",
                              }}
                            >
                              Close at
                              <span className="tx-danger">*</span>
                            </Form.Label>
                            <Space wrap>
                              <TimePicker
                                use12Hours
                                format="h:mm a"
                                onChange={onChange}
                              />
                            </Space>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row py-2">
                        <div
                          className="col-md-12"
                          style={{ fontSize: "15px", fontWeight: "bolder" }}
                        >
                          Saturday
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                color: "#000",
                              }}
                            >
                              Open at
                              <span className="tx-danger">*</span>
                            </Form.Label>
                            <Space wrap>
                              <TimePicker
                                use12Hours
                                format="h:mm a"
                                onChange={onChange}
                              />
                            </Space>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                color: "#000",
                              }}
                            >
                              Close at
                              <span className="tx-danger">*</span>
                            </Form.Label>
                            <Space wrap>
                              <TimePicker
                                use12Hours
                                format="h:mm a"
                                onChange={onChange}
                              />
                            </Space>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row py-2">
                        <div
                          className="col-md-12"
                          style={{ fontSize: "15px", fontWeight: "bolder" }}
                        >
                          Sunday
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                color: "#000",
                              }}
                            >
                              Open at
                              <span className="tx-danger">*</span>
                            </Form.Label>
                            <Space wrap>
                              <TimePicker
                                use12Hours
                                format="h:mm a"
                                onChange={onChange}
                              />
                            </Space>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group
                            controlid="validationFormik101"
                            className="position-relative"
                          >
                            <Form.Label
                              style={{
                                color: "#000",
                              }}
                            >
                              Close at
                              <span className="tx-danger">*</span>
                            </Form.Label>
                            <Space wrap>
                              <TimePicker
                                use12Hours
                                format="h:mm a"
                                onChange={onChange}
                              />
                            </Space>
                          </Form.Group>
                        </div>
                      </div>
                    </div> */}
                      {WeekMap.map((dayData, index) => (
                        <div className="row py-2" key={index}>
                          <div
                            className="col-md-12"
                            style={{ fontSize: "15px", fontWeight: "bolder" }}
                          >
                            {dayData.name}
                          </div>
                          <div className="col-md-6">
                            <Form.Group
                              controlId={`openTime-${dayData.day}`}
                              className="position-relative"
                            >
                              <Form.Label
                                style={{
                                  color: "#000",
                                }}
                              >
                                Open at
                              </Form.Label>
                              <Space wrap>
                                <TimePicker
                                  use12Hours
                                  format="h:mm a"
                                  // onChange={(time, timeString) =>
                                  //   onChanges(time, timeString, dayData.day)
                                  // }
                                  name="bhopen"
                                />
                              </Space>
                            </Form.Group>
                          </div>
                          <div className="col-md-6">
                            <Form.Group
                              controlId={`closeTime-${dayData.day}`}
                              className="position-relative"
                            >
                              <Form.Label
                                style={{
                                  color: "#000",
                                }}
                              >
                                Close at
                              </Form.Label>
                              <Space wrap>
                                <TimePicker
                                  use12Hours
                                  format="h:mm a"
                                  // onChange={(time, timeString) =>
                                  //   onChanges(time, timeString, dayData.day)
                                  // }
                                  name="bhclose"
                                />
                              </Space>
                            </Form.Group>
                          </div>
                        </div>
                      ))}
                    </form>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={8} lg={12} md={12}>
                <Card className="custom-card">
                  <Card.Body>
                    <form>
                      <div className="d-flex align-items-start pb-3 border-bottom">
                        <div>
                          <h4>Business Category & Services</h4>
                        </div>
                      </div>
                      <div className="py-2">
                        <div className="row py-2">
                          <div className="col-md-6">
                            <Form.Group className="position-relative">
                              <Form.Label
                                style={{
                                  // textAlign: "start",
                                  color: "#000",
                                  // marginTop: "15px",
                                }}
                              >
                                Main Category
                                <span className="tx-danger">*</span>
                              </Form.Label>
                              <Select
                                value={mainCat}
                                onChange={(e) => handleMainCategory(e)}
                                options={mainCategoryOptions}
                                isSearchable
                                placeholder="Select Main Category"
                                name="maincategory"
                              />
                              {otherDetailFormik.errors.maincategory &&
                              otherDetailFormik.touched.maincategory ? (
                                <p className="text-start error">
                                  {" "}
                                  {otherDetailFormik.errors.maincategory}
                                </p>
                              ) : null}
                            </Form.Group>
                            <div className="row py-2">
                              <div className="col-md-6">
                                <label
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  Main Category Name:
                                </label>{" "}
                                <span>{mainCat?.label}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <Form.Group className="position-relative">
                              <Form.Label
                                style={{
                                  color: "#000",
                                }}
                              >
                                Sub Category
                              </Form.Label>
                              <Select
                                value={subCat}
                                onChange={(e) => handleSubCategory(e)}
                                options={categoryOptions}
                                isSearchable
                                placeholder="Select Sub Category"
                                name="subcategory"
                              />
                            </Form.Group>
                            <div className="row py-2">
                              <div className="col-md-6">
                                <label
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  Sub Category Name:
                                </label>{" "}
                                <span>{subCat?.label}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <Form.Group className="position-relative">
                              <Form.Label
                                style={{
                                  // textAlign: "start",
                                  color: "#000",
                                  // marginTop: "15px",
                                }}
                              >
                                Services
                              </Form.Label>
                              <Select
                                value={selectedServices}
                                onChange={(selectedOptions) =>
                                  handleServices(selectedOptions)
                                }
                                options={serviceOptions}
                                isMulti
                                isSearchable
                                placeholder="Select Services"
                                name="services"
                              />
                            </Form.Group>

                            <div className="row py-2">
                              <div className="col-md-6">
                                <label
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  Services Name:
                                </label>{" "}
                                <span>
                                  {selectedServices
                                    ?.map((res) => res?.label)
                                    .join(", ")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-start pb-3 border-bottom">
                        <div>
                          <div>
                            <h4>Business Features & Business Eco-friendly</h4>
                          </div>
                        </div>
                      </div>
                      <div className="py-2">
                        <div className="row py-2">
                          <div className="col-md-6">
                            <Form.Group className="position-relative">
                              <Form.Label
                                style={{
                                  color: "#000",
                                }}
                              >
                                Business Features
                              </Form.Label>
                              <Select
                                value={features}
                                onChange={(selectedOption) =>
                                  handleFeatures(selectedOption)
                                }
                                isMulti
                                options={categoryOptions}
                                isSearchable
                                placeholder="Select Business Features"
                                name="features"
                              />
                            </Form.Group>
                            <div className="row py-2">
                              <div className="col-md-6">
                                <label
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  Business Features:
                                </label>{" "}
                                <span>
                                  {features
                                    ?.map((res) => res?.label)
                                    .join(", ")}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <Form.Group className="position-relative">
                              <Form.Label
                                style={{
                                  color: "#000",
                                }}
                              >
                                Business Eco-friendly
                              </Form.Label>
                              <Select
                                value={ecoFriendly}
                                onChange={(selectedOption) =>
                                  handleEcoFrendly(selectedOption)
                                }
                                options={categoryOptions}
                                isMulti
                                isSearchable
                                placeholder="Select Eco-friendly"
                                name="ecofriendly"
                              />
                            </Form.Group>
                            <div className="row py-2">
                              <div className="col-md-6">
                                {/* Category Select */}
                                <label
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  Business Eco-friendly:
                                </label>{" "}
                                <span>
                                  {ecoFriendly
                                    ?.map((res) => res?.label)
                                    .join(", ")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-start pb-3 border-bottom">
                        <div>
                          <h4>Payments & Booking Price</h4>
                        </div>
                      </div>
                      <div className="py-2">
                        <div className="row py-2">
                          <div className="col-md-6">
                            <Form.Group className="m-0">
                              <div className="custom-controls-stacked">
                                <label className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name="acceptcash"
                                    value="option1"
                                    defaultChecked=""
                                  />
                                  <span className="custom-control-label">
                                    Are you accepting Cash?
                                  </span>
                                </label>
                                <label className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name="acceptcredit"
                                    value="option2"
                                  />
                                  <span className="custom-control-label">
                                    Are you accepting Credit Cards?
                                  </span>
                                </label>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-md-6 pb-3">
                            <Form.Group
                              controlid="validationFormik101"
                              className="position-relative"
                            >
                              <Form.Label
                                style={{
                                  // textAlign: "start",
                                  color: "#000",
                                  // marginTop: "15px",
                                }}
                              >
                                Price
                              </Form.Label>
                              <Form.Control
                                type="number"
                                name="bookingprice"
                                placeholder="Enter Price"
                                value={otherDetailFormik.values.price}
                                onChange={otherDetailFormik.handleChange}
                              />
                              {otherDetailFormik.errors.price &&
                              otherDetailFormik.touched.price ? (
                                <p className="text-start error">
                                  {" "}
                                  {otherDetailFormik.errors.price}
                                </p>
                              ) : null}
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="row row-sm">
              <Col xl={12} lg={12} md={12}>
                <Card className="custom-card">
                  <Card.Body>
                    <form>
                      <div className="d-flex align-items-start pb-3 border-bottom">
                        <div>
                          <h4>Business Photos</h4>
                        </div>
                      </div>
                      <div className="py-2">
                        <Upload
                          customRequest={customRequest}
                          fileList={fileList}
                          onChange={handleImageChange}
                          listType="picture-card"
                          maxCount={5}
                        >
                          {fileList.length < 5 && (
                            <div>
                              <UploadOutlined />
                              <div style={{ marginTop: 8 }}>Upload</div>
                            </div>
                          )}
                        </Upload>
                      </div>
                    </form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="row row-sm">
              <Col xl={12} lg={12} md={12}>
                <Card className="custom-card">
                  <Card.Body>
                    <form>
                      <div className="d-flex align-items-start pb-3 border-bottom">
                        <div>
                          <h4>Business Services</h4>
                        </div>
                      </div>
                      <div className="py-2">
                        <div className="row py-2">
                          <div className="col-md-12 pb-3">
                            <Form.Group
                              controlid="validationFormik101"
                              className="position-relative"
                            >
                              <Form.Label
                                style={{
                                  // textAlign: "start",
                                  color: "#000",
                                  // marginTop: "15px",
                                }}
                              >
                                Add Service Photos
                              </Form.Label>

                              {/* <div className="py-2"> */}
                              <Upload
                                customRequest={customRequest}
                                fileList={fileListService}
                                onChange={handleImageChangeService}
                                listType="picture-card"
                                maxCount={5}
                                name="serviceimage"
                              >
                                {fileListService.length < 5 && (
                                  <div>
                                    <UploadOutlined />
                                    <div style={{ marginTop: 8 }}>Upload</div>
                                  </div>
                                )}
                              </Upload>
                              {/* </div> */}
                            </Form.Group>
                          </div>
                        </div>
                        <div className="row py-2">
                          <div className="col-md-6 pb-3">
                            <Form.Group
                              controlid="validationFormik101"
                              className="position-relative"
                            >
                              <Form.Label
                                style={{
                                  color: "#000",
                                }}
                              >
                                Title
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="title"
                                placeholder="Enter Title"
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-6 pb-3">
                            <Form.Group
                              controlid="validationFormik101"
                              className="position-relative"
                            >
                              <Form.Label
                                style={{
                                  color: "#000",
                                }}
                              >
                                Price
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="price"
                                placeholder="Enter Price"
                              />
                            </Form.Group>
                          </div>
                        </div>
                        <div className="row py-2">
                          <div className="col-md-6 pb-3">
                            <Form.Group
                              controlid="validationFormik101"
                              className="position-relative"
                            >
                              <Form.Label
                                style={{
                                  color: "#000",
                                }}
                              >
                                Details
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={4}
                                type="text"
                                name="detail"
                                placeholder="Details"
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button
              type="submit"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                marginLeft: "auto",
                marginBottom: "15px",
                marginTop: "15px",
              }}
            >
              {update ? "Update" : "Add"}
            </Button>
          </form>
        </>
      )}
    </Fragment>
  );
};

export default AddVender;
