import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";

const UserNotification = () => {
  const columns = [
    {
      name: <b>User Name</b>,
      selector: (row) => [row.firstname],
      sortable: true,
      cell: (row) => <div>{row.firstname}</div>,
    },
    {
      name: <b>E-MAIL</b>,
      selector: (row) => [row.email],
      sortable: true,
      cell: (row) => <div>{row.email}</div>,
    },
    {
      name: <b>PROFILE</b>,
      selector: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="demo-avatar-group my-auto float-end">
              <div className="main-img-user">
                <img
                  className="rounded-circle avatar-md me-2"
                  src={row.profile}
                  width="30px"
                  height="30px"
                  alt=""
                />
              </div>
            </div>
          </div>
        );
      },
      sortable: true,
    },
  ];
  const data = [
    {
      firstname: "demo",
      lastname: "studio",
      communityname: "folsom-pulse",
      businessname: "folsom",
      profile:
        "https://images.pexels.com/photos/1037999/pexels-photo-1037999.jpeg?auto=compress&cs=tinysrgb&w=600",
      email: "test@gmail.com",
      dob: "01-01-2001",
      phonenumber: "9576482135",
      country: "India",
      feedback: "5",
    },
    {
      firstname: "demo 1",
      lastname: "studio 1",
      communityname: "folsom-pulse 1",
      businessname: "folsom 1",
      profile:
        "https://images.pexels.com/photos/7585607/pexels-photo-7585607.jpeg?auto=compress&cs=tinysrgb&w=600",
      email: "test1@gmail.com",
      dob: "01-05-2004",
      phonenumber: "9576582135",
      country: "India",
      feedback: "4",
    },
  ];
  return (
    <>
      <div className="page-header2"></div>
      <div className="row">
        <h2>User Notification</h2>
      </div>
      <Row className="row-sm mt-3">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div>
                {/* <h6 className="main-content-label mb-1">all users noti</h6> */}
              </div>
              <div className="responsive">
                <DataTable
                  //   {...extensions}
                  columns={columns}
                  data={data}
                  noHeader
                  persistTableHead
                  pagination
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                  highlightOnHover
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default UserNotification;
