import React from "react";
import { useState } from "react";

const UseDialogState = () => {
  const [open, setOpen] = useState(false);
  const [openEco, setOpenEco] = useState(false);
  const [DeleteOpenEco, setDeleteOpenEco] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteQueryOpen, setDeleteQueryOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [editQuery, setEditQuery] = useState(false);
  const [userStatusOpen, setUserStatusOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleClickOpenEco = () => {
    setOpenEco(true);
  };

  const handleClickCloseEco = () => {
    setOpenEco(false);
  };

  const handleClickDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleClickDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleClickDeleteOpenEco = () => {
    setDeleteOpenEco(true);
  };

  const handleClickDeleteCloseEco = () => {
    setDeleteOpenEco(false);
  };

  const handleClickDeleteQueryOpen = () => {
    setDeleteQueryOpen(true);
  };

  const handleClickDeleteQueryClose = () => {
    setDeleteQueryOpen(false);
  };

  const handleClickViewOpen = () => {
    setViewOpen(true);
  };

  const handleClickViewClose = () => {
    setViewOpen(false);
  };

  const handleEditQueryOpen = () => {
    setEditQuery(true);
  };

  const handleEditQueryClose = () => {
    setEditQuery(false);
  };

  const handleUserStatusOpen = () => {
    setUserStatusOpen(true);
  };

  const handleUserStatusClose = () => {
    setUserStatusOpen(false);
  };

  return {
    open,
    handleClickOpen,
    handleClickClose,
    openEco,
    handleClickOpenEco,
    handleClickCloseEco,
    DeleteOpenEco,
    handleClickDeleteOpenEco,
    handleClickDeleteCloseEco,
    deleteOpen,
    handleClickDeleteOpen,
    handleClickDeleteClose,
    deleteQueryOpen,
    handleClickDeleteQueryOpen,
    handleClickDeleteQueryClose,
    viewOpen,
    handleClickViewOpen,
    handleClickViewClose,
    editQuery,
    handleEditQueryOpen,
    handleEditQueryClose,
    userStatusOpen,
    handleUserStatusOpen,
    handleUserStatusClose,
  };
};

export default UseDialogState;
