import { registerables, Chart as ChartJS } from "chart.js";
import React from "react";
import { Line } from "react-chartjs-2";
ChartJS.register(...registerables);

const MainGraph = ({ data, vendor, user, booking, profit,query }) => {
  // console.log("data", data, vendor, user, booking);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const linechart = {
    labels: months,
    datasets: [
      {
        label: "PROFITS",
        data: [
          profit.January,
          profit.February,
          profit.March,
          profit.April,
          profit.May,
          profit.June,
          profit.July,
          profit.August,
          profit.September,
          profit.October,
          profit.November,
          profit.December,
        ],
        borderWidth: 3,
        backgroundColor: "transparent",
        borderColor: "#6259ca",
        pointBackgroundColor: "ffffff",
        pointRadius: 0,
        type: "line",
        tension: 0.4,
      },
      {
        label: "BOOKINGS",
        data: [
          booking.January,
          booking.February,
          booking.March,
          booking.April,
          booking.May,
          booking.June,
          booking.July,
          booking.August,
          booking.September,
          booking.October,
          booking.November,
          booking.December,
        ],
        borderWidth: 3,
        backgroundColor: "transparent",
        borderColor: "#742774",
        pointBackgroundColor: "#ffffff",
        pointRadius: 0,
        type: "line",
        tension: 0.3,
      },
      {
        label: "USERS",
        data: [
          user.January,
          user.February,
          user.March,
          user.April,
          user.May,
          user.June,
          user.July,
          user.August,
          user.September,
          user.October,
          user.November,
          user.December,
        ],
        borderWidth: 3,
        backgroundColor: "transparent",
        borderColor: "rgb(75, 192, 192)",
        pointBackgroundColor: "#ffffff",
        pointRadius: 0,
        type: "line",
        tension: 0.3,
      },
      {
        label: "VENDORS",
        data: [
          vendor.January,
          vendor.February,
          vendor.March,
          vendor.April,
          vendor.May,
          vendor.June,
          vendor.July,
          vendor.August,
          vendor.September,
          vendor.October,
          vendor.November,
          vendor.December,
        ],
        borderWidth: 3,
        backgroundColor: "transparent",
        borderColor: "rgb(53, 162, 235)",
        pointBackgroundColor: "#ffffff",
        pointRadius: 0,
        type: "line",
        tension: 0.3,
      },
      {
        label: "TRENDING SEARCH",
        data: [
            query.January,
            query.February,
            query.March,
            query.April,
            query.May,
            query.June,
            query.July,
            query.August,
            query.September,
            query.October,
            query.November,
            query.December,
        ],
        borderWidth: 3,
        backgroundColor: "transparent",
        borderColor: "rgb(255, 99, 132)",
        pointBackgroundColor: "#ffffff",
        pointRadius: 0,
        type: "line",
        tension: 0.3,
      },
      // {
      //   label: "VENDER INTERACTION",
      //   data: [
      //       15, 25, 54
      //     //   chat.January,
      //     //   chat.February,
      //     //   chat.March,
      //     //   chat.April,
      //     //   chat.May,
      //     //   chat.June,
      //     //   chat.July,
      //     //   chat.August,
      //     //   chat.September,
      //     //   chat.October,
      //     //   chat.November,
      //     //   chat.December,
      //   ],
      //   borderWidth: 3,
      //   backgroundColor: "transparent",
      //   borderColor: "rgba(151,187,205,1)",
      //   pointBackgroundColor: "#ffffff",
      //   pointRadius: 0,
      //   type: "line",
      //   tension: 0.4,
      // },
    ],
  };
  const linechartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
      },
      legend: {
        position: "top",
        display: true,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        ticks: {
          fontColor: "#c8ccdb",
        },
        barPercentage: 0.7,
        display: true,
        grid: {
          borderColor: "rgba(119, 119, 142, 0.2)",
        },
      },
      y: {
        display: true,
        grid: {
          borderColor: "rgba(119, 119, 142, 0.2)",
        },
        scaleLabel: {
          display: true,
          labelString: "Thousands",
          fontColor: "transparent",
        },
      },
    },
    interaction: {
      intersect: false,
    },
  };
  return (
    <Line
      options={linechartoptions}
      data={linechart}
      className="barchart chart-dropshadow2 ht-300 chartjs-render-monitor"
      height="100"
    />
  );
};

export default MainGraph;
