import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

const EditQuery = (props) => {
  console.log("Edit Query", props);
  return (
    <Modal
      {...props}
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      show={props.show}
      onHide={() => {
        props.onHide();
        props.setEditOpen(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "22px", color: "black" }}>
          {props.updateQuery === "q"
            ? "Update Query Question"
            : "Update Query Answer"}
          {/* Add Query Question & Answer */}
        </Modal.Title>{" "}
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="col-122">
          <div className="card custom-card">
            <form onSubmit={props.formik.handleSubmit}>
              {/* <h4>How Many Add Refer Code</h4> */}
              <div className="col-12">
                {props.updateQuery === "q" ? (
                  <Form.Group
                    controlid="validationFormik101"
                    className="position-relative"
                  >
                    <Form.Label
                      style={{
                        textAlign: "start",
                        color: "#000",
                      }}
                    >
                      Question
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      type="text"
                      name="question"
                      placeholder="Enter quetion"
                      value={props.formik.values.question}
                      onChange={props.formik.handleChange}

                      // value={formik.values.generateReferenceCode}
                      // onChange={formik.handleChange}
                    />
                  </Form.Group>
                ) : (
                  <Form.Group
                    controlid="validationFormik101"
                    className="position-relative"
                  >
                    <Form.Label
                      style={{
                        textAlign: "start",
                        color: "#000",
                        color: "#000",
                      }}
                    >
                      Answer
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={6}
                      type="text"
                      name="answer"
                      placeholder="Enter Answer"
                      value={props.formik.values.answer}
                      onChange={props.formik.handleChange}
                    />
                  </Form.Group>
                )}
              </div>

              <div>
                  {props.loading ? (
                  <Button variant="primary" disabled style={{
                    display: "flex",
                    marginLeft: "auto",
                    marginRight: "15px",
                    marginTop: "15px",
                  }}>
                    <Spinner
                      as="span"
                      aria-hidden="true"
                      animation="border"
                      style={{ color: "white", width: "24px", height: "24px" }}
                      size="sm"
                      role="status"
                    />
                  </Button>
                ) : (
                  <Button type="submit" style={{ marginBottom: "0px",display: "flex",
                  marginLeft: "auto",
                  marginRight: "15px",
                  marginTop: "15px", }}>
                    Submit
                  </Button>
                )}
              </div>
           </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditQuery;
