import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import SendNotification from "./SendNotification";
import UseDialogState from "../../../hooks/UseDialogState";
import { useState } from "react";
import NotificationService from "../../services/notification.service";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

const Notification = () => {
  const { open, handleClickOpen, handleClickClose } = UseDialogState();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState();
  const [pageSize, setPageSize] = useState(10);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(false);

  const getAllNotificationData = async () => {
    try {
      setLoading(true);
      const response = await NotificationService.getAllNotification(
        currentPage,
        pageSize
      );
      setData(response.data.data);
      setTotalItems(response.data.totalNotification);
      setLoadingData(false);
      setLoading(false);
      console.log("Got all notifications", response);
    } catch (err) {
      setLoading(false);
      setLoadingData(false);
      console.log("Error getting all notifications", err);
    }
  };

  useEffect(() => {
    getAllNotificationData();
  }, [currentPage, pageSize]);

  const handleRowsPerPageChange = (newPageSize, currentPage) => {
    setPageSize(newPageSize);
    setCurrentPage(currentPage);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const submit = async (values) => {
    console.log("Submit", values);
    const { title, body } = values;
    try {
      setLoading(true);
      const response = await NotificationService.sendNotification(title, body);
      console.log("Sending notification", response);
      formik.resetForm();
      handleClickClose();
      setLoading(false);
      enqueueSnackbar("Data Send Successfully!", { variant: "success" });
    } catch (err) {
      setLoading(false);
      console.log("Error sending notification", err);
    }
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    body: Yup.string().required("Body is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      body: "",
    },
    onSubmit: submit,
    validationSchema,
  });

  const handleClickOpenDialog = (dataId) => {
    handleClickOpen();
    formik.setFieldValue("title", "");
    formik.setFieldValue("body", "");
  };

  const handleNavigate = (row) => {
    console.log("Navigating", row);
    const path =
      row.title === "New Order Received"
        ? `/bookings`
        : `/reportticket/${row.payload.id}`;
    navigate(path);
  };

  const columns = [
    {
      name: <b>NO</b>,
      selector: (row) => [row._id],
      sortable: true,
      cell: (row) => <div>{row._id}</div>,
    },
    {
      name: <b>Title</b>,
      selector: (row) => [row.title],
      sortable: true,
      cell: (row) => <div>{row.title}</div>,
    },
    {
      name: <b>Description</b>,
      selector: (row) => [row.body],
      sortable: true,
      cell: (row) => <div>{row.body}</div>,
    },
    {
      name: <b>ACTION</b>,
      selector: (row) => [row.ACTIONS],
      // width: "100px",
      sortable: true,
      cell: (row) => (
        <>
          <Link
            to={
              row.title === "New Order Received"
                ? `/bookings`
                : `/reportticket/${row.payload.id}`
            }
            state={{
              reporterId: row.payload?.userId,
            }}
          >
            <Button
              variant="info"
              size="sm"
              className="me-1"
              // onClick={() => handleNavigate(row)}
            >
              <i className="fas fa-eye"></i>
            </Button>
          </Link>
        </>
      ),
    },
  ];
  return (
    <>
      {loadingData ? (
        <div className="loader-container">
          <div
            style={{ position: "absolute", top: "50%", left: "50%" }}
            className="loader"
          >
            Just A Moment....
          </div>
        </div>
      ) : (
        <>
          <div className="page-header2"></div>
          <div className="row">
            <Col sm={12} md={6} lg={6} xl={3}>
              <Card className="card custom-card mb-4">
                <Card.Body>
                  <div className="card-item">
                    <div className="card-item-icon card-icon">
                      <i
                        className="ti-bell fs-30"
                        style={{ color: "#6259CA" }}
                      ></i>
                    </div>
                    <div className="card-item-title  mb-2">
                      <label className="main-content-label tx-13 font-weight-bold ">
                        Total Notifications
                      </label>
                      {/* <span className="d-block tx-12 mb-0 text-muted">
                    Total Audio calls
                  </span> */}
                    </div>
                    <div className="card-item-body">
                      <div className="card-item-stat">
                        <h4 className="font-weight-bold">
                          {/* {data.totalAudioCall} */}
                          {totalItems}
                        </h4>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={6} lg={6} xl={4} className="ms-auto mt-auto">
              <div
                className="custom-card text-end  pointer mb-4"
                style={{ marginRight: "18px" }}
              >
                {" "}
                <Button className="text-white" onClick={handleClickOpenDialog}>
                  <i className="fas fa-plus"></i> &nbsp; Send Notification
                </Button>
              </div>
            </Col>
          </div>
          <Row className="row-sm">
            <Col lg={12}>
              <Card className="custom-card overflow-hidden">
                <Card.Body>
                  <div>
                    <h6 className="main-content-label mb-1">
                      all users/vendor notification
                    </h6>
                  </div>
                  <div className="responsive">
                    <div className="d-flex justify-content-center mb-4">
                      {/* <input
                    style={{
                      borderRadius: "5px",
                      padding: "5px",
                      border: "1px solid #e8e8f7",
                    }}
                    // value={filterValue}
                    // onChange={handleFilterChange}
                    placeholder="Search here"
                    className="ms-auto mb-2 border-rounded"
                  /> */}
                    </div>
                    <DataTable
                      //   {...extensions}
                      columns={columns}
                      data={data}
                      noHeader
                      persistTableHead
                      pagination
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                      highlightOnHover
                      paginationServer
                      paginationTotalRows={totalItems}
                      paginationPerPage={pageSize}
                      paginationRowsPerPageOptions={[10, 25, 50]}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onChangePage={handlePageChange}
                      // progressPending={loading}
                      progressComponent={
                        <div className="text-center"> Just A Moment....</div>
                      }
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* <Row className="row row-sm">
        <Col xl={4} lg={12} md={12}>
          <Card className="custom-card">
            <Card.Body>
              <form>
                <div className="d-flex align-items-start pb-3 border-bottom">
                  <div>
                    <h4>Email/SMS Notifications</h4>
                  </div>
                </div>
                <div className="py-2">
                  <div className="row py-2">
                    <div className="col-md-12 pb-3">
                      <Form.Group
                        controlid="validationFormik101"
                        className="position-relative"
                      >
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={4}
                          name="generateReferenceCode"
                          placeholder="Write text"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <Button>Send Notification</Button>
                </div>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}
          <SendNotification
            show={open}
            onHide={handleClickClose}
            formik={formik}
            loading={loading}
          />
        </>
      )}
    </>
  );
};

export default Notification;
